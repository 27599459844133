import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import React, {useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import axios from 'axios'
import {Formik, Form, Field} from 'formik'
import * as Yup from 'yup'
import ErrorMessage from '../helpers/message'
import Loading from '../helpers/loading'
import {axioDefaultConfig, options, phoneRegex} from '../helpers/commonResource'
import {date} from 'yup/lib/locale'
import SensorIndex from '../../ui/sensor/config/index'

const breadCrumb = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'All Layout',
    path: '/all-layouts',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

function Design(props) {
  return <SensorIndex layout={props.match?.params?.id ?? ''} />
}

export default Design
