/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import * as auth from '../redux/AuthRedux'
import { register } from '../redux/AuthCRUD'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import axios from 'axios'
import { useDataLayerValue } from "../../../../DataLayer";
import { axioDefaultConfig } from '../../../../app/ui/common/commonResource'

const initialValues = {
  email: '',
  password: '',
  changepassword: '',
  token: '',
}

const registrationSchema = Yup.object().shape({

  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    // .min(3, 'Minimum 3 symbols')
    // .max(50, 'Maximum 50 symbols')
    .min(8, 'Password must be at least 8 characters')
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])/, 'Password must contain at least one special character')
    .required('Password is required'),
  changepassword: Yup.string()
    .required('Password confirmation is required')
    .min(8, 'Password must be at least 8 characters')
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])/, 'Password must contain at least one special character')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], "Password and Confirmed Password are not matched"),
    }),
  // acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
})

export function Registration() {
  const [loading, setLoading] = useState(false)
  //const dispatch = useDispatch()
  const [{ token }, dispatch] = useDataLayerValue();
  const [tempToken, setTempToken] = useState('');

  const urlParams = new URLSearchParams(window.location.search);
  const Passwordaccesstoken = String(urlParams.get('token'));
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordChange = (event: any) => {
    setPassword(event.target.value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      values.token = Passwordaccesstoken; // Set the additional token value
      console.log(values)
      setLoading(true)
      setTimeout(() => {
        axios({
          ...axioDefaultConfig,
          method: 'post',
          url: process.env.REACT_APP_BACKEND_API + '/api/accounts/ResetPasswordConfirm',
          data: values,
        })
          .then(function (response) {
            if (response?.data?.token !== '' && response?.data?.token !== null) {
              setTempToken(response?.data?.token)
              setTimeout(() => {
                axios({
                  ...axioDefaultConfig,
                  method: 'post',
                  url: process.env.REACT_APP_BACKEND_API + '/api/accounts/token',
                  data: values,
                }).then(function (response) {
                  if (response?.data?.token !== '' && response?.data?.token !== null) {
                    values.token = response?.data?.token
                    setTempToken(response?.data?.token)
                    dispatch({
                      type: 'SET_USER',
                      user: response?.data?.user,
                    })
                    dispatch({
                      type: 'SET_TOKEN',
                      token: values.token,
                    })
                    //history.push('/facility/' + response?.data?.user.facility)
                    return
                  } else {
                    setLoading(false)
                    setSubmitting(false)
                    setStatus(response.data.message)
                  }
                })
                  .catch(function (error) {
                    setLoading(false)
                    setSubmitting(false)
                    setStatus('The login detail is incorrect')
                  })
              }, 1000)

              // dispatch(auth.actions.login(response?.data?.token))
              // dispatch(auth.actions.setUser(response?.data))
            } else {
              setLoading(false);
              setSubmitting(false)
              setStatus(response.data.message)
            }
          })
          .catch(function (error) {
            setLoading(false)
            setSubmitting(false)
            setStatus('The login detail is incorrect')
          })
      }, 1000)
    },
  })

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className='mb-10 text-center'>
        {/* begin::Title */}
        <h1 className='text-dark mb-3'>Confirm your registration</h1>

      </div>

      <div className='d-flex align-items-center mb-10'>
        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
        <span className='fw-bold text-gray-400 fs-7 mx-2'></span>
        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
      </div>

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group Firstname */}

      {/* end::Form group */}

      {/* begin::Form group Email */}
      <div className='fv-row mb-7'>
        <label className='form-label fw-bolder text-dark fs-6'>Email</label>
        <input
          placeholder='Email'
          type='email'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='text-danger mt-2'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Password */}
      <div className='mb-10 fv-row' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6'>Password</label>
          <div className='position-relative mb-3'>
            <div className="input-group">
              <input
                type={showPassword ? 'text' : 'password'}
                placeholder='Password'
                autoComplete='off'
                {...formik.getFieldProps('password')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {
                    'is-invalid': formik.touched.password && formik.errors.password,
                  },
                  {
                    'is-valid': formik.touched.password && !formik.errors.password,
                  }
                )}
              />
              <span
                className="input-group-text"
                onClick={togglePasswordVisibility}
                style={{ cursor: 'pointer' }}
              >
              </span>
            </div>
            {formik.touched.password && formik.errors.password && (
              <div className='text-danger mt-2'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className='fv-row mb-5'>
        <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label>
        <div className="input-group">
          <input
            type={showPassword ? 'text' : 'password'}
            placeholder='Password confirmation'
            autoComplete='off'
            {...formik.getFieldProps('changepassword')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
              },
              {
                'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
              }
            )}
          />
          <span
            className="input-group-text"
            onClick={togglePasswordVisibility}
            style={{ cursor: 'pointer' }}
          >
          </span>
        </div>
        {formik.touched.changepassword && formik.errors.changepassword && (
          <div className='text-danger mt-2'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.changepassword}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      {/* <div className='fv-row mb-10'>
        <div className='form-check form-check-custom form-check-solid'>
          <input
            className='form-check-input'
            type='checkbox'
            id='kt_login_toc_agree'
            {...formik.getFieldProps('acceptTerms')}
          />
          <label
            className='form-check-label fw-bold text-gray-700 fs-6'
            htmlFor='kt_login_toc_agree'
          >
            I Agree the{' '}
            <Link to='/auth/terms' className='ms-1 link-primary'>
              terms and conditions
            </Link>
            .
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.acceptTerms}</span>
              </div>
            </div>
          )}
        </div>
      </div> */}
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting}
        >
          {!loading && <span className='indicator-label'>Submit</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        {/* <Link to='/auth/registration'>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-primary w-100 mb-5'
          >
            Cancel
          </button>
        </Link> */}
      </div>
      {/* end::Form group */}
    </form>
  )
}

