import React, {useState, useEffect, useRef} from 'react'
import './../../../../_metronic/assets/sass/kntdashbaord.css'
import {HubConnectionBuilder, JsonHubProtocol} from '@microsoft/signalr'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import axios from 'axios'
import useWindowDimensions from '../../helpers/WindowDimension'
import Dictionary from '../../helpers/Dictionary'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Dropdown1} from '../../../../_metronic/partials'
import {AsideDefault} from '../../../../_metronic/layout/components/aside/AsideDefault'
import {Footer} from '../../../../_metronic/layout/components/Footer'
import {HeaderWrapper} from '../../../../_metronic/layout/components/header/HeaderWrapper'
import {PageDataProvider} from '../../../../_metronic/layout/core'
import {Content} from '../../../../_metronic/layout/components/Content'
import {
  ChartsWidget1,
  TablesWidget1,
  ListsWidget5,
  TablesWidget5,
} from '../../../../_metronic/partials/widgets'
import {stat} from 'fs'
import Confetti from 'react-confetti'
import ImageMapper from 'react-image-mapper'
import Draggable from 'react-draggable'
import {v4 as uuidv4} from 'uuid'
import Popup from 'reactjs-popup'
import ReactJson from 'react-json-view'
import 'reactjs-popup/dist/index.css'
import {auto, right} from '@popperjs/core'
var randomColor = require('randomcolor')
//import styles from './DebugPrint.css'

function ObjectViewer(props) {
  const [connection, setConnection] = useState(null)
  const reconnectRef = useRef(0)
  const urlParams = new URLSearchParams(window.location.search)
  const [isloading, setLoading] = useState(false)
  const [item, setItem] = useState('')
  const [SensorList, setsensorList] = useState([])
  const [DisplayName, setDisplayName] = useState('')
  const [Hub, setHub] = useState('')

  const sensors = useRef([])
  const [filterList, setfilterList] = useState([])

  const Filter = (e) => {
    setDisplayName(e.target.value)
    setLoading(true)
    if (e.target.value == '') {
      setTimeout(() => {
        setfilterList(state.SensorList)
        setLoading(false)
      }, 1000)
    } else {
      setTimeout(() => {
        setfilterList(
          state.SensorList.filter(
            (pk) =>
              pk.Packet.toLowerCase().includes(e.target.value.toLowerCase()) ||
              pk.Plant.toLowerCase().includes(e.target.value.toLowerCase())
          )
        )
        setLoading(false)
      }, 1000)
    }
  }

  const HubFilter = (e) => {
    setHub(e.target.value)
    setLoading(true)
    setTimeout(() => {
      setfilterList([])
    }, 1000)
  }

  const [state, setState] = useState({
    Data: {},
    DashboardStat: 'CONNECTING',
    SensorList: [],
  })

  const hubService = process.env.REACT_APP_MIDAS_HUB //"https://signalr.midassit.com";
  useEffect(() => {
    const newConnection = new HubConnectionBuilder()
      .withUrl(hubService + '/' + Hub + '/Hub')
      .withAutomaticReconnect()
      .build()
    setConnection(newConnection)
  }, [Hub])

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then((result) => {
          setState({
            ...state,
            DashboardStat: 'CONNECTED',
          })
          reconnectRef.current = 0
          console.log('Connected!')
          connection.onreconnecting((error) => {
            setState({
              ...state,
              DashboardStat: 'RECONNECTING',
            })
            console.log('Connection lost due to error . Reconnecting.')
          })

          // Reconnect if hub disconnects
          connection.onclose(function (e) {
            setState({
              ...state,
              DashboardStat: 'DISCONNECTED',
            })
            console.log('Disconnected')
          })

          connection.on('ReceiveMessage', (message) => {
            if (typeof message === 'string' || message instanceof String) {
              message = JSON.parse(message)
            }

            //console.log(message.data)
            if (message.key !== 'SensorData') return

            let dataPacket = JSON.parse(message.data)

            const result = sensors.current.find(
              ({Packet, Plant}) => Packet === dataPacket.Packet && Plant === dataPacket?.Plant
            )
            if (result === undefined) {
              sensors.current.push(dataPacket)
              setState({
                ...state,
                SensorList: sensors.current,
                DashboardStat: 'CONNECTED',
              })

              setLoading(true)

              if (DisplayName === '' || DisplayName === undefined) {
                setTimeout(() => {
                  setfilterList(sensors.current)
                  setLoading(false)
                }, 10)
              } else {
                setTimeout(() => {
                  setfilterList(
                    sensors.current.filter(
                      (pk) =>
                        pk.Packet.toLowerCase().includes(DisplayName.toLowerCase()) ||
                        pk.Plant.toLowerCase().includes(DisplayName.toLowerCase())
                    )
                  )
                  setLoading(false)
                }, 10)
              }
            } else {
              //console.log(dataPacket)
            }
          })
        })
        .catch((e) => {
          //   console.log('Connection failed: ', e);
        })
    }
  }, [connection])

  const breadCrumb = [
    {
      title: 'Home',
      path: '/dashboard',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  return (
    <>
      <PageTitle breadcrumbs={breadCrumb}>Data Explorer</PageTitle>
      <div className='row gy-5 g-xl-12'>
        <div className={`card`}>
          {/* begin::Header */}
          <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bolder fs-3 mb-1'>Data Explorer</span>
              <span className='text-muted mt-1 fw-bold fs-7'>
                {' '}
                {state.SensorList.length} Packets Type Received
              </span>
            </h3>
            <div className='card-toolbar'>
              <table>
                <tr>
                  <td>
                    <select
                      className='form-select form-select-solid form-select-lg mb-2 mb-lg-0'
                      onChange={(e) => HubFilter(e)}
                      value={Hub}
                    >
                      <option value=''>Please Select HUB</option>
                      <option value='Automation/Hub'>General Hub</option>
                      <option value='pmp-Automation/Hub'>PMP Hub</option>
                      <option value='wwl-Automation/Hub'>WWL Hub</option>
                      <option value='bel-Automation/Hub'>BEL Hub</option>
                      <option value='wwl-plant13-Automation/Hub'>WWL Plant13 Hub</option>
                      <option value='wwl-gnt-automation/hub'>WWL GNT Plant Hub</option>
                      <option value='pmp-kntting-automation/Hub'>PMP Kntting Hub</option>
                      <option value='wwl-gnt-automation/hub'>WWL GNT Hub</option>
                      <option value='pmp-kntting-automation/Hub'>PMP kntting Hub</option>
                      <option value='test'>Kiosk Hub</option>
                    </select>
                  </td>
                  <td>
                    <input
                      placeholder='Search Object by Plant or Packet...'
                      className='form-control form-control-lg form-control-solid mb-2 mb-lg-0'
                      value={DisplayName}
                      onChange={(e) => Filter(e)}
                      name='macid'
                      type='text'
                    />
                  </td>
                </tr>
              </table>
            </div>
          </div>
          {/* end::Header */}
          {/* begin::Body */}
          <div className='card-body py-3'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              <hr />
              {!isloading ? (
                <ReactJson src={filterList} />
              ) : (
                <span className='indicator-progress' style={{display: 'block'}}>
                  {' '}
                  <b>
                    Loading
                    <span className='spinner-border spinner-border-lg align-middle ms-2'></span>
                  </b>{' '}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ObjectViewer
//https://codesandbox.io/s/k2ds4?file=/src/App.js:128-4341
