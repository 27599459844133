import React, {useState, useEffect, useRef} from 'react'
import './../../../_metronic/assets/sass/kntdashbaord.css'
import {HubConnectionBuilder, JsonHubProtocol} from '@microsoft/signalr'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import axios from 'axios'
import useWindowDimensions from '../helpers/WindowDimension'
import Dictionary from '../helpers/Dictionary'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {Dropdown1} from '../../../_metronic/partials'
import {AsideDefault} from '../../../_metronic/layout/components/aside/AsideDefault'

import 'reactjs-popup/dist/index.css'
import {ToastContainer, toast, Slide, Zoom, Flip, Bounce} from 'react-toastify'
import {FabricJSCanvas, useFabricJSEditor} from 'fabricjs-react'
import LoadingSentrum from '../helpers/LoadingSentrum'
import {axioDefaultConfig} from '../common/commonResource2'
import {useDataLayerValue} from '../../../DataLayer'
import {
  MixedWidget1,
  MixedWidget2,
  MixedWidget3,
  MixedWidget4,
  MixedWidget20_1,
  MixedWidget5_1,
  MixedWidget5,
  MixedWidget6,
  MixedWidget7,
  MixedWidget8,
  MixedWidget9,
  MixedWidget10,
  MixedWidget11,
} from '../../../_metronic/partials/widgets'

function FactoriesManager(props) {
  const [{token}, dispatch] = useDataLayerValue()
  const breadCrumbs = useRef([])
  const hubService = process.env.REACT_APP_MIDAS_HUB
  const [connection, setConnection] = useState(null)
  const reconnectRef = useRef(0)
  const urlParams = new URLSearchParams(window.location.search)
  const [isloading, setLoading] = useState(false)
  const [item, setItem] = useState('')
  const [SensorList, setsensorList] = useState([])
  const sensors = useRef({})
  const {selectedObjects, setSelectedObject, editor, onReady} = useFabricJSEditor({
    defaultStrokeColor: 'red',
    selection: false,
  })
  const [text, setText] = useState('')
  const [strokeColor, setStrokeColor] = useState('')
  const [fillColor, setFillColor] = useState('')
  const [isload, setisload] = useState(true)

  const [layout, setLayout] = useState({
    id: 'dashboard',
    layoutName: '',
    imageData: null,
    devices: [],
    isZoom: false,
    globalJs: '',
    designObject: [],
    factories: [],
    subSectorName: '',
    subSectorCode: '',
    sectorName: '',
    sectorCode: '',
  })

  const [state, setState] = useState({
    Data: {},
    DashboardStat: 'CONNECTING',
    SensorList: [],
  })

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then((result) => {
          setState({
            ...state,
            DashboardStat: 'CONNECTED',
          })
          setLoading(false)
          reconnectRef.current = 0
          connection.onreconnecting((error) => {
            setState({
              ...state,
              DashboardStat: 'RECONNECTING',
            })
            console.log('Connection lost due to error . Reconnecting.')
          })

          // Reconnect if hub disconnects
          connection.onclose(function (e) {
            setState({
              ...state,
              DashboardStat: 'DISCONNECTED',
            })
          })

          connection.on('ReceiveMessage', (message) => {
            if (typeof message === 'string' || message instanceof String) {
              message = JSON.parse(message)
            }

            if (message.key !== 'SensorData') return

            let dataPacket = JSON.parse(message.data)

            // if (
            //   dataPacket.TimeStamp === undefined ||
            //   Math.round((new Date() - new Date(dataPacket.TimeStamp)) / 1000) > 60
            // ) {
            //   return
            // }

            {
              console.log(message)
              if (!(dataPacket.Plant === 'Main' && dataPacket.Packet === 'Dashboard')) {
                return
              }

              sensors.current = dataPacket
              console.log(message)
              setState({
                ...state,
                DashboardStat: 'CONNECTED',
              })
            }
          })
        })
        .catch((e) => {
          // console.log('Connection failed: ', e)
        })
    }
  }, [connection])

  useEffect(() => {
    /* axios({
      method: 'get',
      url:
        process.env.REACT_APP_BACKEND_API +
        '/api/factories/' +
        (props.match?.params?.subsector ?? 'dream'),
      headers: {...axioDefaultConfig.headers, Authorization: 'Bearer ' + token},
    }).then((response) => {
      if (response.data.status == 'S') {
        setLayout({
          ...layout,
          factories: response.data.result.subSectors[0].factories,
          subSectorName: response.data.result.subSectors[0].name,
          subSectorCode: response.data.result.subSectors[0].code,
          sectorName: response.data.result.name,
          sectorCode: response.data.result.code,
        })

        if (response.data.result.subSectors[0].factories.length === 1) {
          window.location.href =
            '/' +
            (props.match?.params?.sector ?? 'dream') +
            '/' +
            (props.match?.params?.subsector ?? 'dream') +
            '/' +
            response.data.result.subSectors[0].factories[0].code
          return
        }

        breadCrumbs.current = [
          {
            title: response.data.result.name,
            path: '/' + response.data.result.code,
            isSeparator: false,
            isActive: false,
          },
          {
            title: '/',
            path: '#',
            isSeparator: false,
            isActive: false,
          },
        ]
      }
      setTimeout(() => {
        const newConnection = new HubConnectionBuilder()
          .withUrl(hubService + '/Automation/Hub')
          .withAutomaticReconnect()
          .build()
        setConnection(newConnection)
      }, 100)
    })*/
  }, [])

  if (isloading) {
    return (
      <>
        <LoadingSentrum state={state} />
      </>
    )
  }

  return (
    <>
      <PageTitle breadcrumbs={breadCrumbs.current}>{layout.subSectorName} </PageTitle>

      <div>
        <div className='row g-2 g-xl-12'>
          {layout.factories.map((item, key) => (
            <>
              <div className='col-xl-3' id={key}>
                <MixedWidget20_1
                  factoryName={item.name}
                  critical={sensors.current?.CICL_Critical}
                  warning={sensors.current?.CICL_Warning}
                  alert={sensors.current?.CICL_Alert}
                  alldevice={sensors.current?.CICL_AllConnect}
                  connected={sensors.current?.CICL_Connect}
                  link={'/factories/' + item.code}
                  className='card-xl-stretch mb-xl-8'
                  color={sensors.current?.CICL_Critical > 0 ? 'danger' : 'success'}
                />
              </div>
            </>
          ))}
        </div>
      </div>
    </>
  )
}

export default FactoriesManager
