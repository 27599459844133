import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import React, {useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import axios from 'axios'
import {Formik, Form, Field} from 'formik'
import * as Yup from 'yup'
import ErrorMessage from '../helpers/message'
import Loading from '../helpers/loading'
import {axioDefaultConfig, options, phoneRegex, validcoderegex} from '../helpers/commonResource'
import {date} from 'yup/lib/locale'
import SensorIndex from '../../ui/sensor/config/index'

const zoomCategory = [
  {label: 'Zoom', value: true},
  {label: '-', value: false},
]

const breadCrumb = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'All Layout',
    path: '/all-layouts',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

function NewLayout(props) {
  const [InitialLoading, setInitialLoading] = useState(true)
  const [error, setError] = useState({status: 'E', text: ''})
  const [isSubmitting, setSubmitting] = useState(false)
  const [code, setCode] = useState(props.match?.params?.id ?? '')
  const [copy, setCopy] = useState('')
  const [copyMessage, setCopyMessage] = useState('')
  const [copyMessageStatus, setCopyMessageStatus] = useState('')

  const [orginalPacket, setOrginalPacket] = useState('')
  const [clonePacket, setClonePacket] = useState('')

  //const [{token}, dispatch] = useDataLayerValue()

  const [state, setState] = useState({
    code: props.match?.params?.id ?? '',
    layoutName: '',
    imageData: null,
    devices: [],
    isImageChanged: false,
    isZoom: false,
    group: '',
    packages: '',
    width: '',
    height: '',
    globalJs: '',
    globalCss: '',
    hub: '',
    isActive: false,
  })

  const validationRules = Yup.object().shape({
    code: Yup.string()
      .matches(validcoderegex, 'Code does not have space or special characters')
      .min(2, 'Too Short!')
      .max(100, 'Too Long!')
      .required('Required'),
    layoutName: Yup.string().min(2, 'Too Short!').max(100, 'Too Long!').required('Required'),
    hub: Yup.string().required('Required'),
  })

  useEffect(() => {
    const id = props.match?.params?.id ?? ''

    if (id === '') {
      setInitialLoading(false)
      return
    }

    const getFormDetails = axios({
      ...axioDefaultConfig,
      method: 'get',
      url: process.env.REACT_APP_BACKEND_API + '/api/sensor-monitor/layout?layout=' + id,
      // headers: {...axioDefaultConfig.headers, Authorization: 'Bearer ' + token},
    })

    axios
      .all([getFormDetails])
      .then(
        axios.spread((...responses) => {
          const getFormDetailsResponse = responses[0]
          if (getFormDetailsResponse?.data.status == 'S') {
            setState(getFormDetailsResponse?.data.result)
            setInitialLoading(false)
          }
        })
      )
      .catch(function (error) {
        handleErros(error)
      })
  }, [])

  if (InitialLoading) return <Loading />

  function handleErros(error) {
    if (error?.response?.status === 401) {
      /*dispatch({
          type: 'SET_TOKEN',
          token: null,
        })*/
      return
    }

    setSubmitting(false)
    if (error?.response?.data?.status === 'E') {
      setError(error?.response?.data)
      return
    }
    const message = Object.entries(error?.response?.data?.errors)
      .map(([key, value]) => value)
      .join(',')
    setError({status: 'E', text: message})
  }

  function onCopied() {
    setSubmitting(true)
    setCopyMessageStatus('')
    setCopyMessage('')

    setTimeout(() => {
      let config = axioDefaultConfig
      config = {
        ...axioDefaultConfig,
        method: 'post',
        url:
          process.env.REACT_APP_BACKEND_API +
          '/api/sensor-monitor/Copy?layout=' +
          code +
          '&copy=' +
          copy,
        data: [{orginal: orginalPacket, replace: clonePacket}],
        /*   headers: {
                    ...axioDefaultConfig.headers,
                    Authorization: 'Bearer ' + token,
                  },*/
      }
      axios(config)
        .then(function (response) {
          setSubmitting(false)
          setCopyMessage(response?.data?.text)
          if (response?.data?.status === 'S') {
            setCopyMessageStatus(copy)
            setCopy('')
            setOrginalPacket('')
            setClonePacket('')
          }
        })
        .catch(function (error) {
          alert(error)
          setSubmitting(false)
        })
    }, 200)
  }

  function handleChange2(e) {
    setCopy(e.target.value)
  }

  function handleChangeOrginalPacket(e) {
    setOrginalPacket(e.target.value)
  }

  function handleChangeClonePacket(e) {
    setClonePacket(e.target.value)
  }

  return (
    <>
      <Formik
        initialValues={state}
        validationSchema={validationRules}
        onSubmit={(values, {}) => {
          values.isZoom = values.isZoom == 'true' ? true : false
          setSubmitting(true)
          setTimeout(() => {
            let config = axioDefaultConfig
            config = {
              ...axioDefaultConfig,
              method: 'post',
              url:
                process.env.REACT_APP_BACKEND_API +
                '/api/sensor-monitor/layout?layout=' +
                code,
              data: values,
              /*   headers: {
                    ...axioDefaultConfig.headers,
                    Authorization: 'Bearer ' + token,
                  },*/
            }
            axios(config)
              .then(function (response) {
                setSubmitting(false)
                setError(response?.data)
                if (response?.data?.status === 'S') {
                  values.code = response?.data?.result.code
                  if (response?.data?.result.code !== '') {
                    setCode(values.code)
                  }
                }
              })
              .catch(function (error) {
                handleErros(error)
              })
          }, 200)
        }}
      >
        {({values, errors, touched, handleChange, handleBlur, handleSubmit}) => (
          <Form onSubmit={handleSubmit}>
            {state.code !== '' && <PageTitle breadcrumbs={breadCrumb}>New Layout</PageTitle>}
            {state.code === '' && <PageTitle breadcrumbs={breadCrumb}>Update Layout</PageTitle>}
            <div className='row gy-5 g-xl-12'>
              <div className='card mb-5 mb-xl-10'>
                <div
                  className='card-header border-0 cursor-pointer'
                  role='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#kt_account_profile_details'
                  aria-expanded='true'
                  aria-controls='kt_account_profile_details'
                >
                  <div className='card-title m-0'>
                    {state.code !== '' && <h3 className='fw-bolder m-0'>New Layout</h3>}
                    {state.code === '' && <h3 className='fw-bolder m-0'>Update Layout</h3>}
                  </div>
                </div>

                <div id='kt_account_profile_details' className='collapse show'>
                  <div className='form'>
                    <ErrorMessage error={error} />
                    <div className='card-body border-top p-9'>
                      <div className='row mb-6'>
                        <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                          Code
                        </label>

                        <div className='col-lg-8'>
                          <div className='row'>
                            <div className='col-lg-12 fv-row'>
                              <input
                                type='text'
                                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                name='code'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.code}
                                disabled={code !== '' ? true : false}
                              />
                              <span style={{color: 'gray', fontStyle: 'italic'}}>
                                Code does not have space or special characters,all characters should
                                be LowerCase
                              </span>
                              {errors.code && touched.code ? (
                                <div className='text-danger fs-7 fw-bolder'>{errors.code}</div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='row mb-6'>
                        <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                          Description
                        </label>

                        <div className='col-lg-8'>
                          <div className='row'>
                            <div className='col-lg-12 fv-row'>
                              <input
                                type='text'
                                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                name='layoutName'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.layoutName}
                              />
                              {errors.layoutName && touched.layoutName ? (
                                <div className='text-danger fs-7 fw-bolder'>
                                  {errors.layoutName}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='row mb-6'>
                        <label className='col-lg-4 col-form-label required fw-bold fs-6'>Hub</label>

                        <div className='col-lg-8'>
                          <div className='row'>
                            <div className='col-lg-12 fv-row'>
                              <select
                                className='form-select form-select-solid form-select-lg mb-3 mb-lg-0'
                                name='hub'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.hub}
                              >
                                <option value=''>Please Select HUB</option>
                                <option value='Automation/Hub'>General Hub</option>
                              </select>
                              {errors.hub && touched.hub ? (
                                <div className='text-danger fs-7 fw-bolder'>{errors.hub}</div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='row mb-6'>
                        <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                          Zoom
                        </label>

                        <div className='col-lg-8'>
                          <div className='row'>
                            <div className='col-lg-12 fv-row'>
                              <select
                                className='form-select form-select-solid form-select-lg mb-3 mb-lg-0'
                                name='isZoom'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.isZoom}
                              >
                                <option value='true'>Full Screen</option>
                                <option value='false'>Normal Size</option>
                              </select>
                              {errors.isZoom && touched.isZoom ? (
                                <div className='text-danger fs-7 fw-bolder'>{errors.isZoom}</div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>

                      {(values.isZoom === 'false' || values.isZoom === false) && (
                        <>
                          {(values.packages === '' ||
                            values.packages === undefined ||
                            values.packages === null) && (
                            <>
                              <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                  Group
                                </label>

                                <div className='col-lg-8'>
                                  <div className='row'>
                                    <div className='col-lg-12 fv-row'>
                                      <input
                                        type='text'
                                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                        name='group'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.group}
                                      />
                                      {errors.group && touched.group ? (
                                        <div className='text-danger fs-7 fw-bolder'>
                                          {errors.group}
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}

                          {(values.group === '' ||
                            values.group === undefined ||
                            values.group === null) && (
                            <>
                              <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                  Packages
                                </label>

                                <div className='col-lg-8'>
                                  <div className='row'>
                                    <div className='col-lg-12 fv-row'>
                                      <textarea
                                        type='text'
                                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                        name='packages'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.packages}
                                      />
                                      <span className='fs-7 fw-bold text-muted'>
                                        Add multiple packet names with comma seprator ex:
                                        Boiler.power1,Boiler.power2,'Boiler.power3
                                      </span>
                                      {errors.packages && touched.packages ? (
                                        <div className='text-danger fs-7 fw-bolder'>
                                          {errors.packages}
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                  Dimension (Width X Height)
                                  <br />
                                  <span className='fs-7 fw-bold text-muted'>
                                    300 X 400 all values need to mention in px
                                  </span>
                                </label>

                                <div className='col-lg-4'>
                                  <div className='row'>
                                    <div className='col-lg-12 fv-row'>
                                      <input
                                        type='number'
                                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                        name='width'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.width}
                                      />
                                      {errors.width && touched.width ? (
                                        <div className='text-danger fs-7 fw-bolder'>
                                          {errors.width}
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                                <div className='col-lg-4'>
                                  <div className='row'>
                                    <div className='col-lg-12 fv-row'>
                                      <input
                                        type='number'
                                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                        name='height'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.height}
                                      />
                                      {errors.height && touched.height ? (
                                        <div className='text-danger fs-7 fw-bolder'>
                                          {errors.height}
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      )}

                      <div className='row mb-6'>
                        <label className='col-lg-4 col-form-label  fw-bold fs-6'>GLobal Code</label>

                        <div className='col-lg-8'>
                          <div className='row'>
                            <div className='col-lg-12 fv-row'>
                              <textarea
                                rows='8'
                                cols='50'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.globalJs}
                                name='globalJs'
                                className='form-control  form-control-solid '
                                placeholder='JS'
                                type='text'
                              />
                              {errors.globalJs && touched.globalJs ? (
                                <div className='text-danger fs-7 fw-bolder'>{errors.globalJs}</div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='row mb-6'>
                        <label className='col-lg-4 col-form-label  fw-bold fs-6'>Active</label>

                        <div className='col-lg-8 d-flex align-items-center'>
                          <div className='form-check form-check-solid form-switch fv-row'>
                            <input
                              className='form-check-input w-45px h-30px'
                              type='checkbox'
                              name='isActive'
                              onChange={handleChange}
                              onBlur={handleBlur}
                              checked={values.isActive}
                            />
                            <label className='form-check-label'></label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='card-footer d-flex justify-content-end py-6 px-9'>
                      <button type='submit' className='btn btn-primary' disabled={isSubmitting}>
                        <span className='indicator-progress' style={{display: 'block'}}>
                          {!isSubmitting && (
                            <span className='indicator-label'>
                              Save &nbsp; <i class='fas fa-chevron-right fs-1x'></i>
                            </span>
                          )}
                          {isSubmitting && (
                            <span className='indicator-progress' style={{display: 'block'}}>
                              <span className='spinner-border spinner-border-lg align-middle ms-2'></span>
                            </span>
                          )}
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {code !== '' && (
                <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed p-6'>
                  <KTSVG
                    path='icons/duotune/general/gen044.svg'
                    className='svg-icon-2tx svg-icon-warning me-4'
                  />
                  <div className='d-flex flex-stack flex-grow-1'>
                    <div className='fw-bold'>
                      <h4 className='text-gray-800 fw-bolder'>Design Template</h4>
                      <div className='fs-6 text-gray-600'>
                        Using below link you can design the layout
                        <a className='fw-bolder' href={'/all-layouts/design/' + code}>
                          {' '}
                          Go to Design
                        </a>
                        .
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className='notice d-flex bg-light-info rounded border-info border border-dashed p-6'>
                <KTSVG
                  path='icons/duotune/general/gen044.svg'
                  className='svg-icon-2tx svg-icon-warning me-4'
                />
                <div className='d-flex flex-stack flex-grow-1'>
                  <div className='fw-bold'>
                    <h4 className='text-gray-800 fw-bolder'>MQTT Monitoring Dashboard</h4>
                    <div className='fs-6 text-gray-600'>
                      Using below link you can monitor & manage the mqtt actions,
                      {/*  <b>Username</b> : admin {' '}<b>Password</b> : admin1234*/}{' '}
                      <a
                        className='fw-bolder'
                        target={'_blank'}
                        href={'http://10.137.224.100:15672'}
                      >
                        Go to dashbaord
                      </a>
                      .
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              {code !== '' && (
                <div className='notice d-flex bg-light rounded border-info border border-dashed p-6'>
                  <KTSVG
                    path='icons/duotune/general/gen044.svg'
                    className='svg-icon-2tx svg-icon-warning me-4'
                  />
                  <div className='d-flex flex-stack flex-grow-1'>
                    <div className='fw-bold'>
                      <h4 className='text-gray-800 fw-bolder'>Copy Layout</h4>
                      <div className='fs-6 text-gray-600'>
                        Using below link you can copy the design the layout
                        <table>
                          <tr>
                            <td>
                              Layout Name
                              <input
                                type='text'
                                className='form-control form-control-lg  mb-3 mb-lg-0'
                                onChange={handleChange2}
                                value={copy}
                                name='layoutName'
                              />
                            </td>
                            <td>
                              Current Packet Name
                              <input
                                type='text'
                                className='form-control form-control-lg  mb-3 mb-lg-0'
                                onChange={handleChangeOrginalPacket}
                                value={orginalPacket}
                                name='orginalPacket'
                              />
                            </td>
                            <td>
                              Change Packet Name
                              <input
                                type='text'
                                className='form-control form-control-lg  mb-3 mb-lg-0'
                                onChange={handleChangeClonePacket}
                                value={clonePacket}
                                name='clonePacket'
                              />
                            </td>
                            <td>
                              <br />
                              <button
                                type='button'
                                className='btn btn-primary'
                                onClick={onCopied}
                                disabled={isSubmitting}
                              >
                                <span className='indicator-progress' style={{display: 'block'}}>
                                  {!isSubmitting && (
                                    <span className='indicator-label'>
                                      Copy &nbsp; <i class='fas fa-chevron-right fs-1x'></i>
                                    </span>
                                  )}
                                  {isSubmitting && (
                                    <span className='indicator-progress' style={{display: 'block'}}>
                                      <span className='spinner-border spinner-border-lg align-middle ms-2'></span>
                                    </span>
                                  )}
                                </span>
                              </button>
                            </td>
                          </tr>
                        </table>
                        <div className='text-info fs-7 fw-bolder'>{copyMessage}</div>
                        {copyMessageStatus !== '' && (
                          <a className='fw-bolder' href={'/all-layouts/edit/' + copyMessageStatus}>
                            {' '}
                            View Layout
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default NewLayout
