/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { getCSSVariableValue } from '../../../assets/ts/_utils'
import { link } from 'fs'
import { config } from 'process'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'

type Props = {
  className: string
  chartColor: string
  chartHeight: string
  name: string
  min: string
  max: string
  val: number
  Link: string
  type: string,
  data: string
}

const routeChange = (link: any) => {
  window.location.href = link;
  // alert('asa' + link)
}

const MixedWidget49: React.FC<Props> = ({ className, chartColor, chartHeight, name, min, max, val, Link, type, data }) => {
  const chartRef = useRef<HTMLDivElement | null>(null)

  var color = ''
  // if (type === "OFFLINE") {
  //   color = 'dark'
  //   return (
  //     <div className={`card ${className}`} onClick={(e) => routeChange(Link)} style={{ cursor: 'pointer' }}>
  //       {/* begin::Body */}
  //       <div className='card-body d-flex flex-column p-0'>
  //         {/* begin::Stats */}
  //         <div className={`flex-grow-1 card-p pb-0 bg-${color}`}>
  //           <div className='d-flex flex-stack flex-wrap'>
  //             <div className='me-2'>
  //               <a href='#' className='text-live text-hover-primary fw-bolder fs-3'>
  //                 ssssss
  //               </a>
  //               <div style={{ color: 'white' }} className='fs-7 '>fffff</div>
  //               <div className='fs-7 '>ereeee</div>
  //             </div>
  //             <div className={`fw-bolder fs-3 text-${chartColor}`}> ---</div>
  //           </div>
  //         </div>
  //         {/* end::Stats */}


  //       </div>
  //       {/* end::Body */}
  //     </div>
  //   )
  // }
  console.log(data)
  var objList = JSON.parse(data);
  var obj = objList.filter((item: any) => item.FieldName == "MCU_TEMP")[0]
  var imei = objList.filter((item: any) => item.FieldName == "IMEI")[0]
  var BAT_VOL = objList.filter((item: any) => item.FieldName == "BAT_VOL")[0]
  var DATETIME = objList.filter((item: any) => item.FieldName == "DATETIME")[0]
  var GAS_METER = objList.filter((item: any) => item.FieldName == "GAS_METER")[0]
  //var pr = ((GAS_METER.Value / 400.0) * 100); //
  var F_DATETIME = objList.filter((item: any) => item.FieldName == "F_DATETIME")[0]
  var CSQ = objList.filter((item: any) => item.FieldName == "CSQ")[0]
  var TANK_SIZE = objList.filter((item: any) => item.FieldName == "TANK_SIZE")[0]
  var GAS_PERCENT = objList.filter((item: any) => item.FieldName == "GAS_PERCENT")[0] ?? 0
  var pr = GAS_PERCENT.Value ?? 0
  var colurx = '#900C3F';
  if (pr > 70) {
    colurx = '#09932c';
  }
  else if (pr > 45) {
    colurx = '#FFC300'
  }
  else {
    colurx = '#900C3F';
  }

  return (
    <div className={`card ${className}`} onClick={(e) => routeChange(Link)} style={{ cursor: 'pointer' }}>



      {/* begin::Body */}
      <div className='card-body d-flex flex-column p-0'>
        {/* begin::Stats */}
        <div className={`flex-grow-1 card-p pb-0 bg-${color}`}>
          <div className='d-flex flex-stack flex-wrap'>
            <table>
              <tr>
                <td>
                  <div className='me-1'>
                    <div style={{
                      backgroundColor: colurx,
                      width: '55px',
                      height: '80px',
                      float: 'left',
                      top: 0
                    }}>
                      <div style={{
                        backgroundColor: 'grey',

                        bottom: 0,
                        width: '100%',
                        height: (100 - pr) + '%',
                        //border-top: 6px solid #FFF;
                        boxSizing: 'border-box',
                        animation: 'grow 1.5s ease-out forwards',
                        transformOrigin: 'bottom'
                      }}></div>
                    </div>
                    <img style={{ width: "64px", position: 'absolute', left: '25px', top: '5px' }} src={toAbsoluteUrl('/media/img/gas.png')} alt='Footer' />
                  </div>
                </td>
                <td>
                  <div className='me-2'>

                    <a href='#' className='text-dark text-hover-primary fw-bolder fs-3'>
                      {imei.Description}
                    </a>
                    <div className='text-muted fs-7 fw-bold'>Tank Level: {GAS_METER.Value} cm | Size: {TANK_SIZE.Value ?? "--- lb"}</div>

                  </div>
                </td>
              </tr>
            </table>

            <div className={`fw-bolder fs-3 `} style={{ color: colurx }}>{pr}%</div>
            <div className='ms-1' style={{ position: 'absolute', textAlign: 'right', right: '20px', top: '15px' }}>
              <>
                <span style={{ fontSize: '13px', marginTop: "-7px", position: "absolute" }} className={`dot bg-info`}></span>
                <span style={{ fontSize: '13px', marginTop: '-20px', marginLeft: '-12px' }} className={`pulse-ring bg-info`}> </span>
              </>
            </div>
            <div className='ms-1' style={{ position: 'absolute', textAlign: 'right', fontSize: '12px', right: '20px', bottom: '5px' }}>
              <>
                <div className=' '>{F_DATETIME.Value}</div>
                Battery {BAT_VOL.Value} <i className='fas fa-battery-half'></i> <i className="fa fa-wifi"></i> {CSQ.Value}
              </>
            </div>
          </div>


        </div>
        {/* end::Stats */}


      </div>
      {/* end::Body */}
    </div >
  )
}


export { MixedWidget49 }
