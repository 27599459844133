import React, {useState, useEffect, useRef} from 'react'
import './../../../_metronic/assets/sass/kntdashbaord.css'
import {HubConnectionBuilder, JsonHubProtocol} from '@microsoft/signalr'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import axios from 'axios'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {MixedWidget20_1} from '../../../_metronic/partials/widgets'
import 'reactjs-popup/dist/index.css'
import {FabricJSCanvas, useFabricJSEditor} from 'fabricjs-react'
import LoadingSentrum from '../helpers/LoadingSentrum'
import {axioDefaultConfig} from '../common/commonResource2'
import {useDataLayerValue} from '../../../DataLayer'

const breadCrumbs = []
function SectorManager(props) {
  const [{token}, dispatch] = useDataLayerValue()
  const hubService = process.env.REACT_APP_BACKEND_API
  const [connection, setConnection] = useState(null)
  const reconnectRef = useRef(0)
  const urlParams = new URLSearchParams(window.location.search)
  const [isloading, setLoading] = useState(true)
  const [item, setItem] = useState('')
  const [SensorList, setsensorList] = useState([])
  const sensors = useRef([])
  const {selectedObjects, setSelectedObject, editor, onReady} = useFabricJSEditor({
    defaultStrokeColor: 'red',
    selection: false,
  })
 // alert('MKD -' + JSON.stringify(props))
  const [layout, setLayout] = useState({
    id: 'dashboard',
    layoutName: '',
    imageData: null,
    devices: [],
    isZoom: false,
    globalJs: '',
    designObject: [],
    subSectors: [],
    name: '',
  })

  const [state, setState] = useState({
    Data: {},
    DashboardStat: 'CONNECTING',
    IsRefesh: false,
    SensorList: [],
  })

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then((result) => {
          setState({
            ...state,
            DashboardStat: 'CONNECTED',
          })

          reconnectRef.current = 0

          connection.onreconnecting((error) => {
            setState({
              ...state,
              DashboardStat: 'RECONNECTING',
            })
            console.log('Connection lost due to error . Reconnecting.')
          })

          // Reconnect if hub disconnects
          connection.onclose(function (e) {
            setState({
              ...state,
              DashboardStat: 'DISCONNECTED',
            })
          })

          connection.on('ReceiveMessage', (message) => {
            if (typeof message === 'string' || message instanceof String) {
              message = JSON.parse(message)
            }

            if (
              message.key !== 'SensorData' ||
              message.packetType !== (props.match?.params?.sector ?? 'dream')
            ) {
              return
            }

            let dataPacket = JSON.parse(message.data)

            /* if (dataPacket.Packet != 'jkhfood') {
              return
            }*/

            // if (
            //   dataPacket.TimeStamp === undefined ||
            //   Math.round((new Date() - new Date(dataPacket.TimeStamp)) / 1000) > 60
            // ) {
            //   return
            // }
            console.log(message)
            sensors.current[dataPacket.Packet] = dataPacket

            setState({
              ...state,
              DashboardStat: 'CONNECTED',
            })
          })
        })
        .catch((e) => {
          console.log('Connection failed: ', e)
        })
    }
  }, [connection])

  useEffect(() => {
    axios({
      method: 'get',
      url:
        process.env.REACT_APP_BACKEND_API +
        '/api/sub-sectors/' +
        (props.match?.params?.sector ?? 'dream'),
      headers: {...axioDefaultConfig.headers, Authorization: 'Bearer ' + token},
    }).then((response) => {
      if (response.data.status == 'S') {
        setLoading(false)
        setLayout({
          ...layout,
          subSectors: response.data.result.subSectors,
          name: response.data.result.name,
        })

        setTimeout(() => {
          const newConnection = new HubConnectionBuilder()
            .withUrl(process.env.REACT_APP_MIDAS_HUB + '/Automation/Hub')
            .withAutomaticReconnect()
            .build()
          setConnection(newConnection)
        }, 100)
      }
    })

    setInterval(() => {
      var isExpired = false
      var newObjectArray = []
      Object.entries(sensors.current).forEach(([key, value]) => {
        if (
          sensors.current[value]?.TimeStamp === undefined ||
          Math.round((new Date() - new Date(sensors.current[value]?.TimeStamp)) / 1000) > 60
        ) {
          isExpired = true
        } else {
          newObjectArray[value] = sensors.current[value]
        }
      })

      if (isExpired) {
        sensors.current = newObjectArray
        setState({
          ...state,
          IsRefesh: true,
        })
      }
    }, 60000)
  }, [])

  if (isloading) {
    return (
      <>
        <LoadingSentrum state={state} />
      </>
    )
  }

  return (
    <>
      <PageTitle breadcrumbs={breadCrumbs}>{layout.name} </PageTitle>
      <div>
        <div className='row g-2 g-xl-12'>
          {layout.subSectors.map((item, key) => (
            <>
              <div key={key} className='col-xl-3'>
                <MixedWidget20_1
                  factoryName={item.name}
                  critical={sensors.current[item.fK_SectorCode]?.[item.code + '_critical']}
                  warning={sensors.current[item.fK_SectorCode]?.[item.code + '_warning']}
                  alert={sensors.current[item.fK_SectorCode]?.[item.code + '_alert']}
                  alldevice={sensors.current[item.fK_SectorCode]?.[item.code + '_devices']}
                  connected={sensors.current[item.fK_SectorCode]?.[item.code + '_connected']}
                  link={'/' + (props.match?.params?.sector ?? 'dream') + '/' + item.code}
                  className='card-xl-stretch mb-xl-8'
                  color={'dark'}
                />
              </div>
            </>
          ))}
        </div>
      </div>
    </>
  )
}

export default SectorManager
