import React, {useState, useEffect, useRef} from 'react'
import './../../../_metronic/assets/sass/kntdashbaord.css'
import {HubConnectionBuilder, JsonHubProtocol} from '@microsoft/signalr'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import axios from 'axios'
import useWindowDimensions from '../helpers/WindowDimension'
import Dictionary from '../helpers/Dictionary'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {Dropdown1} from '../../../_metronic/partials'
import {AsideDefault} from '../../../_metronic/layout/components/aside/AsideDefault'
import {MixedWidget1, MixedWidget7} from '../../../_metronic/partials/widgets'
import 'reactjs-popup/dist/index.css'
import {ToastContainer, toast, Slide, Zoom, Flip, Bounce} from 'react-toastify'
import {FabricJSCanvas, useFabricJSEditor} from 'fabricjs-react'
import LoadingSentrum from '../helpers/LoadingSentrum'
import {axioDefaultConfig} from '../common/commonResource2'
import {useDataLayerValue} from '../../../DataLayer'

function BusinessUnitManager(props) {
  const [{token}, dispatch] = useDataLayerValue()
  const breadCrumbs = useRef([])
  const hubService = process.env.REACT_APP_MIDAS_HUB
  const [connection, setConnection] = useState(null)
  const reconnectRef = useRef(0)
  const urlParams = new URLSearchParams(window.location.search)
  const [isloading, setLoading] = useState(true)
  const [item, setItem] = useState('')
  const [SensorList, setsensorList] = useState([])
  const sensors = useRef([])
  const {selectedObjects, setSelectedObject, editor, onReady} = useFabricJSEditor({
    defaultStrokeColor: 'red',
    selection: false,
  })
  const [text, setText] = useState('')
  const [strokeColor, setStrokeColor] = useState('')
  const [fillColor, setFillColor] = useState('')
  const [isload, setisload] = useState(true)

  const [layout, setLayout] = useState({
    id: 'dashboard',
    layoutName: '',
    imageData: null,
    devices: [],
    isZoom: false,
    globalJs: '',
    designObject: [],
    factories: [],
    subSectorName: '',
    subSectorCode: '',
    sectorName: '',
    sectorCode: '',
    factroyName: '',
    factoryCode: '',
    businessUnits: [],
  })

  const [state, setState] = useState({
    Data: {},
    DashboardStat: 'CONNECTING',
    SensorList: [],
  })

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then((result) => {
          setState({
            ...state,
            DashboardStat: 'CONNECTED',
          })
          setLoading(false)
          reconnectRef.current = 0
          connection.onreconnecting((error) => {
            setState({
              ...state,
              DashboardStat: 'RECONNECTING',
            })
            console.log('Connection lost due to error . Reconnecting.')
          })

          // Reconnect if hub disconnects
          connection.onclose(function (e) {
            setState({
              ...state,
              DashboardStat: 'DISCONNECTED',
            })
          })

          connection.on('ReceiveMessage', (message) => {
            if (typeof message === 'string' || message instanceof String) {
              message = JSON.parse(message)
            }

            var packetType = layout.subSectorCode + '_' + layout.factoryCode
            if (message.key !== 'SensorData' || message.packetType !== packetType) {
              return
            }

            let dataPacket = JSON.parse(message.data)

            // if (
            //   dataPacket.TimeStamp === undefined ||
            //   Math.round((new Date() - new Date(dataPacket.TimeStamp)) / 1000) > 60
            // ) {
            //   return
            // }

            //  if (!(dataPacket.Packet === 'jkhfood_cicl_ciclfactory1_businessunit')) {
            // return
            //  }

            console.log(message)
            sensors.current[dataPacket.Packet] = dataPacket
            /*var foundValue = sensors.current.filter((obj) => obj === dataPacket.Packet)
              if (foundValue.length > 0) {
                
                // console.log('The pair exists.')
              } else {
                sensors.current.push(dataPacket.Packet, dataPacket)
                //console.log('The pair does not exist.')
              }*/

            setState({
              ...state,
              DashboardStat: 'CONNECTED',
            })
          })
        })
        .catch((e) => {
          // console.log('Connection failed: ', e)
        })
    }
  }, [connection])

  useEffect(() => {
    axios({
      method: 'get',
      url:
        process.env.REACT_APP_BACKEND_API +
        '/api/business-units/' +
        (props.match?.params?.factory ?? 'dream'),
      headers: {...axioDefaultConfig.headers, Authorization: 'Bearer ' + token},
    }).then((response) => {
      if (response.data.status == 'S') {
        setLayout({
          ...layout,
          factories: response.data.result.subSectors[0].factories,
          subSectorName: response.data.result.subSectors[0].name,
          subSectorCode: response.data.result.subSectors[0].code,
          sectorName: response.data.result.name,
          sectorCode: response.data.result.code,
          factroyName: response.data.result.subSectors[0].factories[0].name,
          factoryCode: response.data.result.subSectors[0].factories[0].code,
          businessUnits: response.data.result.businessUnitModels,
        })

        breadCrumbs.current = [
          {
            title: response.data.result.name,
            path: '/' + response.data.result.code,
            isSeparator: false,
            isActive: false,
          },
          {
            title: '/',
            path: '#',
            isSeparator: false,
            isActive: false,
          },
          {
            title: response.data.result.subSectors[0].name,
            path: '/' + response.data.result.code + '/' + response.data.result.subSectors[0].code,
            isSeparator: false,
            isActive: false,
          },
          {
            title: '/',
            path: '#',
            isSeparator: false,
            isActive: false,
          },
        ]
      }
      setTimeout(() => {
        const newConnection = new HubConnectionBuilder()
          .withUrl(hubService + '/Automation/Hub')
          .withAutomaticReconnect()
          .build()
        setConnection(newConnection)
      }, 100)
    })
    setInterval(() => {
      var newObjectArray = []
      Object.entries(sensors.current).forEach(([key, value]) => {
        if (
          sensors.current[value]?.TimeStamp === undefined ||
          Math.round((new Date() - new Date(sensors.current[value]?.TimeStamp)) / 1000) > 30
        ) {
          newObjectArray[value] = null
        } else {
          newObjectArray[value] = sensors.current[value]
        }
      })

      if (sensors.current.length != newObjectArray.length) {
        sensors.current = newObjectArray
        setState({
          ...state,
          IsRefesh: true,
        })
      }
    }, 60000)
  }, [])

  if (isloading) {
    return (
      <>
        <LoadingSentrum state={state} />
      </>
    )
  }

  return (
    <>
      <PageTitle breadcrumbs={breadCrumbs.current}>{layout.factroyName} </PageTitle>
      <div>
        <div className='row g-5 g-xl-8'>
          {layout.businessUnits &&
            layout.businessUnits.map((item, index) => (
              <div className='col-xl-3'>
                <MixedWidget1
                  factoryName={item.name}
                  critical={
                    sensors.current[item.fK_SubSectorCode + '_' + item.fK_Factory]?.[
                      item.fK_SectorCode +
                        '_' +
                        item.fK_SubSectorCode +
                        '_' +
                        item.fK_Factory +
                        '_' +
                        item.code +
                        '_critical'
                    ]
                  }
                  warning={
                    sensors.current[item.fK_SubSectorCode + '_' + item.fK_Factory]?.[
                      item.fK_SectorCode +
                        '_' +
                        item.fK_SubSectorCode +
                        '_' +
                        item.fK_Factory +
                        '_' +
                        item.code +
                        '_warning'
                    ]
                  }
                  alert={
                    sensors.current[item.fK_SubSectorCode + '_' + item.fK_Factory]?.[
                      item.fK_SectorCode +
                        '_' +
                        item.fK_SubSectorCode +
                        '_' +
                        item.fK_Factory +
                        '_' +
                        item.code +
                        '_alert'
                    ]
                  }
                  alldevice={
                    sensors.current[item.fK_SubSectorCode + '_' + item.fK_Factory]?.[
                      item.fK_SectorCode +
                        '_' +
                        item.fK_SubSectorCode +
                        '_' +
                        item.fK_Factory +
                        '_' +
                        item.code +
                        '_devices'
                    ]
                  }
                  connected={
                    sensors.current[item.fK_SubSectorCode + '_' + item.fK_Factory]?.[
                      item.fK_SectorCode +
                        '_' +
                        item.fK_SubSectorCode +
                        '_' +
                        item.fK_Factory +
                        '_' +
                        item.code +
                        '_connected'
                    ]
                  }
                  link={
                    '/' +
                    (props.match?.params?.sector ?? 'dream') +
                    '/' +
                    (props.match?.params?.subsector ?? 'dream') +
                    '/' +
                    (props.match?.params?.factory ?? 'dream') +
                    '/' +
                    item.code
                  }
                  className='card-xl-stretch mb-xl-8'
                  color={'dark'}
                />
              </div>
            ))}

          <div className='col-xl-4'>
            <MixedWidget7
              className='card-xl-stretch mb-xl-8'
              chartColor='danger'
              chartHeight='200px'
            />
          </div>
          {/* end::Col */}
        </div>
      </div>
    </>
  )
}

export default BusinessUnitManager
