import React, {useState, useEffect, useRef} from 'react'
import './../../../_metronic/assets/sass/kntdashbaord.css'
import {HubConnectionBuilder, JsonHubProtocol} from '@microsoft/signalr'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import axios from 'axios'
import useWindowDimensions from '../helpers/WindowDimension'
import Dictionary from '../helpers/Dictionary'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {Dropdown1} from '../../../_metronic/partials'
import {AsideDefault} from '../../../_metronic/layout/components/aside/AsideDefault'
import 'reactjs-popup/dist/index.css'
import {ToastContainer, toast, Slide, Zoom, Flip, Bounce} from 'react-toastify'
import {FabricJSCanvas, useFabricJSEditor} from 'fabricjs-react'
import LoadingSentrum from '../helpers/LoadingSentrum'
import {axioDefaultConfig} from '../common/commonResource2'
import {useDataLayerValue} from '../../../DataLayer'
import {stat} from 'fs'
import {MixedWidget49, MixedWidget5} from '../../../_metronic/partials/widgets'

function BusinessUnitDetailManager2(props) {
  const [{token}, dispatch] = useDataLayerValue()
  const breadCrumbs = useRef([])
  const hubService = process.env.REACT_APP_MIDAS_HUB
  const [connection, setConnection] = useState(null)
  const reconnectRef = useRef(0)
  const urlParams = new URLSearchParams(window.location.search)
  const [isloading, setLoading] = useState(true)
  const [item, setItem] = useState('')
  const [SensorList, setsensorList] = useState([])
  const sensors = useRef([])

  const {selectedObjects, setSelectedObject, editor, onReady} = useFabricJSEditor({
    defaultStrokeColor: 'red',
    selection: false,
  })
  const [text, setText] = useState('')
  const [strokeColor, setStrokeColor] = useState('')
  const [fillColor, setFillColor] = useState('')
  const [isload, setisload] = useState(true)

  const [layout, setLayout] = useState({
    id: 'dashboard',
    layoutName: '',
    imageData: null,
    devices: [],
    isZoom: false,
    globalJs: '',
    designObject: [],
    factories: [],
    units: [],
    subSectorName: '',
    subSectorCode: '',
    sectorName: '',
    sectorCode: '',
    factroyName: '',
    factroyCode: '',
    unitName: '',
    unitCode: '',
    packet: (props.match?.params?.code ?? 'dream') + '_group',
    Field: '',
  })

  const [state, setState] = useState({
    Data: {},
    DashboardStat: 'CONNECTING',
    SensorList: [],
    IsDataReceived: false,
  })

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then((result) => {
          setState({
            ...state,
            DashboardStat: 'CONNECTED',
          })
          setLoading(false)
          reconnectRef.current = 0
          connection.onreconnecting((error) => {
            setState({
              ...state,
              DashboardStat: 'RECONNECTING',
            })
            console.log('Connection lost due to error . Reconnecting.')
          })

          // Reconnect if hub disconnects
          connection.onclose(function (e) {
            setState({
              ...state,
              DashboardStat: 'DISCONNECTED',
            })
            //console.log('Disconnected');
          })

          connection.on('ReceiveMessage', (message) => {
            if (typeof message === 'string' || message instanceof String) {
              message = JSON.parse(message)
            }

            //if (message.key !== 'SensorData') return

            let datapacket = JSON.parse(message.data)

            // if (
            //   datapacket.TimeStamp === undefined ||
            //   Math.round((new Date() - new Date(datapacket.TimeStamp)) / 1000) > 160
            // ) {
            //   return
            // }

            var packetType = (props.match?.params?.code ?? 'dream') + '_group'
            if (message.packetType !== packetType) {
              return
            }
            //ciclpd_offline_list
            console.log(message)
            sensors.current[packetType] = datapacket
            setState({
              ...state,
              DashboardStat: 'CONNECTED',
              IsDataReceived: true,
            })
          })
        })
        .catch((e) => {
          // console.log('Connection failed: ', e)
        })
    }
  }, [connection])

  useEffect(() => {
    axios({
      method: 'get',
      url:
        process.env.REACT_APP_BACKEND_API +
        '/api/facility-detail/' +
        (props.match?.params?.code ?? 'dream'),
      headers: {...axioDefaultConfig.headers, Authorization: 'Bearer ' + token},
    }).then((response) => {
      if (response.data.status == 'S') {
        setLayout({
          ...layout,
          //units: response.data.result.unitModels,
          breadCrumbs: response.data.result.breadCrumbs,
          name: response.data.result.name,
          //packet: props.match?.params?.code,
        })
      }

      setTimeout(() => {
        const newConnection = new HubConnectionBuilder()
          .withUrl(hubService + '/' + response.data.result.automationChannel + '/Hub')
          .withAutomaticReconnect()
          .build()
        setConnection(newConnection)
      }, 100)
    })
  }, [])

  // // Get the browser timezone
  // const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  // // Calculate SLST time if browser timezone is not SLST
  // if (browserTimezone !== 'Asia/Colombo') {
  //   const date = new Date() // Example UTC date
  //   const options = {
  //     timeZone: 'Asia/Colombo',
  //     year: 'numeric',
  //     month: '2-digit',
  //     day: '2-digit',
  //     hour: '2-digit',
  //     minute: '2-digit',
  //     second: '2-digit',
  //     hour12: false,
  //   }
  //   const formatter = new Intl.DateTimeFormat('en-GB', options)
  //   const slstFormattedDate = new Date(formatter.format(date))

  //   console.log(`Formatted date in SLST: ${slstFormattedDate}`)
  // } else {
  //   console.log('Browser timezone is already SLST.')
  // }

  if (isloading) {
    return (
      <>
        <LoadingSentrum state={state} />
      </>
    )
  }

  return (
    <>
      <PageTitle code={props?.match?.params?.code} breadcrumbs={layout.breadCrumbs}>
        {layout.name}
      </PageTitle>
      <>
        {/* <div className='row g-5 g-xl-8'>
          <div className='col-xl-4'>
            <MixedWidget49
              className='card-xl-stretch mb-xl-8'
              chartColor='warning'
              chartHeight='150px'
              name={'ddd'}
              type='OFFLINE'
              min='-19°C'
              max='-25°C'
              val={'11'}
              Link={'#'}
              data={'[{"Code":"sgastag1","Name":"MCU_TEMP","Description":"Gas Tag 01","Value":30}]'}
            />
          </div>
        </div> */}

        {state.IsDataReceived == false && layout.units.length > 0 && (
          <>
            <div className='row g-5 g-xl-8'>
              <div className='col-xl-12'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bolder fs-3 mb-1'>
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Connecting...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  </span>
                  <span className='text-muted mt-1 fw-bold fs-7'></span>
                </h3>
              </div>
            </div>
            <div className='row g-5 g-xl-8'>
              {/* begin::Col */}
              {layout.units.map((item, index) => (
                <div className='col-xl-4'>
                  <a href={'/facility-detail-item/' + item.code}>
                    <div className={`card danger`}>
                      {/* begin::Body */}
                      <div className='card-body d-flex flex-column p-0'>
                        {/* begin::Stats */}
                        <div className='flex-grow-1 card-p pb-0'>
                          <div className='d-flex flex-stack flex-wrap'>
                            <div className='me-2'>
                              <div className='text-dark text-hover-primary fw-bolder fs-3'>
                                {item.description}
                              </div>
                              <div className='text-muted fs-7 fw-bold'> {item.name}</div>
                            </div>
                            <div className={`fw-bolder fs-3`}>--</div>
                          </div>
                        </div>
                        {/* end::Stats */}
                      </div>
                      {/* end::Body */}
                    </div>
                  </a>
                </div>
              ))}
              {layout.units.length == 0 && <div className='col-xl-12'>No Records Found</div>}
            </div>
          </>
        )}
        {sensors.current[layout.packet]?.[(props.match?.params?.code ?? 'dream') + '_critical_list']
          .length > 0 && (
          <>
            <div className='row g-5 g-xl-8'>
              <div className='col-xl-12'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bolder fs-3 mb-1'>Critical </span>
                  <span className='text-muted mt-1 fw-bold fs-7'>
                    {/* {sensors.current[layout.packet]?.[
                      (props.match?.params?.code ?? 'dream') + '_critical_list'
                    ] &&
                      ' | ' +
                        sensors.current[layout.packet]?.[
                          (props.match?.params?.code ?? 'dream') + '_critical_list'
                        ].length +
                        ' Records'} */}
                  </span>
                </h3>
              </div>
            </div>
            <div className='row g-5 g-xl-8'>
              {/* begin::Col */}
              {sensors.current[layout.packet]?.[
                (props.match?.params?.code ?? 'dream') + '_critical_list'
              ] &&
                sensors.current[layout.packet]?.[
                  (props.match?.params?.code ?? 'dream') + '_critical_list'
                ].map((item, index) => (
                  <div className='col-xl-4'>
                    {/* {JSON.stringify(item.Data)} */}
                    <MixedWidget49
                      className='card-xl-stretch mb-xl-8'
                      chartColor='warning'
                      chartHeight='150px'
                      name={'ddd'}
                      type='OFFLINE'
                      min='-19°C'
                      max='-25°C'
                      val={'11'}
                      Link={'#'}
                      data={JSON.stringify(item.Data)}
                    />
                    {/*  <MixedWidget49
                      className='card-xl-stretch mb-xl-8'
                      chartColor='warning'
                      chartHeight='150px'
                      name={'ddd'}
                      type='OFFLINE'
                      min='-19°C'
                      max='-25°C'
                      val={'11'}
                      Link={'#'}
                      data={item.Data}
                    />
                
                    /> */}
                  </div>
                ))}
              {sensors.current[layout.packet]?.[
                (props.match?.params?.code ?? 'dream') + '_critical_list'
              ] &&
                sensors.current[layout.packet]?.[
                  (props.match?.params?.code ?? 'dream') + '_critical_list'
                ] == 0 && <div className='col-xl-12'>No Records Found</div>}
            </div>
          </>
        )}
        {sensors.current[layout.packet]?.[(props.match?.params?.code ?? 'dream') + '_warning_list']
          .length > 0 && (
          <>
            <div className='row g-5 g-xl-8'>
              <div className='col-xl-12'>
                <br />
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bolder fs-3 mb-1'>Warning</span>
                  <span className='text-muted mt-1 fw-bold fs-7'>
                    {/* {sensors.current[layout.packet]?.[
                      (props.match?.params?.code ?? 'dream') + '_warning_list'
                    ] &&
                      ' | ' +
                        sensors.current[layout.packet]?.[
                          (props.match?.params?.code ?? 'dream') + '_warning_list'
                        ].length +
                        ' Records'} */}
                  </span>
                </h3>
              </div>
            </div>

            <div className='row g-5 g-xl-8'>
              {sensors.current[layout.packet]?.[
                (props.match?.params?.code ?? 'dream') + '_warning_list'
              ] &&
                sensors.current[layout.packet]?.[
                  (props.match?.params?.code ?? 'dream') + '_warning_list'
                ].map((item, index) => (
                  <div className='col-xl-4'>
                    {/* {JSON.stringify(item.Data)} */}
                    <MixedWidget49
                      className='card-xl-stretch mb-xl-8'
                      chartColor='warning'
                      chartHeight='150px'
                      name={'ddd'}
                      type='OFFLINE'
                      min='-19°C'
                      max='-25°C'
                      val={'11'}
                      Link={'#'}
                      data={JSON.stringify(item.Data)}
                    />
                    {/*  <MixedWidget49
                      className='card-xl-stretch mb-xl-8'
                      chartColor='warning'
                      chartHeight='150px'
                      name={'ddd'}
                      type='OFFLINE'
                      min='-19°C'
                      max='-25°C'
                      val={'11'}
                      Link={'#'}
                      data={item.Data}
                    />
                
                    /> */}
                    {/* <MixedWidget10
                      className='card-xl-stretch mb-xl-8'
                      chartColor='warning'
                      chartHeight='150px'
                      name={item.Description}
                      type='WARNING'
                      min='-19°C'
                      max='-25°C'
                      val={item.Value}
                      Link={'/facility-detail-item/' + item.Code}
                      extra={item?.Config}
                    /> */}
                  </div>
                ))}
              {sensors.current[layout.packet]?.[
                (props.match?.params?.code ?? 'dream') + '_warning_list'
              ] &&
                sensors.current[layout.packet]?.[
                  (props.match?.params?.code ?? 'dream') + '_warning_list'
                ].length == 0 && <div className='col-xl-12'>No Records Found</div>}
            </div>
          </>
        )}
        {/* end::Row */}
        {sensors.current[layout.packet]?.[(props.match?.params?.code ?? 'dream') + '_normal_list']
          .length > 0 && (
          <>
            <div className='row g-5 g-xl-8'>
              <div className='col-xl-12'>
                <br />
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bolder fs-3 mb-1'>Normal</span>
                  <span className='text-muted mt-1 fw-bold fs-7'>
                    {/* {sensors.current[layout.packet]?.[
                      (props.match?.params?.code ?? 'dream') + '_normal_list'
                    ] &&
                      ' | ' +
                        sensors.current[layout.packet]?.[
                          (props.match?.params?.code ?? 'dream') + '_normal_list'
                        ].length +
                        ' Records'} */}
                  </span>
                </h3>
              </div>
            </div>

            <div className='row g-5 g-xl-8'>
              {sensors.current[layout.packet]?.[
                (props.match?.params?.code ?? 'dream') + '_normal_list'
              ] &&
                sensors.current[layout.packet]?.[
                  (props.match?.params?.code ?? 'dream') + '_normal_list'
                ].map((item, index) => (
                  <div className='col-xl-4'>
                    {/* {JSON.stringify(item.Data)} */}
                    <MixedWidget49
                      className='card-xl-stretch mb-xl-8'
                      chartColor='warning'
                      chartHeight='150px'
                      name={'ddd'}
                      type='OFFLINE'
                      min='-19°C'
                      max='-25°C'
                      val={'11'}
                      Link={'#'}
                      data={JSON.stringify(item.Data)}
                    />
                    {/*  <MixedWidget49
                      className='card-xl-stretch mb-xl-8'
                      chartColor='warning'
                      chartHeight='150px'
                      name={'ddd'}
                      type='OFFLINE'
                      min='-19°C'
                      max='-25°C'
                      val={'11'}
                      Link={'#'}
                      data={item.Data}
                    />
                
                    /> */}
                    {/* <MixedWidget10
                      className='card-xl-stretch mb-xl-8'
                      chartColor='success'
                      chartHeight='150px'
                      name={item.Description}
                      type='NORMAL'
                      min='-19°C'
                      max='-25°C'
                      val={item.Value}
                      Link={'/facility-detail-item/' + item.Code}
                      extra={item?.Config}
                    /> */}
                  </div>
                ))}

              {sensors.current[layout.packet]?.[
                (props.match?.params?.code ?? 'dream') + '_normal_list'
              ] &&
                sensors.current[layout.packet]?.[
                  (props.match?.params?.code ?? 'dream') + '_normal_list'
                ].length == 0 && <div className='col-xl-12'>No Records Found</div>}
            </div>
          </>
        )}
        {sensors.current[layout.packet]?.[(props.match?.params?.code ?? 'dream') + '_offline_list']
          .length > 0 && (
          <>
            <div className='row g-5 g-xl-8'>
              <div className='col-xl-12'>
                <br />
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bolder fs-3 mb-1'>Offline</span>
                  <span className='text-muted mt-1 fw-bold fs-7'>
                    {/* {sensors.current[layout.packet]?.[
                      (props.match?.params?.code ?? 'dream') + '_offline_list'
                    ] &&
                      ' | ' +
                        sensors.current[layout.packet]?.[
                          (props.match?.params?.code ?? 'dream') + '_offline_list'
                        ].length +
                        ' Records'} */}
                  </span>
                </h3>
              </div>
            </div>

            <div className='row g-5 g-xl-8'>
              {sensors.current[layout.packet]?.[
                (props.match?.params?.code ?? 'dream') + '_offline_list'
              ] &&
                sensors.current[layout.packet]?.[
                  (props.match?.params?.code ?? 'dream') + '_offline_list'
                ].map((item, index) => (
                  <div className='col-xl-4'>
                    {/* {JSON.stringify(item.Data)} */}
                    {/* {JSON.stringify(item.Data.find(({Name}) => Name === 'MCU_TEMP'))} */}
                    <MixedWidget49
                      className='card-xl-stretch mb-xl-8'
                      chartColor='warning'
                      chartHeight='150px'
                      name={'ddd'}
                      type='OFFLINE'
                      min='-19°C'
                      max='-25°C'
                      val={'11'}
                      Link={'#'}
                      data={JSON.stringify(item.Data)}
                    />
                    {/*  
                
                    /> */}
                  </div>
                ))}
              {sensors.current[layout.packet]?.[
                (props.match?.params?.code ?? 'dream') + '_offline_list'
              ] &&
                sensors.current[layout.packet]?.[
                  (props.match?.params?.code ?? 'dream') + '_offline_list'
                ].length == 0 && <div className='col-xl-12'>No Records Found</div>}
            </div>
          </>
        )}
        <br />
        <br />
        <div className='row g-5 g-xl-8'></div>
      </>
    </>
  )
}

export default BusinessUnitDetailManager2
