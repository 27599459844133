/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { FC } from 'react'
import { Redirect, Switch, Route } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import { PrivateRoutes } from './PrivateRoutes'
import { Logout, AuthPage } from '../modules/auth'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { RootState } from '../../setup'
import Home from '../ui/knitting/dashboard/Home'
import Test from "../ui/sensor/config/Test";

import Testx from '../ui/knitting/dashboard/Testx'
import SensorIndex from "../ui/sensor/config/index";
import Display from "../ui/sensor/config/display";
import MainDashboard from "../ui/sensor/config/MainDashboard";
import FactoryDashboard from "../ui/sensor/config/FactoryDashboard";
import DetailDashboard from "../ui/sensor/config/DetailDashboard";




import Map from "../ui/sensor/config/Map";
import ViewAll from "../ui/sensor/config/ViewAll"
import { useDataLayerValue } from "../../DataLayer";


const Routes: FC = () => {
  const isAuthorized = useSelector<RootState>(({ auth }) => auth.user, shallowEqual)
  const [{ token, user }, dispatch] = useDataLayerValue();


  return (
    <>

      <Switch>

        {!token ? (
          /*Render auth page when user at `/auth` and not authorized.*/
          <Route>
            <AuthPage />
          </Route>
        ) : (
          /*Otherwise redirect to root page (`/`)*/
          <Redirect from='/auth' to={'/facility/' + user.facility} />
        )}

        <Route path='/map/:id' component={Map} />
        <Route path='/error' component={ErrorsPage} />
        <Route path='/logout' component={Logout} />

        {!token ? (
          /*Redirect to `/auth` when user is not authorized*/
          <Redirect to='/auth/login' />
        ) : (
          <>

            <MasterLayout>
              <PrivateRoutes />
            </MasterLayout>
          </>
        )}
      </Switch>

    </>
  )

  if (token !== '' && token !== null && token !== undefined) {
    return (
      <Switch>

        <Route path='/error' component={ErrorsPage} />
        <Route path='/logout' component={Logout} />
        <Route path='/pmp-knt-live' component={Home} />
        <Route path='/group-layout/:id' component={ViewAll} />
        <Route path='/layout/:id' component={Display} />
        <Route path='/chain' component={MainDashboard} />
        <Route path='/factory' component={FactoryDashboard} />
        <Route path='/detail' component={DetailDashboard} />
        <Route path='/map/:id' component={Map} />
        <Route path='/Sensor' component={SensorIndex} />
        <Route path='/tt' component={Test} />




        <MasterLayout>
          <PrivateRoutes />
        </MasterLayout>

      </Switch>
    )
  }

  return (
    <>

      <Switch>

        {!token ? (
          /*Render auth page when user at `/auth` and not authorized.*/
          <Route>
            <AuthPage />
          </Route>
        ) : (

          /*Otherwise redirect to root page (`/`)*/
          <Redirect from='/auth' to='/' />
        )}

        <Route path='/error' component={ErrorsPage} />
        <Route path='/logout' component={Logout} />

        {!token ? (
          /*Redirect to `/auth` when user is not authorized*/
          <Redirect to='/auth/login' />
        ) : (
          <>
            <MasterLayout>
              <PrivateRoutes />
            </MasterLayout>
          </>
        )}
      </Switch>

    </>
  )

  return (
    <Switch>


      <Route>
        <AuthPage />
      </Route>
      {/*
      !(token !== '' && token !== null && token !== undefined) ? (
      <Route>
        <AuthPage />
      </Route>
      ) : (
      <Redirect from='/auth' to='/:uuid' />
      )


      !(token !== '' && token !== null && token !== undefined) ? (
      <Redirect to='/auth/login/:uuid' />
      ) : (
      <MasterLayout>
        <PrivateRoutes />
      </MasterLayout>
      )


 */}



      <MasterLayout>
        <PrivateRoutes />
      </MasterLayout>

      <Route path='/error' component={ErrorsPage} />
      <Route path='/logout' component={Logout} />
      <Route path='/pmp-knt-live' component={Home} />
      <Route path='/group-layout/:id' component={ViewAll} />
      <Route path='/layout/:id' component={Display} />
      <Route path='/map/:id' component={Map} />
      <Route path='/sensor/viewall/:id' component={ViewAll} />
      <Route path='/Sensor/view' component={Display} />
      <Route path='/Sensor' component={SensorIndex} />


    </Switch>
  )
}

export { Routes }
