import React, { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { requestPassword } from '../redux/AuthCRUD'
import { axioDefaultConfig } from '../../../../app/ui/common/commonResource'
import axios from 'axios'

const initialValues = {
  email: '',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
})

export function ForgotPassword() {

  const [isSubmitting, setSubmitting] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const formik = useFormik({

    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, { setStatus }) => {
      setSubmitting(true)
      setHasErrors(undefined)
      setTimeout(() => {


        console.log(values)
        axios({
          ...axioDefaultConfig,
          method: 'post',
          url: process.env.REACT_APP_BACKEND_API + '/api/accounts/ForgetPassword',
          data: values,
        })
          .then(function (response) {
            console.log(response);

            if (response?.data?.token !== '' && response?.data?.token !== null) {

              // setTempToken(response?.data?.token)
              setHasErrors(false)
              // setLoading(false)
              // setSubmitting(false)
              setStatus(response?.data.text)
              setSubmitting(false)

              // dispatch(auth.actions.login(response?.data?.token))
              // dispatch(auth.actions.setUser(response?.data))
            } else {

              setSubmitting(false)
              setStatus(response.data.message)
            }
          })
          .catch(() => {
            setHasErrors(true)
            setSubmitting(false)
            setStatus('The login detail is incorrect')
          })
      }, 1000)

    },
  })


  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark mb-3'>Forgot Password ?</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-400 fw-bold fs-4'>Enter your email to reset your password.</div>
          {/* end::Link */}
        </div>

        {/* begin::Title */}
        {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>
              Sorry, looks like there are some errors detected, please try again.
            </div>
          </div>
        )}

        {hasErrors === false && (
          <div className='mb-10 bg-light-info p-8 rounded'>
            <div className='text-info'>Password reset request was sent successfully.  Please check your email to reset your password</div>
          </div>
        )}
        {/* end::Title */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
          <input
            type='email'
            placeholder=''
            autoComplete='off'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              { 'is-invalid': formik.touched.email && formik.errors.email },
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='text-danger mt-2'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <button type='submit' className='btn btn-primary me-4' disabled={isSubmitting}>
            <span className='indicator-progress' style={{ display: 'block' }}>
              {!isSubmitting && (
                <span className='indicator-label'>
                  Save &nbsp;
                </span>
              )}
              {isSubmitting && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  <span className='spinner-border spinner-border-lg align-middle ms-2'></span>
                </span>
              )}
            </span>
          </button>
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              className='btn btn-lg btn-light-primary fw-bolder'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              Cancel
            </button>
          </Link>{' '}
        </div>
        {/* <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <button
            type='submit'
            id='kt_password_reset_submit'
            className='btn btn-lg btn-primary fw-bolder me-4'
          >
            <span className='indicator-label'>Submit</span>
            {isSubmitting && (
              <span className='indicator-progress'>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              className='btn btn-lg btn-light-primary fw-bolder'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              Cancel
            </button>
          </Link>{' '}
        </div> */}
        {/* end::Form group */}
      </form>
    </>
  )
}
