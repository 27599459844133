import React, {useState, useEffect, useRef, PureComponent} from 'react'
import {
  PieChart,
  Pie,
  LineChart,
  BarChart,
  Bar,
  AreaChart,
  Label,
  Area,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Cell,
  ResponsiveContainer,
} from 'recharts'
import parse from 'html-react-parser'
import ChartJ from './chart/Chartj'

import './../../../../_metronic/assets/sass/kntdashbaord.css'
import Draggable from 'react-draggable'
import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'
import Chart from './chart/Chart'
var randomColor = require('randomcolor')

function SensorArea(props) {
  const RADIAN = Math.PI / 180
  const Gradients = (item) => {
    var lines = []
    if (item.lines !== undefined && item.lines.length > 0) {
      for (let index = 0; index < item.lines.length; index++) {
        const element = item.lines[index]
        lines.push(
          <linearGradient id={element.dataKey} x1='0' y1='0' x2='0' y2='1'>
            <stop
              offset='5%'
              stopColor={element?.stroke ?? '#8884d8'}
              stopOpacity={element?.fillTopOpacity ?? 0.8}
            />
            <stop
              offset='95%'
              stopColor={element?.stroke ?? '#8884d8'}
              stopOpacity={element?.fillBottomOpacity ?? 0}
            />
          </linearGradient>
        )
      }
    }
    return lines
  }

  const Article = (item, LineCode) => {
    var lines = []
    if (item.lines !== undefined && item.lines.length > 0) {
      for (let index = 0; index < item.lines.length; index++) {
        const element = item.lines[index]
        switch (element?.Line ?? LineCode) {
          case 'Line':
            lines.push(
              <Line
                name={element?.name ?? element.dataKey}
                strokeWidth={element?.strokeWidth ?? 2}
                type={element?.type ?? 'monotone'}
                dot={element?.dot ?? true}
                dataKey={element.dataKey}
                stroke={element?.stroke ?? '#8884d8'}
                activeDot={{r: 8}}
              />
            )
            break
          case 'Area':
            lines.push(
              <Area
                name={element?.name ?? element.dataKey}
                strokeWidth={element?.strokeWidth ?? 2}
                type={element?.type ?? 'monotone'}
                dataKey={element.dataKey}
                fillOpacity={element?.fillOpacity ?? 1}
                stroke={element?.stroke ?? '#8884d8'}
                fill={'url(#' + element.dataKey + ')'}
              />
            )
            break
          case 'Bar':
            lines.push(
              <Bar
                name={element?.name ?? element.dataKey}
                dataKey={element.dataKey}
                fill={element?.fill ?? '#8884d8'}
                stackId={element?.stackId ?? ''}
                type={element?.type ?? ''}
                fillOpacity={element?.fillOpacity ?? 1}
                stroke={element?.stroke ?? '#8884d8'}
              />
            )
            break
          case 'Pie':
            lines.push(
              <Cell
                name={element?.name ?? element.dataKey}
                dataKey={element.dataKey}
                fill={element?.fill ?? '#8884d8'}
              />
            )
            break
          default:
            lines.push(
              <Line
                name={element?.name ?? element.dataKey}
                type={element?.type ?? 'monotone'}
                dot={element?.dot ?? true}
                dataKey={element.dataKey}
                fill={element?.fill ?? '#8884d8'}
                activeDot={{r: 8}}
              />
            )
            break
        }
      }
    }
    return lines
  }

  const htmlDecode = (input) => {
    var e = document.createElement('div')
    e.innerHTML = input
    return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue
  }

  const renderCustomizedLabel = ({cx, cy, midAngle, innerRadius, outerRadius, percent, index}) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)

    return (
      <text
        x={x}
        y={y}
        fill='white'
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline='central'
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    )
  }

  /*try {
   // console.log("CODE"+props.layout.globalJs)
    eval(props.layout.globalJs)
  } catch (error) {
    // console.log("E"+error);
  }*/

  return (
    <>
      {props.state.SensorList.map((item, index) => {
        if (item.note === '<p><br></p>') {
          item.note = ''
        }

        return (
          <>
            <Draggable key={item.id} defaultPosition={item.defaultPos} onStart={() => false}>
              <div style={{fontFamily: 'Roboto Mono, monospace', zIndex: 10, position: 'absolute'}}>
                <a href={item?.url ?? '#'} target={item?.url ? '_blank' : '_self'}>
                  {(item.type == 11 || item.type == 12) && item.displayName !== '' && (
                    <div style={{color: item.color}} className='sensor' key={item.id}>
                      <table
                        style={{
                          fontSize: item?.fontSize ?? '13px',
                          width: item?.width ?? '150px',
                        }}
                      >
                        <tr>
                          <td
                            style={{
                              width: item?.captionWidth ?? '30%',
                              left: '0px',
                              float: 'left',
                            }}
                          >
                            <b style={{position: 'absolute', fontSize: item?.fontSize ?? '13px'}}>
                              {'' + item.displayName}
                            </b>
                          </td>
                          <td
                            style={{
                              width: item?.valueWidth ?? '60%',
                              textAlign: item?.textAlign ?? 'right',
                              right: '0px',
                              float: 'right',
                            }}
                          >
                            <b style={{marginTop: '-0px', fontSize: item?.fontSize ?? '13px'}}>
                              {' '}
                              {'' + item.value}
                            </b>
                          </td>
                          <td style={{width: '10%'}}>
                            {item.note === '' && (
                              <>
                                {item.icon === 'dot' && (
                                  <>
                                    <span
                                      style={{
                                        fontSize: item?.fontSize ?? '13px',
                                        backgroundColor: item.color,
                                        marginTop: '-7px',
                                        position: 'absolute',
                                      }}
                                      className='dot'
                                    ></span>
                                    {item.isNotify === true && (
                                      <span
                                        style={{
                                          fontSize: item?.fontSize ?? '13px',
                                          backgroundColor: item.color,
                                          marginTop: '-20px',
                                          marginLeft: '-12px',
                                        }}
                                        className='pulse-ring'
                                      >
                                        {' '}
                                      </span>
                                    )}
                                  </>
                                )}

                                {item.icon !== '' && item.icon !== 'dot' && (
                                  <>
                                    <i
                                      style={{
                                        fontSize: item?.fontSize ?? '13px',
                                        color: item.color,
                                        marginTop: '-7px',
                                        position: 'absolute',
                                      }}
                                      className={item.icon}
                                    ></i>
                                    {item.isNotify === true && (
                                      <span
                                        style={{
                                          fontSize: item?.fontSize ?? '13px',
                                          backgroundColor: item.color,
                                          marginTop: '-8px',
                                          marginLeft: '15px',
                                        }}
                                        className='pulse-ring'
                                      >
                                        {' '}
                                      </span>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                            {item.note !== '' && (
                              <>
                                {item.isNotify === true && (
                                  <span
                                    style={{
                                      fontSize: item?.fontSize ?? '13px',
                                      backgroundColor: item.color,
                                      marginTop: '-9px',
                                      marginLeft: '-12px',
                                    }}
                                    className='pulse-ring'
                                  >
                                    {' '}
                                  </span>
                                )}
                                {item.icon === 'dot' && (
                                  <span
                                    style={{
                                      fontSize: item?.fontSize ?? '13px',
                                      backgroundColor: item.color,
                                      marginTop: '2px',
                                      position: 'absolute',
                                    }}
                                    className='dot'
                                  ></span>
                                )}
                                {item.icon !== '' && item.icon !== 'dot' && (
                                  <i
                                    style={{
                                      fontSize: item?.fontSize ?? '13px',
                                      color: item.color,
                                      marginTop: '2px',
                                      position: 'absolute',
                                    }}
                                    className={item.icon}
                                  ></i>
                                )}
                                <Popup
                                  trigger={
                                    <i
                                      className='fas fa-info-circle'
                                      style={{
                                        fontSize: item?.fontSize ?? '13px',
                                        marginLeft: '20px',
                                        color: '#6495ed',
                                        cursor: 'pointer',
                                      }}
                                    ></i>
                                  }
                                  position='top left'
                                  modal
                                >
                                  {(close) => (
                                    <div>
                                      Documentation
                                      <a
                                        className='close'
                                        style={{
                                          float: 'right',
                                          fontSize: '35px',
                                          marginTop: '-15px',
                                        }}
                                        onClick={close}
                                      >
                                        &times;
                                      </a>
                                      <div className='card mb-5 mb-xl-12'>
                                        <div
                                          className='card-body pt-3 pb-0'
                                          style={{borderBottom: '1px solid'}}
                                        >
                                          {' '}
                                        </div>
                                        <div className='d-flex flex-wrap '>
                                          <div dangerouslySetInnerHTML={{__html: item.note}}></div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </Popup>
                              </>
                            )}
                          </td>
                        </tr>
                      </table>
                    </div>
                  )}

                  {(item.type == 11 || item.type == 12) && item.displayName === '' && (
                    <div style={{color: item.color}} className='sensor' key={item.id}>
                      <table
                        style={{
                          fontSize: item?.fontSize ?? '13px',
                          width: item?.width ?? '150px',
                        }}
                      >
                        <tr>
                          <td
                            style={{
                              width: item?.valueWidth ?? '90%',
                              textAlign: item?.textAlign ?? 'right',
                            }}
                          >
                            <b style={{fontSize: item?.fontSize ?? '13px', marginTop: '-0px'}}>
                              {' '}
                              {item.value}
                            </b>
                            {item.note === '' && (
                              <>
                                {item.icon === 'dot' && (
                                  <span
                                    style={{
                                      fontSize: item?.fontSize ?? '13px',
                                      backgroundColor: item.color,
                                      marginTop: '2px',
                                      position: 'absolute',
                                    }}
                                    className='dot'
                                  ></span>
                                )}
                                {item.icon !== '' && item.icon !== 'dot' && (
                                  <i
                                    style={{
                                      fontSize: item?.fontSize ?? '13px',
                                      color: item.color,
                                      marginTop: '2px',
                                      position: 'absolute',
                                    }}
                                    className={item.icon}
                                  ></i>
                                )}
                                {item.isNotify === true && (
                                  <span
                                    style={{
                                      backgroundColor: item.color,
                                      marginTop: '-8px',
                                      marginLeft: '15px',
                                    }}
                                    className='pulse-ring'
                                  >
                                    {' '}
                                  </span>
                                )}
                              </>
                            )}

                            {item.note !== '' && (
                              <>
                                {item.icon === 'dot' && (
                                  <span
                                    style={{
                                      fontSize: item?.fontSize ?? '13px',
                                      backgroundColor: item.color,
                                      marginTop: '2px',
                                      position: 'absolute',
                                    }}
                                    className='dot'
                                  ></span>
                                )}
                                {item.icon !== '' && item.icon !== 'dot' && (
                                  <i
                                    style={{
                                      fontSize: item?.fontSize ?? '13px',
                                      color: item.color,
                                      marginTop: '2px',
                                      position: 'absolute',
                                    }}
                                    className={item.icon}
                                  ></i>
                                )}
                                {item.isNotify === true && (
                                  <span
                                    style={{
                                      backgroundColor: item.color,
                                      marginTop: '-8px',
                                      marginLeft: '15px',
                                    }}
                                    className='pulse-ring'
                                  >
                                    {' '}
                                  </span>
                                )}
                                <Popup
                                  trigger={
                                    <i
                                      className='fas fa-info-circle'
                                      style={{
                                        marginLeft: '20px',
                                        color: '#6495ed',
                                        cursor: 'pointer',
                                      }}
                                    ></i>
                                  }
                                  position='top left'
                                  modal
                                >
                                  {(close) => (
                                    <div>
                                      Documentation
                                      <a
                                        className='close'
                                        style={{
                                          float: 'right',
                                          fontSize: '35px',
                                          marginTop: '-15px',
                                        }}
                                        onClick={close}
                                      >
                                        &times;
                                      </a>
                                      <div className='card mb-5 mb-xl-12'>
                                        <div
                                          className='card-body pt-3 pb-0'
                                          style={{borderBottom: '1px solid'}}
                                        >
                                          {' '}
                                        </div>
                                        <div
                                          className='d-flex flex-wrap '
                                          style={{display: 'inline-block'}}
                                        >
                                          <div dangerouslySetInnerHTML={{__html: item.note}}></div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </Popup>
                              </>
                            )}
                          </td>
                        </tr>
                      </table>
                    </div>
                  )}

                  {item.type == 20 && (
                    <div
                      style={{
                        color: item.color,
                        paddingTop: '0px',
                        paddingLeft: '0px',
                        padding: '0px',
                      }}
                      className='sensor'
                      key={item.id}
                    >
                      <table
                        style={{
                          width: item?.width ?? '150px',
                          cellpadding: '0',
                          cellspacing: '0',
                        }}
                      >
                        <tr>
                          <td
                            style={{
                              width: item?.captionWidth ?? '30%',
                              left: '0px',
                              float: 'left',
                              cellpadding: '0',
                              cellspacing: '0',
                            }}
                          >
                            <b style={{fontSize: item?.fontSize ?? '13px', position: 'absolute'}}>
                              {'' + item.displayName}
                            </b>
                          </td>
                          <td
                            style={{
                              width: item?.valueWidth ?? '60%',
                              textAlign: item?.textAlign ?? 'right',
                              right: '0px',
                              float: 'right',
                            }}
                          >
                            <b style={{fontSize: item?.fontSize ?? '13px', marginTop: '-0px'}}>
                              {' '}
                              {'' + item.value}
                            </b>
                          </td>
                          <td style={{width: '10%'}}>
                            {item.note === '' && (
                              <>
                                {item.icon === 'dot' && (
                                  <>
                                    <span
                                      style={{
                                        fontSize: item?.fontSize ?? '13px',
                                        backgroundColor: item.color,
                                        marginTop: '-7px',
                                        position: 'absolute',
                                      }}
                                      className='dot'
                                    ></span>
                                    {item.isNotify === true && (
                                      <span
                                        style={{
                                          fontSize: item?.fontSize ?? '13px',
                                          backgroundColor: item.color,
                                          marginTop: '-20px',
                                          marginLeft: '-12px',
                                        }}
                                        className='pulse-ring'
                                      >
                                        {' '}
                                      </span>
                                    )}
                                  </>
                                )}

                                {item.icon !== '' && item.icon !== 'dot' && (
                                  <>
                                    <i
                                      style={{
                                        fontSize: item?.fontSize ?? '13px',
                                        color: item.color,
                                        marginTop: '-7px',
                                        position: 'absolute',
                                      }}
                                      className={item.icon}
                                    ></i>
                                    {item.isNotify === true && (
                                      <span
                                        style={{
                                          fontSize: item?.fontSize ?? '13px',
                                          backgroundColor: item.color,
                                          marginTop: '-8px',
                                          marginLeft: '15px',
                                        }}
                                        className='pulse-ring'
                                      >
                                        {' '}
                                      </span>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                            {item.note !== '' && (
                              <>
                                {item.isNotify === true && (
                                  <span
                                    style={{
                                      fontSize: item?.fontSize ?? '13px',
                                      backgroundColor: item.color,
                                      marginTop: '-9px',
                                      marginLeft: '-12px',
                                    }}
                                    className='pulse-ring'
                                  >
                                    {' '}
                                  </span>
                                )}
                                {item.icon === 'dot' && (
                                  <span
                                    style={{
                                      fontSize: item?.fontSize ?? '13px',
                                      backgroundColor: item.color,
                                      marginTop: '2px',
                                      position: 'absolute',
                                    }}
                                    className='dot'
                                  ></span>
                                )}
                                {item.icon !== '' && item.icon !== 'dot' && (
                                  <i
                                    style={{
                                      color: item.color,
                                      marginTop: '2px',
                                      position: 'absolute',
                                    }}
                                    className={item.icon}
                                  ></i>
                                )}
                                <Popup
                                  trigger={
                                    <i
                                      className='fas fa-info-circle'
                                      style={{
                                        marginLeft: '20px',
                                        color: '#6495ed',
                                        cursor: 'pointer',
                                      }}
                                    ></i>
                                  }
                                  position='top left'
                                  modal
                                >
                                  {(close) => (
                                    <div>
                                      Documentation
                                      <a
                                        className='close'
                                        style={{
                                          float: 'right',
                                          fontSize: '35px',
                                          marginTop: '-15px',
                                        }}
                                        onClick={close}
                                      >
                                        &times;
                                      </a>
                                      <div className='card mb-5 mb-xl-12'>
                                        <div
                                          className='card-body pt-3 pb-0'
                                          style={{borderBottom: '1px solid'}}
                                        >
                                          {' '}
                                        </div>
                                        <div
                                          className='d-flex flex-wrap '
                                          style={{display: 'inline-block'}}
                                        >
                                          <div dangerouslySetInnerHTML={{__html: item.note}}></div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </Popup>
                              </>
                            )}
                          </td>
                        </tr>
                      </table>
                    </div>
                  )}

                  {item.type == 19 && (
                    <div
                      style={{
                        color: item.color,
                        width: item.height,
                        paddingTop: '0px',
                        paddingLeft: '0px',
                        padding: '0px',
                      }}
                      className='sensor2'
                      key={item.id}
                    >
                      <div
                        className={'h-' + (item.width ?? '10px') + ' mx-3 w-100 bg-light mb-3'}
                        style={{transform: 'rotate(270deg)', transformOrigin: '10px 15px'}}
                      >
                        <div
                          className={'rounded h-' + (item.width ?? '10px')}
                          role='progressbar'
                          style={{
                            width: item.value ?? '10%',
                            transform: 'rotate(0deg)',
                            backgroundColor: item.color ?? 'black',
                          }}
                        ></div>
                      </div>
                    </div>
                  )}

                  {item.type == 10 && (
                    <div style={{color: item.color}} className='sensor' key={item.id}>
                      <b
                        style={{
                          fontSize: item?.fontSize ?? '13px',
                          marginTop: '-5px',
                          marginLeft: '5px',
                          position: 'absolute',
                        }}
                      >
                        {' '}
                        {item.displayName}{' '}
                      </b>
                      {item.icon === 'dot' && (
                        <>
                          <span
                            style={{
                              fontSize: item?.fontSize ?? '13px',
                              backgroundColor: item.color,
                              marginTop: '-7px',
                              position: 'absolute',
                            }}
                            className='dot'
                          ></span>
                          {item.isNotify === true && (
                            <span
                              style={{
                                fontSize: item?.fontSize ?? '13px',
                                backgroundColor: item.color,
                                marginTop: '-20px',
                                marginLeft: '-12px',
                              }}
                              className='pulse-ring'
                            >
                              {' '}
                            </span>
                          )}
                        </>
                      )}

                      {item.icon !== '' && item.icon !== 'dot' && (
                        <>
                          <i
                            style={{
                              fontSize: item?.fontSize ?? '13px',
                              color: item.color,
                              marginTop: '-7px',
                              position: 'absolute',
                            }}
                            className={item.icon}
                          ></i>
                          {item.isNotify === true && (
                            <span
                              style={{
                                fontSize: item?.fontSize ?? '13px',
                                backgroundColor: item.color,
                                marginTop: '-8px',
                                marginLeft: '15px',
                              }}
                              className='pulse-ring'
                            >
                              {' '}
                            </span>
                          )}
                        </>
                      )}
                    </div>
                  )}

                  {item.type == 14 && (
                    <div style={{color: item.color}} className='sensor' key={item.id}>
                      <AreaChart
                        width={item.width ?? 500}
                        height={item.height ?? 300}
                        data={item.value}
                      >
                        <defs>{Gradients(item)}</defs>
                        {item.isEnableCartesianGrid && <CartesianGrid strokeDasharray='3 3' />}
                        <XAxis
                          dy={item?.xAxis?.dy ?? 20}
                          minTickGap={item?.xAxis?.minTickGap ?? 5}
                          axisLine={item?.xAxis?.axisLine ?? true}
                          dataKey={item?.xAxis?.dataKey ?? 'name'}
                          interval={item?.xAxis?.interval ?? 5}
                          angle={item?.xAxis?.angle ?? 0}
                          dx={item?.xAxis?.dx ?? 0}
                          label={item?.xAxis?.label ?? ''}
                          textAnchor={item?.xAxis?.textAnchor ?? 'end'}
                        />
                        <YAxis
                          minTickGap={item?.yAxis?.minTickGap ?? 5}
                          axisLine={item?.yAxis?.axisLine ?? true}
                          tickLine={false}
                          dy={item?.yAxis?.dy ?? 20}
                          dx={item?.yAxis?.dx ?? 0}
                          position='insideLeft'
                          style={{textAnchor: 'middle'}}
                          domain={item?.yAxis?.domain ?? [175, 250]}
                        >
                          <Label
                            angle={-90}
                            value={item?.yAxis?.label ?? ''}
                            position='insideLeft'
                            style={{textAnchor: 'middle'}}
                          />
                        </YAxis>

                        {Article(item, 'Area')}
                        {item.isLegend && (
                          <Legend
                            layout={item?.legend?.layout ?? 'horizontal'}
                            verticalAlign={item?.legend?.verticalAlign ?? 'bottom'}
                            align={item?.legend?.align ?? 'middle'}
                            margin={item?.legend?.margin ?? {top: 0}}
                          />
                        )}
                        {item.isTooltip && <Tooltip />}
                      </AreaChart>
                    </div>
                  )}

                  {item.type == 13 && (
                    <div style={{color: item.color}} className='sensor' key={item.id}>
                      <LineChart
                        width={item.width ?? 500}
                        height={item.height ?? 300}
                        data={item.value}
                      >
                        {item.isEnableCartesianGrid && <CartesianGrid strokeDasharray='3 3' />}
                        <XAxis
                          dy={item?.xAxis?.dy ?? 20}
                          minTickGap={item?.xAxis?.minTickGap ?? 5}
                          axisLine={item?.xAxis?.axisLine ?? true}
                          dataKey={item?.xAxis?.dataKey ?? 'name'}
                          interval={item?.xAxis?.interval ?? 5}
                          angle={item?.xAxis?.angle ?? 0}
                          dx={item?.xAxis?.dx ?? 0}
                          label={item?.xAxis?.label ?? ''}
                          textAnchor={item?.xAxis?.textAnchor ?? 'end'}
                        />
                        <YAxis
                          minTickGap={item?.yAxis?.minTickGap ?? 5}
                          axisLine={item?.yAxis?.axisLine ?? true}
                          dy={item?.yAxis?.dy ?? 20}
                          dx={item?.yAxis?.dx ?? 0}
                          position='insideLeft'
                          style={{textAnchor: 'middle'}}
                          domain={item?.yAxis?.domain ?? [175, 250]}
                        >
                          <Label
                            angle={-90}
                            value={item?.yAxis?.label ?? ''}
                            position='insideLeft'
                            style={{textAnchor: 'middle'}}
                          />
                        </YAxis>
                        {item.isLegend && (
                          <Legend
                            layout={item?.legend?.layout ?? 'horizontal'}
                            verticalAlign={item?.legend?.verticalAlign ?? 'bottom'}
                            align={item?.legend?.align ?? 'middle'}
                            margin={item?.legend?.margin ?? {top: 0}}
                          />
                        )}

                        {item.isTooltip && <Tooltip />}
                        {Article(item, 'Line')}
                      </LineChart>
                    </div>
                  )}

                  {item.type == 26 && (
                    <div style={{color: item.color}} key={item.id}>
                      <>
                        <ChartJ item={item} />
                      </>
                    </div>
                  )}

                  {item.type == 17 && (
                    <div style={{color: item.color}} className='sensor' key={item.id}>
                      <PieChart
                        width={item?.width ?? 200}
                        height={item?.height ?? 200}
                        margin={{
                          top: -120,
                          left: -120,
                        }}
                      >
                        <Pie
                          data={item?.value}
                          cx={200}
                          cy={200}
                          labelLine={false}
                          label={renderCustomizedLabel}
                          outerRadius={80}
                          fill='#8884d8'
                          dataKey='value'
                        >
                          {Article(item, 'Pie')}
                        </Pie>
                        {item.isLegend && (
                          <Legend
                            layout={item?.legend?.layout ?? 'horizontal'}
                            verticalAlign={item?.legend?.verticalAlign ?? 'bottom'}
                            align={item?.legend?.align ?? 'middle'}
                            margin={item?.legend?.margin ?? {top: 0}}
                          />
                        )}
                      </PieChart>
                    </div>
                  )}

                  {item.type == 15 && (
                    <div style={{color: item.color}} className='sensor' key={item.id}>
                      <BarChart
                        width={item.width ?? 500}
                        height={item.height ?? 300}
                        data={item.value}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 20,
                          bottom: 5,
                        }}
                      >
                        {item.isEnableCartesianGrid && <CartesianGrid strokeDasharray='3 3' />}
                        <XAxis
                          dy={item?.xAxis?.dy ?? 20}
                          minTickGap={item?.xAxis?.minTickGap ?? 5}
                          axisLine={item?.xAxis?.axisLine ?? true}
                          dataKey={item?.xAxis?.dataKey ?? 'name'}
                          interval={item?.xAxis?.interval ?? 5}
                          angle={item?.xAxis?.angle ?? 0}
                          dx={item?.xAxis?.dx ?? 0}
                          label={item?.xAxis?.label ?? ''}
                          textAnchor={item?.xAxis?.textAnchor ?? 'end'}
                        />
                        <YAxis
                          minTickGap={item?.yAxis?.minTickGap ?? 5}
                          axisLine={item?.yAxis?.axisLine ?? true}
                          dy={item?.yAxis?.dy ?? 20}
                          dx={item?.yAxis?.dx ?? 0}
                          position='insideLeft'
                          style={{textAnchor: 'middle'}}
                          domain={item?.yAxis?.domain ?? [175, 250]}
                        >
                          <Label
                            angle={-90}
                            value={item?.yAxis?.label ?? ''}
                            position='insideLeft'
                            style={{textAnchor: 'middle'}}
                          />
                        </YAxis>

                        {item.isLegend && (
                          <Legend
                            layout={item?.legend?.layout ?? 'horizontal'}
                            verticalAlign={item?.legend?.verticalAlign ?? 'bottom'}
                            align={item?.legend?.align ?? 'middle'}
                            margin={item?.legend?.margin ?? {top: 0}}
                          />
                        )}
                        {item.isTooltip && <Tooltip />}
                        {Article(item, 'Bar')}
                      </BarChart>
                    </div>
                  )}
                </a>
              </div>
            </Draggable>
          </>
        )
      })}
    </>
  )
}
export default SensorArea
//https://codesandbox.io/s/k2ds4?file=/src/App.js:128-4341
