import React, { useState, useEffect, useRef,PureComponent } from "react";
import './../../../../_metronic/assets/sass/kntdashbaord.css'
import { HubConnectionBuilder, JsonHubProtocol } from '@microsoft/signalr'
import axios from 'axios'
import useWindowDimensions from '../../helpers/WindowDimension'
import Dictionary from '../../helpers/Dictionary'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { Dropdown1 } from '../../../../_metronic/partials'
import { AsideDefault } from '../../../../_metronic/layout/components/aside/AsideDefault'
import { Footer } from '../../../../_metronic/layout/components/Footer'
import { HeaderWrapper } from '../../../../_metronic/layout/components/header/HeaderWrapper'
import { PageDataProvider } from '../../../../_metronic/layout/core'
import { Content } from '../../../../_metronic/layout/components/Content'
import { ChartsWidget1, TablesWidget1, ListsWidget5, TablesWidget5, } from '../../../../_metronic/partials/widgets'
import { stat } from 'fs'
import Confetti from 'react-confetti'
import ImageMapper from "react-image-mapper";
import Draggable from "react-draggable";
import { v4 as uuidv4 } from "uuid";
import Popup from 'reactjs-popup';
import SensorArea from './SensorArea'
import 'reactjs-popup/dist/index.css'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import { auto, hide, right } from "@popperjs/core";

var randomColor = require("randomcolor");



function Display(props) {

 
    const [connection, setConnection] = useState(null)
    const reconnectRef = useRef(0);
    const urlParams = new URLSearchParams(window.location.search);
    const [isloading, setLoading] = useState(true);
    const [item, setItem] = useState("");
    const [SensorList, setsensorList] = useState([]);
    const sensors = useRef([]);
  
  
    const [layout, setLayout] = useState(
      {
        id: props.match?.params?.id ?? props.layout, //urlParams.get('id')??props.layout,
        layoutName: "",
        imageData: null,
        devices: [],
        isZoom: false
      });
    const [state, setState] = useState({
      Data: {},
      DashboardStat: 'CONNECTING',
      SensorList: []
    })
  
    const hubService = process.env.REACT_APP_MIDAS_HUB ;//"https://signalr.midassit.com";
  
    useEffect(() => {
      if (connection) {
        connection
          .start()
          .then((result) => {
            setState({
              ...state,
              DashboardStat: 'CONNECTED',
            })
            setLoading(false)
            reconnectRef.current = 0;
            console.log('Connected!');
            connection.onreconnecting((error) => {
              setState({
                ...state,
                DashboardStat: 'RECONNECTING',
              })
              console.log('Connection lost due to error . Reconnecting.')
            })
  
            // Reconnect if hub disconnects
            connection.onclose(function (e) {
              setState({
                ...state,
                DashboardStat: 'DISCONNECTED',
              })
              console.log('Disconnected');
            })
  
            connection.on('ReceiveMessage', (message) => {
  
              if (typeof message === 'string' || message instanceof String)
              {
                message = JSON.parse(message);
              }
              
              if (message.key !== "SensorData")
                return;
  
              let dataPacket = JSON.parse(message.data)
              //console.log("[DB PACKET]"+JSON.stringify(dataPacket));
              if (dataPacket.TimeStamp === undefined || Math.round((new Date() - new Date(dataPacket.TimeStamp)) / 1000) > 10) {
                return
              } else {
  
                let newElements = {};
                Object.entries(dataPacket).map(([key, value]) => {
                  newElements[key] = value;
                });
  
                Object.entries(dataPacket).map(([key, value]) => {
                  for (let index = 0; index < sensors.current.length; index++) {
                    const element = sensors.current[index];
                    if (element.machineNo === '')
                      continue;
  
                    if (element.icon === undefined)
                      element.icon = 'dot';
  
                    if (element.isNotify === undefined || element.isNotify === '' || element.isNotify === null)
                      element.isNotify = false;
  
                    if (!element.lastUpdate) {
                      element.lastUpdate = new Date();
                    }
                    else if (Math.round((new Date() - new Date(element.lastUpdate)) / 1000) > 60) {
                      element.value = "---";
                      element.color = 'black';
                    }
  
                    let object = element.object.toString().toLowerCase();
                    if (props?.object !== undefined && props?.object !== '') {
                      object = props?.object.toString().toLowerCase();
                    }
  
  
                    if (element.machineNo.toString().toLowerCase() === key.toString().toLowerCase() && message.packetType.toString().toLowerCase() === object) {
                      element.lastUpdate = new Date();
                      element.value = value;
                      element.color = 'black';
                      try {
                        eval(element.formula)
                      } catch (error) {
                        element.value = '---'
                        element.color = 'black';
                        console.log(element.formula)
                        console.log(error);
                      }
                    }
                  }
                });
                setState({
                  ...state,
                  SensorList: sensors.current,
                  DashboardStat: 'CONNECTED',
                });
              }
            })
          })
          .catch((e) => {
            console.log('Connection failed: ', e);
          })
      }
    }, [connection])
  
    useEffect(() => {
      axios({
        method: 'get',
        url: process.env.REACT_APP_BACKEND_API + '/api/sensor-monitor/layout?layout=' + layout.id,
        headers: {},
      }).then((response) => {
  
        setLayout({
          ...layout,
          layoutName: response.data.result.layoutName,
          imageData: "data:image/png;base64," + response.data.result.imageData,
          isZoom: response.data.result.isZoom
        })
  
        setState({
          ...state,
          SensorList: response.data.result.devices
        })
  
        setTimeout(() => {
          const newConnection = new HubConnectionBuilder()
            .withUrl(hubService + '/Automation/Hub')
            .withAutomaticReconnect()
            .build();
          setConnection(newConnection);
  
        }, 100);
        sensors.current = response.data.result.devices
      })
  
    }, []);
  
    let bodyCss = {
    overflowX: "hidden" 
    }
  
    let backcss = {
      top: 0, left: 0, right: 0,
      bottom: 0, margin: "auto", width: "1200px"
    }
  
    let sensorcss = {
      position: "absolute", width: "1200px"
    }
  
    if (!layout.isZoom) {
      backcss = {
        top: 0, left: 0, right: 0,
        bottom: 0, margin: "auto"
      }
  
      sensorcss = {
        position: "absolute"
      }
  
      bodyCss = {
        
      }
    }

  const data = [
    {
      name: 'Page A',
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: 'Page B',
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: 'Page C',
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: 'Page D',
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: 'Page E',
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: 'Page F',
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: 'Page G',
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];

  return (
    <PageDataProvider >
  <img width={"500px"} src="a2.gif"></img>
     
    </PageDataProvider>
  );
  

}

export default Display;
//https://codesandbox.io/s/k2ds4?file=/src/App.js:128-4341
