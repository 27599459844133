import React, {useState, useEffect, useRef, PureComponent, useMemo} from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import {Line} from 'react-chartjs-2'
import parse from 'html-react-parser'
import axios from 'axios'
import {stat} from 'fs'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)

function Chartj(props) {
  // console.log(JSON.stringify(props?.item?.datasets))
  const labels = []
  const chartReference = React.createRef()
  const [state, setState] = useState({
     config: props?.item?.config ?? {
      label: props?.item?.config?.label ?? 'TimeStamp',
      data: props?.item?.config?.data ?? 'C_value',
      url: props?.item?.config?.url ?? '/api/sensor/getall',
      refersh: props?.item?.config?.refersh ?? 5000,
      sort: props?.item?.config?.sort ?? 'TimeStamp: -1',
      find: props?.item?.config?.find ?? 'TimeStamp : today',
      project: props?.item?.config?.project ?? 'TimeStamp: 1, C_value: 1',
    },
    name: 'React',
    option: props?.item?.option ?? {
      responsive: props?.item?.responsive ?? true,
      plugins: props?.item?.plugins ?? {
        legend: {
          position: 'top',
        },
        title: props?.item?.title ?? {
          display: true,
          text: 'chart name',
        },
        elements: {line: {tension: 0.4}},
      },
      y: props?.item?.y ?? {
        min: props?.item?.y?.min ?? -30,
        max: props?.item?.y?.max ?? 10,
        ticks: props?.item?.y?.ticks ?? {
          min: 0,
          max: 150,
          stepSize: 20,
        },
      },
      x: props?.item?.y ?? {},
    },
    data: props?.item?.data ?? {
      labels,
      datasets: props?.item?.datasets ?? [
        {
          label: '',
          data: [],
          tension: 0.4,
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
      ],
    },
  })

  useEffect(() => {
    RefreshData()
    setInterval(() => {
      RefreshData()
    }, state.config?.refersh)
  }, [])

  function RefreshData() {
    var datafield = {}
    datafield['sort'] = state.config.sort
    datafield['find'] = state.config.find
    datafield['project'] = state.config.project
    axios({
      method: 'post',
      url: process.env.REACT_APP_BACKEND_API + state.config.url,
      headers: {},
      data: datafield,
    }).then((response) => {
      if (response.data.status == 'S') {
        const labelsx = []
        const datax = []
        for (let index = 0; index < response.data.result.length; index++) {
          const element = response.data.result[index]
          labelsx.push(element[state.config.label])
          datax.push(element[state.config.data])
        }
        const chart = chartReference.current
        chart.data.labels = labelsx
        state.data.datasets[0].data = datax
        chart.data.datasets[0] = state.data.datasets[0]
        chart.update('none')
      }
    })
  }

  return (
    <div
      style={{fontFamily: 'Roboto Mono, monospace', zIndex: 10, position: 'absolute', width: 1000}}
    >
      <>
        {state.option.plugins.title.text}
        <Line height={80} option={state.option} ref={chartReference} data={state.data} />
      </>
    </div>
  )
}

export default Chartj
//https://codesandbox.io/s/k2ds4?file=/src/App.js:128-4341
