import React, {Component} from 'react'
import PropTypes from 'prop-types'
import shouldPureComponentUpdate from 'react-pure-render/function'
import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'
import {greatPlaceStyle} from './my_great_place_styles.js'

export default class MyGreatPlace extends Component {
  static propTypes = {
    text: PropTypes.string,
    isNotify: PropTypes.bool,
    Link: PropTypes.string,
  }

  static defaultProps = {}

  shouldComponentUpdate = shouldPureComponentUpdate

  render() {
    if (this.props.Type == 'Car') {
      return (
        <>
          <Popup
            trigger={
              <div>
                <img
                  style={{position: 'absolute', left: -18, top: -10}}
                  //  onClick={() => alert('')}
                  width={'50px'}
                  src='https://cdn4.iconfinder.com/data/icons/VISTA/accounting/png/256/truck.png'
                />
              </div>
            }
            position='right center'
          >
            <div>
              temp : <b> {this.props.Value}°C</b> <br />
              <a target={'_blank'} href={this.props.Link}>
                View More Details
              </a>
            </div>
          </Popup>
        </>
      )
    } else {
      if (!this.props.isNotify) {
        return (
          <>
            <Popup
              trigger={<div style={greatPlaceStyle}>{this.props.text}</div>}
              position='right center'
            >
              <div>
                <a target={'_blank'} href={this.props.Link}>
                  View More Details
                </a>
              </div>
            </Popup>
          </>
        )
      } else {
        return (
          <>
            <Popup
              trigger={
                <div style={greatPlaceStyle}>
                  {this.props.text}{' '}
                  <img
                    style={{position: 'absolute', left: -18, top: -10}}
                    //  onClick={() => alert('')}
                    width={'50px'}
                    src='https://media.tenor.com/3mZe43RbInsAAAAi/alert-blink.gif'
                  />
                </div>
              }
              position='right center'
            >
              <div>
                <a target={'_blank'} href={this.props.Link}>
                  View More Details
                </a>
              </div>
            </Popup>
          </>
        )
      }
    }
  }
}

const PopupExample = () => (
  <Popup trigger={<button> Trigger</button>} position='right center'>
    <div>Popup content here !!</div>
  </Popup>
)
