import DisplayGroup from '../config/DisplayGroup'
import Display from '../config/display'
import React, {useState, useEffect, useRef} from 'react'
import './../../../../_metronic/assets/sass/kntdashbaord.css'
import axios from 'axios'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
const urlParams = new URLSearchParams(window.location.search)

function ViewAll(props) {
  const [InitialLoading, setInitialLoading] = useState(true)
  const [state, setstate] = useState([])
  const [packages, setPackages] = useState([
    'Boiler.Weight',
    'Boiler.Weight',
    'Boiler.Weight',
    'Boiler.Weight',
    'Boiler.Weight',
  ])

  useEffect(() => {
    axios({
      method: 'get',
      url:
        process.env.REACT_APP_BACKEND_API +
        '/api/sensor-monitor/' +
        props.match?.params?.id +
        '/layouts',
      headers: {},
    }).then((response) => {
      setstate(response.data)
      setInitialLoading(false)
    })
  }, [])

  if (InitialLoading) {
    return (
      <>
        <div
          className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
          style={{
            backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.png')})`,
          }}
        >
          {/* begin::Content */}
          <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
            {/* begin::Logo */}
            <h1>Elysium Automation</h1>

            {/* end::Logo */}
            {/* begin::Wrapper */}
            <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
              <div>
                <span
                  className='indicator-progress'
                  style={{display: 'block', textAlign: 'center'}}
                >
                  <>
                    {' '}
                    {state.DashboardStat}{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </>
                  <br />
                </span>
              </div>
            </div>
            {/* end::Wrapper */}
          </div>
          {/* end::Content */}
          {/* begin::Footer */}
          <div className='d-flex flex-center flex-column-auto p-10'>
            <div className='d-flex align-items-center fw-bold fs-6'>
              <a href='#' className='text-muted text-hover-primary px-2'>
                {new Date().getFullYear()} &copy; Overleap
              </a>
            </div>
          </div>
          {/* end::Footer */}
        </div>
      </>
    )
  }

  if (
    urlParams.get('filter') !== null &&
    urlParams.get('filter') !== undefined &&
    urlParams.get('filter') !== ''
  ) {
    return (
      <>
        <div
          style={{marginTop: '0px', marginLeft: '50px', fontSize: '11px'}}
          className='text-dark '
        >
          <h1>{state.layoutName} by Elysium</h1>
          <hr />
        </div>
        <div style={{marginTop: '-25px'}} className='row'>
          <div
            className='col-sm-3'
            style={{width: state.width + 'px', height: state.height + 'px'}}
          >
            <DisplayGroup layout={state.code} object={urlParams.get('filter')} />
          </div>
        </div>
      </>
    )
  }

  if (state.type === 'P') {
    return (
      <>
        <div
          style={{marginTop: '0px', marginLeft: '50px', fontSize: '11px'}}
          className='text-dark '
        >
          <h1>{state.layoutName} by Elysium</h1>
          {/*  <span className='text-muted fw-bold me-2'>{new Date().getFullYear()} &copy;</span>
            <a href='#' className='text-gray-800 text-hover-primary'>
              overleap | Elysium  
            </a>*/}
          <hr />
        </div>
        <div style={{marginTop: '-25px'}} className='row'>
          {state.packets.map((item, index) => {
            if (
              urlParams.get('filter') !== null &&
              urlParams.get('filter') !== undefined &&
              urlParams.get('filter') !== ''
            ) {
              if (
                urlParams.get('filter').toLocaleLowerCase() === item.toString().toLocaleLowerCase()
              ) {
                return (
                  <div
                    className='col-sm-3'
                    style={{width: state.width + 'px', height: state.height + 'px'}}
                  >
                    <DisplayGroup layout={state.code} object={item} />
                  </div>
                )
              }
            } else {
              return (
                <div
                  className='col-sm-3'
                  style={{width: state.width + 'px', height: state.height + 'px'}}
                >
                  <DisplayGroup layout={state.code} object={item} />
                </div>
              )
            }
          })}
        </div>
      </>
    )
  }

  return (
    <>
      <div className='row'>
        {state.layouts.map((item, index) => {
          return (
            <div className='col-lg-6'>
              <Display layout={item} />
            </div>
          )
        })}
      </div>
    </>
  )
}

export default ViewAll
