/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { Registration } from './components/Registration'
import { ForgotPassword } from './components/ForgotPassword'
import { Login } from './components/Login'
import { toAbsoluteUrl } from '../../../_metronic/helpers'


export function AuthPage() {
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  const [backgroundImage, setBackgroundImage] = useState('');

  useEffect(() => {
    // Array of background image URLs
    var backgroundImages = [

      toAbsoluteUrl('/media/bg/industryx/1.jpg'),
      toAbsoluteUrl('/media/bg/industryx/2.jpg'),
      toAbsoluteUrl('/media/bg/industryx/3.jpg'),
      toAbsoluteUrl('/media/bg/industryx/4.jpg'),
      toAbsoluteUrl('/media/bg/industryx/5.jpg'),
      toAbsoluteUrl('/media/bg/industryx/6.jpg'),

    ];

    var content = 'INDUSRTYX'
    if (window.location.hostname.toLowerCase() === 'e2eq.lk') {
      backgroundImages = [

        toAbsoluteUrl('/media/bg/e2eq/1.jpg'),
        toAbsoluteUrl('/media/bg/e2eq/2.jpg'),
        toAbsoluteUrl('/media/bg/e2eq/3.jpg'),
        toAbsoluteUrl('/media/bg/e2eq/4.jpg'),
        toAbsoluteUrl('/media/bg/e2eq/5.jpg'),
        toAbsoluteUrl('/media/bg/e2eq/6.jpg'),

      ];
    }
    if (window.location.hostname.toLowerCase() === 'portal.smartgas.biz') {
      backgroundImages = [

        toAbsoluteUrl('/media/bg/smartgas/1.jpg'),
        toAbsoluteUrl('/media/bg/smartgas/2.jpg'),
        toAbsoluteUrl('/media/bg/smartgas/3.jpg'),
        toAbsoluteUrl('/media/bg/smartgas/4.jpg'),
        toAbsoluteUrl('/media/bg/smartgas/5.jpg'),
        toAbsoluteUrl('/media/bg/smartgas/6.jpg'),

      ];
    }
    // Generate a random index to select a background image from the array
    const randomIndex = Math.floor(Math.random() * backgroundImages.length);

    // Set the selected background image URL
    setBackgroundImage(backgroundImages[randomIndex]);
  }, []);

  return (

    <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100'>
      {/* begin::Body */}
      <div className='d-flex flex-column flex-lg-row-fluid w-lg-40 p-10 order-2 order-lg-1' style={{ overflow: 'auto' }}>
        {/* begin::Form */}
        <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
          {/* begin::Wrapper */}
          <div className='w-lg-500px p-10'>
            <Switch>
              <Route path='/auth/login' component={Login} />
              <Route path='/auth/registration' component={Registration} />
              <Route path='/auth/forgot-password' component={ForgotPassword} />
              <Redirect from='/auth' exact={true} to='/auth/login' />
              <Redirect to='/auth/login' />
            </Switch>
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Form */}

        {/* begin::Footer */}
        <div className='d-flex flex-center flex-wrap px-5'>
          {/* begin::Links */}
          <div className='d-flex fw-semibold text-primary fs-base'>


            <div style={{ fontSize: "12px" }} className='text-gray-500'>
              {new Date().getFullYear()} &copy; <a href='https://industryx.io/home-2' className='text-gray-800 text-hover-primary'>
                <img width={80} src="https://industryx.io/wp-content/uploads/2024/08/logo-2.png" />
              </a>
            </div>

          </div>
          {/* end::Links */}
        </div>
        {/* end::Footer */}
      </div>
      {/* end::Body */}

      {/* begin::Aside */}
      <div
        className='d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2'
        // style={{ backgroundImage: `url(${toAbsoluteUrl('/media/bg/bg.jpg')})` }}

        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        {/* begin::Content */}

        {/* end::Content */}
      </div>
      {/* end::Aside */}
    </div>
  )
}

