import React, {useState, useEffect, useRef} from 'react'
import './../../../../_metronic/assets/sass/kntdashbaord.css'
import {HubConnectionBuilder, JsonHubProtocol} from '@microsoft/signalr'
import axios from 'axios'
import useWindowDimensions from '../../helpers/WindowDimension'
import Dictionary from '../../helpers/Dictionary'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Dropdown1} from '../../../../_metronic/partials'
import {AsideDefault} from '../../../../_metronic/layout/components/aside/AsideDefault'
import {Footer} from '../../../../_metronic/layout/components/Footer'
import {HeaderWrapper} from '../../../../_metronic/layout/components/header/HeaderWrapper'
import {PageDataProvider} from '../../../../_metronic/layout/core'
import {Content} from '../../../../_metronic/layout/components/Content'
import {
  MixedWidget1,
  MixedWidget20_1,
  MixedWidget20_2,
  MixedWidget20_3,
  MixedWidget7,
} from '../../../../_metronic/partials/widgets'
import {stat} from 'fs'
import Confetti from 'react-confetti'
import ImageMapper from 'react-image-mapper'
import Draggable from 'react-draggable'
import {v4 as uuidv4} from 'uuid'
import Popup from 'reactjs-popup'
import SensorArea from './SensorArea'
import 'reactjs-popup/dist/index.css'
import {auto, hide, right} from '@popperjs/core'
import {ToastContainer, toast, Slide, Zoom, Flip, Bounce} from 'react-toastify'

import {FabricJSCanvas, useFabricJSEditor} from 'fabricjs-react'
import {fabric} from 'fabric'

import 'react-toastify/dist/ReactToastify.css'
var randomColor = require('randomcolor')

function FactoryDashboard(props) {
  const [connection, setConnection] = useState(null)
  const reconnectRef = useRef(0)
  const urlParams = new URLSearchParams(window.location.search)
  const [isloading, setLoading] = useState(true)
  const [item, setItem] = useState('')
  const [SensorList, setsensorList] = useState([])
  const sensors = useRef({})
  const {selectedObjects, setSelectedObject, editor, onReady} = useFabricJSEditor({
    defaultStrokeColor: 'red',
    selection: false,
  })
  const [text, setText] = useState('')
  const [strokeColor, setStrokeColor] = useState('')
  const [fillColor, setFillColor] = useState('')
  const [isload, setisload] = useState(true)

  const [layout, setLayout] = useState({
    id: urlParams.get('id') ?? props.layout,
    layoutName: '',
    imageData: null,
    devices: [],
    isZoom: false,
    globalJs: '',
    designObject: [],
  })
  const [state, setState] = useState({
    Data: {},
    DashboardStat: 'CONNECTING',
    SensorList: [],
  })

  const hubService = process.env.REACT_APP_MIDAS_HUB //"https://signalr.midassit.com";

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then((result) => {
          setState({
            ...state,
            DashboardStat: 'CONNECTED',
          })
          setLoading(false)
          reconnectRef.current = 0
          connection.onreconnecting((error) => {
            setState({
              ...state,
              DashboardStat: 'RECONNECTING',
            })
            console.log('Connection lost due to error . Reconnecting.')
          })

          // Reconnect if hub disconnects
          connection.onclose(function (e) {
            setState({
              ...state,
              DashboardStat: 'DISCONNECTED',
            })
            //console.log('Disconnected');
          })

          connection.on('ReceiveMessage', (message) => {
            if (typeof message === 'string' || message instanceof String) {
              message = JSON.parse(message)
            }

            if (message.key !== 'SensorData') return

            let dataPacket = JSON.parse(message.data)

            if (
              dataPacket.TimeStamp === undefined ||
              Math.round((new Date() - new Date(dataPacket.TimeStamp)) / 1000) > 10
            ) {
              return
            } else {
              layout.id = layout.id.replace(/\s\s+/g, ' ')
              layout.id = layout.id.replace('-', '')
              layout.id = layout.id.replace(' ', '_')

              if (!(dataPacket.Plant === layout.id && dataPacket.Packet === 'Dashboard')) {
                return
              }

              sensors.current = dataPacket
              console.log(message)
              setState({
                ...state,
                DashboardStat: 'CONNECTED',
              })
            }
          })
        })
        .catch((e) => {
          // console.log('Connection failed: ', e)
        })
    }
  }, [connection])

  useEffect(() => {
    const newConnection = new HubConnectionBuilder()
      .withUrl(hubService + '/Automation/Hub')
      .withAutomaticReconnect()
      .build()
    setConnection(newConnection)
  }, [])

  if (editor != undefined && isload && layout.designObject.length > 0) {
    var desginObject = {
      version: '4.3.1',
      objects: [],
    }
    for (let index = 0; index < layout.designObject.length; index++) {
      const element = layout.designObject[index]
      var ds = JSON.parse(element.tempFeild1)
      //Movement:
      ds.lockMovementX = true
      ds.lockMovementY = true

      //Scaling:
      ds.lockScalingX = true
      ds.lockScalingY = true

      // Rotation:
      ds.lockRotation = true

      // Object's frame/bounidngBox:
      ds.hasControls = false
      ds.hasBorders = false
      desginObject.objects.push(ds)
    }
    editor?.canvas.loadFromJSON(desginObject)

    setisload(false)
  }

  let bodyCss = {
    overflowX: 'hidden',
  }

  function hideAllNotification() {
    toast.dismissAll()
  }

  function hideNotification(notification) {
    toast.dismiss(notification.toastId)
  }

  function getTransactionType(transition) {
    switch (transition.toLowerCase()) {
      case 'flip':
        return Flip
      case 'slide':
        return Slide
      case 'zoom':
        return Zoom

      default:
        return Bounce
    }
  }
  function getType(type) {
    switch (type.toLowerCase()) {
      case 'info':
        return toast.TYPE.INFO
      case 'error':
        return toast.TYPE.ERROR
      case 'success':
        return toast.TYPE.SUCCESS
      case 'warning':
        return toast.TYPE.WARNING
      default:
        return toast.TYPE.INFO
    }
  }

  function showNotification(notification) {
    notification.transition = getTransactionType(notification.transition)
    if (toast.isActive(notification.toastId)) {
      notification.render = notification.message
      notification.type = getType(notification.type)
      toast.update(notification.toastId, notification)
      return
    }

    switch (notification.type) {
      case 'error':
        toast.error(notification.message, notification)
        break
      case 'info':
        toast.info(notification.message, notification)
        break
      case 'warning':
        toast.warn(notification.message, notification)
        break
      case 'success':
        toast.success(notification.message, notification)
        break
      default:
        toast(notification.message)
        break
    }
  }

  function Notification(notification) {
    if (toast.isActive(notification.toastId)) {
      toast.dismiss(notification.toastId)
    }
    switch (notification.Type) {
      case 'error':
        toast.error(notification.Message, {
          toastId: notification?.toastId ?? 'default',
          position: notification?.Position ?? 'top-right',
          autoClose: notification?.Autoclose ?? 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
        break

      case 'info':
        toast.info(notification.Message, {
          position: notification?.Position ?? 'top-right',
          autoClose: notification?.Autoclose ?? 5000,
          toastId: notification?.toastId ?? 'default',
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
        break
      case 'warning':
        toast.warn(notification.Message, {
          position: notification?.Position ?? 'top-right',
          autoClose: notification?.Autoclose ?? 5000,
          toastId: notification.toastId ?? 'default',
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
        break
      case 'success':
        toast.success(notification.Message, {
          position: notification?.Position ?? 'top-right',
          autoClose: notification?.Autoclose ?? 5000,
          toastId: notification?.toastId ?? 'default',
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
        break
      default:
        toast('Wow so easy!')
        break
    }
  }

  let backcss = {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 'auto',
    width: '1200px',
  }

  let sensorcss = {
    position: 'absolute',
    width: '1200px',
  }

  if (!layout.isZoom) {
    backcss = {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      margin: 'auto',
    }

    sensorcss = {
      position: 'absolute',
    }

    bodyCss = {}
  }

  if (isloading) {
    return (
      <>
        <div
          className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
          style={{
            backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.png')})`,
          }}
        >
          {/* begin::Content */}
          <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
            {/* begin::Logo */}
            <h1>Elysium Automation</h1>

            {/* end::Logo */}
            {/* begin::Wrapper */}
            <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
              <div>
                <span
                  className='indicator-progress'
                  style={{display: 'block', textAlign: 'center'}}
                >
                  <>
                    {' '}
                    {state.DashboardStat}{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </>
                  <br />
                </span>
              </div>
            </div>
            {/* end::Wrapper */}
          </div>
          {/* end::Content */}
          {/* begin::Footer */}
          <div className='d-flex flex-center flex-column-auto p-10'>
            <div className='d-flex align-items-center fw-bold fs-6'>
              <a href='#' className='text-muted text-hover-primary px-2'>
                {new Date().getFullYear()} &copy;{' '}
                <a href='https://industryx.io/home-2' className='text-gray-800 text-hover-primary'>
                  <img
                    width={80}
                    src='https://industryx.io/wp-content/uploads/2024/08/logo-2.png'
                  />
                </a>
              </a>
            </div>
          </div>
          {/* end::Footer */}
        </div>
      </>
    )
  }

  return (
    <PageDataProvider>
      <div className='page d-flex flex-row flex-column-fluid'>
        <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
          <div
            id='kt_content'
            style={{marginTop: '-170px'}}
            className='content d-flex flex-column flex-column-fluid'
          >
            <div className='post d-flex flex-column-fluid' id='kt_post'>
              <Content>
                <ToastContainer />
                <h1>
                  {layout.layoutName} <br />
                  <small style={{fontSize: '15px'}}>IndustryX</small>
                  <a href='#' className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'></a>
                  {state.DashboardStat === 'DISABLED' && (
                    <a
                      href='#'
                      className='btn btn-sm btn-light-dark fw-bolder ms-2 fs-8 py-1 px-3'
                      data-bs-toggle='modal'
                      data-bs-target='#kt_modal_upgrade_plan'
                    >
                      {state.DashboardStat}
                    </a>
                  )}
                  {state.DashboardStat === 'ACTIVE' && (
                    <a
                      href='#'
                      className='btn btn-sm btn-light-success fw-bolder ms-2 fs-8 py-1 px-3'
                      data-bs-toggle='modal'
                      data-bs-target='#kt_modal_upgrade_plan'
                    >
                      {state.DashboardStat}
                    </a>
                  )}
                  {state.DashboardStat === 'CONNECTED' && (
                    <a
                      href='#'
                      className='btn btn-sm btn-light-info fw-bolder ms-2 fs-8 py-1 px-3'
                      data-bs-toggle='modal'
                      data-bs-target='#kt_modal_upgrade_plan'
                    >
                      {state.DashboardStat}
                    </a>
                  )}
                  {state.DashboardStat === 'CONNECTING' && (
                    <a
                      href='#'
                      className='btn btn-sm btn-light-warning fw-bolder ms-2 fs-8 py-1 px-3'
                      data-bs-toggle='modal'
                      data-bs-target='#kt_modal_upgrade_plan'
                    >
                      {state.DashboardStat}
                    </a>
                  )}
                </h1>
                <div>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bolder fs-3 mb-1'>
                      {layout.id.replace(/_/g, ' ')} Overview
                    </span>
                  </h3>
                </div>
                <div className='row g-5 g-xl-8'>
                  {/* begin::Col */}
                  <div className='col-xl-4'>
                    <MixedWidget1
                      factoryName='Cool Room(s)'
                      critical={sensors.current?.COLD_Critical}
                      warning={sensors.current?.COLD_Warning}
                      alert={sensors.current?.COLD_Alert}
                      alldevice={sensors.current?.COLD_AllConnect}
                      connected={sensors.current?.COLD_Connect}
                      link={layout.id + '_Cool_Room'}
                      className='card-xl-stretch mb-xl-8'
                      color={sensors.current?.COLD_Critical > 0 ? 'danger' : 'success'}
                    />
                  </div>
                  {/* end::Col */}

                  {/* begin::Col */}
                  <div className='col-xl-4'>
                    <MixedWidget1
                      factoryName='Primary Transportation'
                      critical={sensors.current?.PD_Critical}
                      warning={sensors.current?.PD_Warning}
                      alert={sensors.current?.PD_Alert}
                      alldevice={sensors.current?.PD_AllConnect}
                      connected={sensors.current?.PD_Connect}
                      link={layout.id + '_Primary_Distribution'}
                      className='card-xl-stretch mb-xl-8'
                      color={sensors.current?.PD_Critical > 0 ? 'danger' : 'success'}
                    />
                  </div>
                  {/* end::Col */}

                  {/* begin::Col */}
                  <div className='col-xl-4'>
                    <MixedWidget1
                      factoryName='Distributors'
                      critical={sensors.current?.D_Critical}
                      warning={sensors.current?.D_Warning}
                      alert={sensors.current?.D_Alert}
                      alldevice={sensors.current?.DAllConnect}
                      connected={sensors.current?.D_Connect}
                      link='Cold Chain KPF - JaEla'
                      className='card-xl-stretch mb-xl-8'
                      color={sensors.current?.D_Critical > 0 ? 'danger' : 'success'}
                    />
                  </div>
                  {/* end::Col */}
                </div>
                {/* end::Row */}

                {/* begin::Row */}
                <div className='row g-5 g-xl-8'>
                  {/* begin::Col */}
                  <div className='col-xl-4'>
                    <MixedWidget1
                      factoryName='Secondary Distribution'
                      critical={sensors.current?.SD_Critical}
                      warning={sensors.current?.SD_Warning}
                      alert={sensors.current?.SD_Alert}
                      alldevice={sensors.current?.SD_AllConnect}
                      connected={sensors.current?.SD_Connect}
                      link='Cold Chain KPF - Pannala'
                      className='card-xl-stretch mb-xl-8'
                      color={sensors.current?.SD_Critical > 0 ? 'danger' : 'success'}
                    />
                  </div>
                  <div className='col-xl-4'>
                    <MixedWidget7
                      className='card-xl-stretch mb-xl-8'
                      chartColor='danger'
                      chartHeight='200px'
                    />
                  </div>
                </div>
              </Content>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </PageDataProvider>
  )
}

export default FactoryDashboard
