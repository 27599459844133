import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import React, {useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import axios from 'axios'
import {Formik, Form, Field} from 'formik'
import * as Yup from 'yup'
import ErrorMessage from '../helpers/message'
import Loading from '../helpers/loading'
import {axioDefaultConfig, options, phoneRegex, validcoderegex} from '../helpers/commonResource'
import {date} from 'yup/lib/locale'
import SensorIndex from '../sensor/config/index'
import {useDataLayerValue} from '../../../DataLayer'

const zoomCategory = [
  {label: 'Zoom', value: true},
  {label: '-', value: false},
]

const breadCrumb = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'All Alerts',
    path: '/alerts/open',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

function Alert(props) {
  const [InitialLoading, setInitialLoading] = useState(true)
  const [error, setError] = useState({status: 'E', text: ''})
  const [isSubmitting, setSubmitting] = useState(false)
  const [code, setCode] = useState(props.match?.params?.id ?? '')
  const [copy, setCopy] = useState('')
  const [copyMessage, setCopyMessage] = useState('')
  const [copyMessageStatus, setCopyMessageStatus] = useState('')
  const [{user, token}, dispatch] = useDataLayerValue()
  const [orginalPacket, setOrginalPacket] = useState('')
  const [clonePacket, setClonePacket] = useState('')
  const [notification, setNotification] = useState([])
  const [job, setJobs] = useState([])
  const [facility, setFacility] = useState([])
  //const [{token}, dispatch] = useDataLayerValue()

  const [state, setState] = useState({
    code: props.match?.params?.id ?? '',
    comment: '',
  })

  const validationRules = Yup.object().shape({
    comment: Yup.string().required('Required'),
  })

  useEffect(() => {
    const id = props.match?.params?.id ?? ''
    const sector = props.match?.params?.sector ?? ''
    if (id === '') {
      setInitialLoading(false)
      return
    }

    const getNotificationType = axios({
      ...axioDefaultConfig,
      method: 'get',
      url: `${process.env.REACT_APP_BACKEND_API}/api/get-notification-type?sector=${sector}`,
      headers: {...axioDefaultConfig.headers, Authorization: 'Bearer ' + token},
    })

    const getSheduleJobs = axios({
      ...axioDefaultConfig,
      method: 'get',
      url: `${process.env.REACT_APP_BACKEND_API}/api/get-notification-shedulejob?sector=${sector}`,
      headers: {...axioDefaultConfig.headers, Authorization: 'Bearer ' + token},
    })
    const getfacility = axios({
      ...axioDefaultConfig,
      method: 'get',
      url: `${process.env.REACT_APP_BACKEND_API}/api/get-notification-facility?sector=${sector}`,
      headers: {...axioDefaultConfig.headers, Authorization: 'Bearer ' + token},
    })

    const getFormDetails = axios({
      ...axioDefaultConfig,
      method: 'get',
      url: process.env.REACT_APP_BACKEND_API + '/api/alerts/' + id,
      headers: {...axioDefaultConfig.headers, Authorization: 'Bearer ' + token},
    })

    axios
      .all([getFormDetails, getNotificationType, getSheduleJobs, getfacility])
      .then(
        axios.spread((...responses) => {
          console.log(responses[0])
          const getFormDetailsResponse = responses[0]
          const getnotifications = responses[1]
          const getJobs = responses[2]
          const getfacility = responses[3]

          setNotification(getnotifications?.data)
          setJobs(getJobs?.data)
          setFacility(getfacility.data)
          setState(getFormDetailsResponse?.data.data)
          setInitialLoading(false)
        })
      )
      .catch(function (error) {
        handleErros(error)
      })
  }, [])

  if (InitialLoading) return <Loading />

  function handleErros(error) {
    if (error?.response?.status === 401) {
      dispatch({
        type: 'SET_TOKEN',
        token: null,
      })
      return
    }

    setSubmitting(false)
    if (error?.response?.data?.status === 'E') {
      setError(error?.response?.data)
      return
    }
    const message = Object.entries(error?.response?.data?.errors)
      .map(([key, value]) => value)
      .join(',')
    setError({status: 'E', text: message})
  }

  function handleChange2(e) {
    setCopy(e.target.value)
  }

  function handleChangeOrginalPacket(e) {
    setOrginalPacket(e.target.value)
  }

  function handleChangeClonePacket(e) {
    setClonePacket(e.target.value)
  }

  if (isSubmitting) return <Loading />

  return (
    <>
      <Formik
        initialValues={state}
        validationSchema={validationRules}
        onSubmit={(values, {}) => {
          values.status = 'Closed'
          setSubmitting(true)
          setTimeout(() => {
            let config = axioDefaultConfig
            config = {
              ...axioDefaultConfig,
              method: 'post',
              url: process.env.REACT_APP_BACKEND_API + '/api/update-alertx',
              data: values,
              headers: {
                ...axioDefaultConfig.headers,
                Authorization: 'Bearer ' + token,
              },
            }
            axios(config)
              .then(function (response) {
                const getFormDetails = axios({
                  ...axioDefaultConfig,
                  method: 'get',
                  url: process.env.REACT_APP_BACKEND_API + '/api/alerts/' + state.code,
                  headers: {...axioDefaultConfig.headers, Authorization: 'Bearer ' + token},
                })
                axios
                  .all([getFormDetails])
                  .then(
                    axios.spread((...responses) => {
                      const getFormDetailsResponse = responses[0]
                      setState(getFormDetailsResponse?.data.data)
                      setSubmitting(false)
                    })
                  )
                  .catch(function (error) {
                    handleErros(error)
                  })
              })
              .catch(function (error) {
                handleErros(error)
              })
          }, 200)
        }}
      >
        {({values, errors, touched, handleChange, handleBlur, handleSubmit}) => (
          <Form onSubmit={handleSubmit}>
            <PageTitle breadcrumbs={breadCrumb}>Alert!!</PageTitle>
            <div className='row gy-5 g-xl-12'>
              <div className='card mb-5 mb-xl-10'>
                <div
                  className='card-header border-0 cursor-pointer'
                  role='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#kt_account_profile_details'
                  aria-expanded='true'
                  aria-controls='kt_account_profile_details'
                >
                  <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Alert Information</h3>
                  </div>
                </div>

                <div id='kt_account_profile_details' className='collapse show'>
                  <div className='form'>
                    <ErrorMessage error={error} />
                    <div className='card-body border-top p-9'>
                      <div className='row mb-6'>
                        <label className='col-lg-4 col-form-label  fw-bold fs-6'>Alert Code</label>

                        <div className='col-lg-8'>
                          <div className='row'>
                            <div className='col-lg-12 fv-row'>
                              <input
                                type='text'
                                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                name='formatCode'
                                value={values.formatCode}
                                disabled={code !== '' ? true : false}
                              />
                              {/*    <span style={{color: 'gray', fontStyle: 'italic'}}>
                                Code does not have space or special characters,all characters should
                                be LowerCase
                              </span>
                              */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='row mb-6'>
                        <label className='col-lg-4 col-form-label fw-bold fs-6'>Status</label>

                        <div className='col-lg-4'>
                          <div className='row'>
                            <div className='col-lg-12 fv-row'>
                              <input
                                type='text'
                                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                name='status'
                                value={values.status}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='col-lg-4'>
                          <div className='row'>
                            <div className='col-lg-12 fv-row'>
                              <input
                                type='text'
                                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                name='priority'
                                value={values.priority}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='row mb-6'>
                        <label className='col-lg-4 col-form-label  fw-bold fs-6'>Facility</label>

                        <div className='col-lg-8'>
                          <div className='row'>
                            <div className='col-lg-12 fv-row'>
                              <select
                                className='form-select form-select-solid form-select-lg mb-3 mb-lg-0'
                                name='fK_Layout'
                                value={values.fK_Layout}
                              >
                                <option value=''>Facility</option>
                                {facility.map((d, key) => (
                                  <option value={d['code']}>{d['name']}</option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='row mb-6'>
                        <label className='col-lg-4 col-form-label  fw-bold fs-6'>About Alert</label>

                        <div className='col-lg-8'>
                          <div className='row'>
                            <div className='col-lg-12 fv-row'>
                              <textarea
                                type='text'
                                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                name='description'
                                value={values.description}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='row mb-6'>
                        <label className='col-lg-4 col-form-label  fw-bold fs-6'>
                          Notification Type
                        </label>

                        <div className='col-lg-8'>
                          <div className='row'>
                            <div className='col-lg-12 fv-row'>
                              <select
                                className='form-select form-select-solid form-select-lg mb-3 mb-lg-0'
                                name='fK_NotificationCode'
                                value={values.fK_NotificationCode}
                              >
                                <option value=''>Notifications</option>
                                {notification.map((d, key) => (
                                  <option value={d['code']}>{d['name']}</option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='row mb-6'>
                        <label className='col-lg-4 col-form-label  fw-bold fs-6'>
                          Trigger/Shedule
                        </label>

                        <div className='col-lg-8'>
                          <div className='row'>
                            <div className='col-lg-12 fv-row'>
                              <select
                                className='form-select form-select-solid form-select-lg mb-3 mb-lg-0'
                                name='fK_SheduleJobCode'
                                value={values.fK_SheduleJobCode}
                              >
                                <option value=''>Notifications</option>
                                {job.map((d, key) => (
                                  <option value={d['code']}>{d['name']}</option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='row mb-6'>
                        <label className='col-lg-4 col-form-label fw-bold fs-6'>
                          Created By & DateTime
                        </label>

                        <div className='col-lg-4'>
                          <div className='row'>
                            <div className='col-lg-12 fv-row'>
                              <input
                                type='text'
                                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                name='createdBy'
                                value={values.createdBy}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='col-lg-4'>
                          <div className='row'>
                            <div className='col-lg-12 fv-row'>
                              <input
                                type='text'
                                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                name='createdOn'
                                value={values.createdOn}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='row mb-6'>
                        <label className='col-lg-4 col-form-label fw-bold fs-6'>
                          Auto Resolved By & DateTime
                        </label>

                        <div className='col-lg-4'>
                          <div className='row'>
                            <div className='col-lg-12 fv-row'>
                              <input
                                type='text'
                                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                name='resolvedBy'
                                value={values.resolvedBy}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='col-lg-4'>
                          <div className='row'>
                            <div className='col-lg-12 fv-row'>
                              <input
                                type='text'
                                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                name='resolvedOn'
                                value={values.resolvedOn}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='row mb-6'>
                        <label className='col-lg-4 col-form-label  fw-bold fs-6'>Remark</label>

                        <div className='col-lg-8'>
                          <div className='row'>
                            <div className='col-lg-12 fv-row'>
                              <textarea
                                rows='8'
                                cols='50'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.comment}
                                name='comment'
                                className='form-control  form-control-solid '
                                placeholder='Remark'
                                type='text'
                              />
                              {errors.comment && touched.comment ? (
                                <div className='text-danger fs-7 fw-bolder'>{errors.comment}</div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='row mb-6'>
                        <label className='col-lg-4 col-form-label fw-bold fs-6'>
                          Closed By/Checked & Verified
                        </label>

                        <div className='col-lg-4'>
                          <div className='row'>
                            <div className='col-lg-12 fv-row'>
                              <input
                                type='text'
                                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                name='closedBy'
                                value={values.closedBy}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='col-lg-4'>
                          <div className='row'>
                            <div className='col-lg-12 fv-row'>
                              <input
                                type='text'
                                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                name='closedOn'
                                value={values.closedOn}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='card-footer d-flex justify-content-end py-6 px-9'>
                      <a type='reset' className='btn btn-light me-3' href={'/alerts/open'}>
                        Discard
                      </a>
                      {values.status !== 'Closed' && (
                        <button type='submit' className='btn btn-primary' disabled={isSubmitting}>
                          <span className='indicator-progress' style={{display: 'block'}}>
                            {!isSubmitting && (
                              <span className='indicator-label'>
                                Closed &nbsp; <i class='fas fa-chevron-right fs-1x'></i>
                              </span>
                            )}
                            {isSubmitting && (
                              <span className='indicator-progress' style={{display: 'block'}}>
                                <span className='spinner-border spinner-border-lg align-middle ms-2'></span>
                              </span>
                            )}
                          </span>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default Alert
