export const axioDefaultConfig = {
  method: 'get',
  url: 'https://localhost:44321/api/contractors',
  data: {},
  headers: {
    'Content-Type': 'application/json',
  },
  validateStatus: function (status) {
    return status >= 200 && status < 400 // default
  },
}
  
export const validcoderegex = /^[a-z0-9]{1,15}$/;
export const vehicleRegex = /^([0-9]{1,3}|[A-Z]{1,3})-[0-9]{1,4}$/

export const options = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  // and so on ...
}
