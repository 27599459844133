import React, {useState, useEffect, useRef, PureComponent, Component} from 'react'
import {useQuill} from 'react-quilljs'
// or const { useQuill } = require('react-quilljs');
import 'quill/dist/quill.snow.css' // Add css for snow theme

function TagConfig(props) {
  const inputReference = useRef(null)
  const {quill, quillRef} = useQuill('#editor', {
    theme: 'snow',
  })

  React.useEffect(() => {
    if (quill) {
      quill.clipboard.dangerouslyPasteHTML(props?.item?.note ?? '')
      if (inputReference) {
        inputReference.current.focus()
      }

      quill.on('text-change', (delta, oldDelta, source) => {
        var hm = quill.root.innerHTML
        //  console.log('Text change!');
        //  console.log(quill.getText()); // Get text only
        //console.log(quill.getContents()); // Get delta contents
        // console.log(quill.root.innerHTML); // Get innerHTML using quill
        //console.log(quillRef.current.firstChild.innerHTML); // Get innerHTML using quillRef
        /* if (quill.root.innerHTML && quill.root.innerHTML.substr(quill.root.innerHTML.length - 11) == "<p><br></p>") {
                hm = quill.root.innerHTML.substr(0, quill.root.innerHTML.length - 11) + "<p>&#8205;</p>"
            }*/
        props.item.note = hm
      })
    }
  }, [quill])

  return (
    <>
      <div className='col-lg-12'>
        <b className='required' style={{paddingBottom: '30px'}}>
          Packet Name
        </b>
        <input
          ref={inputReference}
          onChange={(e) => props.onkeyPress(e, props.index, 'OBJECT')}
          className='form-control  form-control-solid '
          value={props.item.object}
          placeholder='Packet Name'
          type='text'
        />
        <span style={{color: 'gray', fontStyle: 'italic'}}>
          [Plant] / [Plant.PacketType] / [PacketType]
        </span>
        <br /> <br />
      </div>
      <div className='col-lg-12'>
        <b className='required'>Tag/Field Name</b>
        <input
          onChange={(e) => props.onkeyPress(e, props.index, 'NAME')}
          className='form-control  form-control-solid '
          value={props.item.machineNo}
          placeholder='Tag Name'
          type='text'
        />
        <a target={'_blank'} href='/object-browser'>
          view object browser
        </a>
        <br /> <br />
      </div>
      <div className='col-lg-12'>
        <table style={{width: '100%'}}>
          <tr>
            <td style={{width: 100}}>
              <b className=''>Display Text</b>
              <input
                onChange={(e) => props.onkeyPress(e, props.index, 'DISPLAY_NAME')}
                className='form-control  form-control-solid '
                value={props.item?.displayName}
                placeholder='Tag Name'
                type='text'
              />
            </td>
            <td style={{width: 100}}>
              <b className='required'>Type</b>
              <select
                value={props.item.type}
                onChange={(e) => props.handleChange(e, props.index, 'TYPE')}
                className='form-control form-control-solid '
              >
                <option value='1'>Dongsung</option>
                <option value='2'>Simaseki - NFG</option>
                <option value='10'>Empty Block</option>
                <option value='20'>Tag</option>
                <option value='13'>Line Chart</option>
                <option value='14'>Stacked Area Chart</option>
                <option value='15'>Bar Chart</option>
                {/*<option value="16">Stacked Bar Chart</option>*/}
                <option value='17'>Pie Chart</option>
                <option value='19'>Fill Bar</option>
                <option value='12'>Tag v1 (deprecated)</option>
                <option value='25'>Design Component</option>
                <option value='26'>Line Chart - Chartjs</option>
                {/*<option value="18">Percent Area Chart</option>*/}
              </select>
            </td>
          </tr>
        </table>
      </div>

      <div className='col-lg-12'>
        <br /> <br />
        <table style={{width: '100%'}}>
          <tr>
            <td style={{width: 100}}>
              <b className='required'> X coordinate</b>
              <input
                type='number'
                onChange={(e) => props.onkeyPress(e, props.index, 'X')}
                className='form-control  form-control-solid '
                value={props.item?.defaultPos?.x}
                placeholder='X'
              />
            </td>
            <td style={{width: 100}}>
              <b className='required'> Y coordinate</b>
              <input
                type='number'
                onChange={(e) => props.onkeyPress(e, props.index, 'Y')}
                className='form-control  form-control-solid '
                value={props.item?.defaultPos?.y}
                placeholder='Y'
              />
            </td>
          </tr>
        </table>
      </div>
      <div className='col-lg-12'>
        <br /> <br />
        <b className=''>[CODE - INITIAL]</b>
        <textarea
          rows='8'
          cols='50'
          onChange={(e) => props.onkeyPress(e, props.index, 'INIT')}
          className='form-control  form-control-solid '
          value={props.item.tempFeild1}
          placeholder='Intial'
          type='text'
        />
        <a target={'_blank'} href='/documentation.html'>
          View Documentation
        </a>
      </div>
      <div className='col-lg-12'>
        <div className='col-lg-12 fv-row'>
          <br /> <br />
          <b className=''>[Code - RUNTIME/Data Manipulation]</b>
          <textarea
            rows='15'
            cols='50'
            onChange={(e) => props.onkeyPress(e, props.index, 'FORMULA')}
            className='form-control  form-control-solid '
            value={props.item.formula}
            placeholder='Code here'
            type='text'
          />
        </div>
        <a target={'_blank'} href='/documentation.html'>
          View Documentation
        </a>
        <hr />
      </div>
      <div className='col-lg-12'>
        {' '}
        <b className=''>Document/Instructions</b>
        <div style={{width: 500, height: 300}}>
          <div ref={quillRef}></div>
        </div>
        {/*  <textarea rows="8" cols="50" onChange={(e) => props.onkeyPress(e, props.index, "DOCUMENT")} 
                className='form-control  form-control-solid ' value={props.item.note} placeholder='Document' type="text" />*/}
      </div>
    </>
  )
}
export default TagConfig
//https://codesandbox.io/s/k2ds4?file=/src/App.js:128-4341
