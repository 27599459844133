import React, { Suspense, lazy } from 'react'
import { Redirect, Route, Router, Switch } from 'react-router-dom'
import { FallbackView } from '../../_metronic/partials'

import AllLayout from '../ui/layout/AllLayouts'
import Alert from '../ui/layout/AllAlerts'
import AlertMgt from '../ui/layout/Alert'
import AllGroups from '../ui/layout/AllGroups'
import NewLayout from '../ui/layout/NewLayout'

import Design from '../ui/layout/Design'
import EditHome from '../ui/knitting/dashboard/EditHome'
import ObjectViewer from "../ui/sensor/config/ObjectViewer";
import { MainDashboard } from '../pages/dashboard/MainDashboard'
import Sector from "../ui/dashboard/SubSectorManager";
import Dashboard from "../ui/dashboard/Dashboard";
import facility from "../ui/dashboard/FacilityManager";
import Factories from "../ui/dashboard/FactoriesManager";
import SensorView from "../ui/dashboard/SensorView";
import Factory from "../ui/dashboard/BusinessUnitManager";
import FactoryDetail from "../ui/dashboard/BusinessUnitDetailManager";
import FactoryDetail2 from "../ui/dashboard/BusinessUnitDetailManager2";

import Display from "../ui/sensor/config/display";
import { createBrowserHistory } from 'history';
export const customHistory = createBrowserHistory();  //This maintains custom history


export function PrivateRoutes() {
  /*const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
*/
  return (
    /* <Router history={customHistory} > */
    <Switch>
      <Route path='/dashboard' component={Dashboard} />
      <Route path='/layout/:id' component={Display} />
      <Route path='/facility/:code' component={facility} />
      <Route path='/facility-detail/:code' component={FactoryDetail} />
      <Route path='/facility-detail2/:code' component={FactoryDetail2} />

      <Route path='/facility-detail-item/:code' component={SensorView} />
      <Route path='/alerts/open/:sector' component={Alert} />
      <Route path='/alerts/open' component={Alert} />
      <Route path='/alerts/closed' component={Alert} />
      <Route path='/alert/view/:sector/:id' component={AlertMgt} />


      <Route path='/all-layouts/new' component={NewLayout} />
      <Route path='/object-browser' component={ObjectViewer} />
      <Route path='/all-layouts/edit/:id' component={NewLayout} />
      <Route path='/all-layouts/design/:id' component={Design} />
      <Route path='/all-layouts' component={AllLayout} />
      <Route path='/all-groups' component={AllGroups} />

      <Route path='/:sector/:subsector/:factory/:unit/:code' component={SensorView} />
      <Route path='/:sector/:subsector/:factory/:unit' component={FactoryDetail} />
      <Route path='/:sector/:subsector/:factory' component={Factory} />
      <Route path='/:sector/:subsector' component={Factories} />
      <Route path='/:sector' component={Sector} />
      <Route path='/' component={facility} />
      {/*
        <Route path='/test' component={SensorView} />
        <Redirect from='/auth' to='/ ' />
        <Route path='/detail-dashboard' component={DashboardDetailWrapper} />
        <Route path='/main' component={MainDashboard} />
        <Route path='/knt-pmp' component={EditHome} />
        <Route path='/sub-sectors/:sector' component={Sector} />
        <Route path='/factories/:subsector' component={Factories} />
        <Route path='/business-unit/:code/:unit' component={FactoryDetail} />
        <Route path='/business-units/:code' component={Factory} />
        <Route path='/Sectors/:sector' component={Sector} />
        <Route path='/subsectors/:subsector' component={Factories} />
        <Route path='/factories/:code' component={Factory} />
        <Route path='/factory-detail/:code/:unit' component={FactoryDetail} />
        <Route path='/sensor-unit/:code' component={SensorView} />
        <Route path='/test' component={SensorView} />
        <Route path='/sensor/display/:id' component={SensorIndex} />
  		<Route path='/live' component={Dashboard} />
		 <Route path='/home' component={Home} />
        <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
        <Redirect to='error/404' />*/}
    </Switch>
    /* </Router> */
  )
}
