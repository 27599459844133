export default function LoadingSentrum(props) {
  return (
    <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'>
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        <h1>Connecting...</h1>
        <div className='w-lg-500px  p-10 p-lg-15 mx-auto'>
          <div>
            <span className='indicator-progress' style={{display: 'block', textAlign: 'center'}}>
              <>
                {props.state.DashboardStat}{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </>
              <br />
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}
