import React, {useState, useEffect, useRef} from 'react'
import './../../../_metronic/assets/sass/kntdashbaord.css'
import {HubConnectionBuilder, JsonHubProtocol} from '@microsoft/signalr'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import axios from 'axios'
import 'reactjs-popup/dist/index.css'
import {ToastContainer, toast, Slide, Zoom, Flip, Bounce} from 'react-toastify'
import {FabricJSCanvas, useFabricJSEditor} from 'fabricjs-react'
import {Line} from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import {axioDefaultConfig} from '../common/commonResource2'
import {useDataLayerValue} from '../../../DataLayer'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {useTable, useFilters, useGlobalFilter, usePagination, useAsyncDebounce} from 'react-table'
import ErrorMessage from '../helpers/message'
import Loading from '../helpers/loading'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)

function SensorView(props) {
  const [{token}, dispatch] = useDataLayerValue()
  const labels = []
  const chartReference = React.createRef()
  const breadCrumbs = useRef([])
  const hubService = process.env.REACT_APP_MIDAS_HUB
  const [connection, setConnection] = useState(null)
  const reconnectRef = useRef(0)
  const urlParams = new URLSearchParams(window.location.search)
  const [isloading, setLoading] = useState(true)
  const [isChartLoading, setChartLoading] = useState(true)
  const [SensorList, setsensorList] = useState([])
  const sensors = useRef([])
  const [DeviceType, setDeviceType] = useState('24h')
  const [InitialLoading2, setInitialLoading2] = useState(true)
  const [data, setdata] = useState([])

  const {selectedObjects, setSelectedObject, editor, onReady} = useFabricJSEditor({
    defaultStrokeColor: 'red',
    selection: false,
  })

  const [layout, setLayout] = useState({
    id: 'dashboard',
    layoutName: '',
    imageData: null,
    devices: [],
    isZoom: false,
    globalJs: '',
    designObject: [],
    factories: [],
    subSectorName: '',
    subSectorCode: '',
    sectorName: '',
    sectorCode: '',
    factroyName: '',
    factroyCode: '',
    unitName: '',
    unitCode: '',
    Sensor: [],
    Packet: '',
    Field: '',
    data: [],
  })

  const [live, setLive] = useState({
    Live: '',
    Min: '',
    Max: '',
    Status: '',
    Note: '',
    Description: '',
    NormalRange: '',
    WarningRange: '',
    CriticalRange: '',
    DisableRange: '',
  })

  const [state, setState] = useState({
    Data: {},
    DashboardStat: 'CONNECTING',
    SensorList: [],
    name: '',

    option: {
      responsive: true,
      plugins: {
        annotation: {
          annotations: {
            line1: {
              type: 'line',
              yMin: 60,
              yMax: 60,
              borderColor: 'rgb(255, 99, 132)',
              borderWidth: 2,
            },
          },
        },
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: 'Line Chart',
        },
        bezierCurve: false,
        elements: {
          line: {
            tension: 0,
          },
        },
      },
    },
    data: {
      labels,
      datasets: [
        {
          label: '',
          data: [],
          lineTension: 0,
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
      ],
    },
  })

  function TablesWidget10() {
    const columns = React.useMemo(
      () => [
        {
          Header: ' ',
          columns: [
            {
              accessor: 'code',
            },
            {
              accessor: 'formatCode',
            },
            {
              accessor: 'description',
            },
            {
              accessor: 'createdOn',
            },
            {
              accessor: 'status',
            },
            {
              accessor: 'priority',
            },
            {
              accessor: 'fK_SectorCode',
            },
            {
              accessor: 'facilityName',
            },
            {
              accessor: 'remain',
            },
          ],
        },
      ],
      []
    )

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      page,
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: {pageIndex, pageSize, globalFilter},
      preGlobalFilteredRows,
      setGlobalFilter,
    } = useTable(
      {
        columns,
        data,
        initialState: {pageIndex: 0, pageSize: 20, globalFilter: ''},
      },
      useGlobalFilter,
      usePagination
    )
    const [error, setError] = useState({status: 'E', text: ''})

    const [{user, token}, dispatch] = useDataLayerValue()

    if (InitialLoading2) return <Loading />

    return (
      <div className={`card`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Last 24h Alerts</span>
            <span className='text-muted mt-1 fw-bold fs-7'> Alert Management</span>
          </h3>
          <ErrorMessage error={error} />
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click to add a user'
          >
            <div className='me-4'></div>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table
              className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'
              {...getTableProps()}
            >
              <thead>
                <td className='text-gray-600 text-gray-800 fw-bolder'>Ticket</td>
                <td className='text-gray-600 text-gray-800 fw-bolder'>Description</td>
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.length === 0 && (
                  <tr>
                    <td colSpan={7}>
                      <b>No Data Found!</b>
                    </td>
                  </tr>
                )}
                {page.map((row, i) => {
                  prepareRow(row)
                  return (
                    <tr {...row.getRowProps()}>
                      {/* {row.cells.map((cell) => {
                      return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    })}*/}
                      <td style={{width: '120px'}}>
                        {
                          <a className='btn btn-sm  fw-bolder  fs-9 py-1'>
                            {row.values.formatCode}
                          </a>
                        }
                      </td>
                      <td>
                        <b>{row.values.description}</b>{' '}
                        <span
                          style={{fontSize: '10px'}}
                          className='btn btn-sm btn-light-info fw-bolder  fs-9 py-1'
                        >
                          {row.values.priority}
                        </span>
                        {
                          <a
                            style={{fontSize: '10px'}}
                            className='btn btn-sm btn-light-info fw-bolder  ms-2 fs-9 py-1'
                          >
                            {row.values.facilityName}
                          </a>
                        }
                        <br />
                        <span style={{fontSize: '10px'}}> {row.values.createdOn}</span>
                        {row.values.status == 'Opened' && (
                          <a
                            style={{fontSize: '10px'}}
                            className='btn btn-sm btn-light-danger fw-bolder ms-2 fs-9 py-1 px-3'
                          >
                            {' '}
                            OPENED ({row.values.remain})
                          </a>
                        )}
                        {row.values.status == 'Resolved' && (
                          <a
                            style={{fontSize: '10px'}}
                            className='btn btn-sm btn-light-warning fw-bolder ms-2 fs-9 py-1 px-3'
                          >
                            {' '}
                            RESOLVED
                          </a>
                        )}
                        {row.values.status == 'Closed' && (
                          <a
                            style={{fontSize: '10px'}}
                            className='btn btn-sm btn-light-gray fw-bolder ms-2 fs-9 py-1 px-3'
                          >
                            {' '}
                            CLOSED
                          </a>
                        )}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            {/* 
      Pagination can be built however you'd like. 
      This is just a very basic UI implementation:
    */}
            <ul className='pagination'>
              <li className='page-item' onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                <a className='page-link'>First</a>
              </li>
              <li className='page-item' onClick={() => previousPage()} disabled={!canPreviousPage}>
                <a className='page-link'>{'<'}</a>
              </li>
              <li className='page-item' onClick={() => nextPage()} disabled={!canNextPage}>
                <a className='page-link'>{'>'}</a>
              </li>
              <li
                className='page-item'
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                <a className='page-link'>Last</a>
              </li>
              <li>
                <a className='page-link'>
                  Page{' '}
                  <strong>
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>{' '}
                </a>
              </li>
              <li>
                <a className='page-link'>
                  <input
                    className='page-link'
                    type='number'
                    defaultValue={pageIndex + 1}
                    onChange={(e) => {
                      const page = e.target.value ? Number(e.target.value) - 1 : 0
                      gotoPage(page)
                    }}
                    style={{width: '100px', height: '20px'}}
                  />
                </a>
              </li>{' '}
              <select
                className='page-link'
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value))
                }}
                style={{width: '120px', height: '38px'}}
              >
                {[5, 10, 20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </ul>
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
    )
  }
  const [countdown, setCountdown] = useState(60)

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then((result) => {
          setState({
            ...state,
            DashboardStat: 'CONNECTED',
          })
          setLoading(false)
          reconnectRef.current = 0
          connection.onreconnecting((error) => {
            setState({
              ...state,
              DashboardStat: 'RECONNECTING',
            })
            console.log('Connection lost due to error . Reconnecting.')
          })

          // Reconnect if hub disconnects
          connection.onclose(function (e) {
            setState({
              ...state,
              DashboardStat: 'DISCONNECTED',
            })
            //console.log('Disconnected');
          })

          connection.on('ReceiveMessage', (message) => {
            if (typeof message === 'string' || message instanceof String) {
              message = JSON.parse(message)
            }
            if (message.key !== 'SensorData') return

            let dataPacket = JSON.parse(message.data)
            if (
              dataPacket.TimeStamp === undefined ||
              Math.round((new Date() - new Date(dataPacket.TimeStamp)) / 1000) > 10
            ) {
              return
            }

            console.log(message)
            let newElements = {}
            Object.entries(dataPacket).map(([key, value]) => {
              newElements[key] = value
            })

            Object.entries(dataPacket).map(([key, value]) => {
              for (let index = 0; index < sensors.current.length; index++) {
                const element = sensors.current[index]
                if (element.machineNo === '') continue

                if (element.displayName === '') element.displayName = ' '

                if (element.icon === undefined) element.icon = 'dot'

                if (
                  element.isNotify === undefined ||
                  element.isNotify === '' ||
                  element.isNotify === null
                )
                  element.isNotify = false

                if (!element.lastUpdate) {
                  element.lastUpdate = new Date()
                } else if (Math.round((new Date() - new Date(element.lastUpdate)) / 1000) > 60) {
                  // console.log('[DB PACKET ' + element.machineNo.toString().toLowerCase() + ']')
                  if (
                    element.type == 14 ||
                    element.type == 13 ||
                    element.type == 17 ||
                    element.type == 15
                  ) {
                    //console.log("[chart data cleared "+element.machineNo.toString().toLowerCase()+"]");
                    element.value = []
                  } else {
                    element.value = '---'
                    element.color = 'black'
                  }
                }

                let object = element.object.toString().toLowerCase()
                let packetType = dataPacket.Plant + '.' + dataPacket.Packet

                if (
                  element.object.toString().toLowerCase() === key.toString().toLowerCase() &&
                  packetType.toString().toLowerCase() === element.pakages
                ) {
                  element.lastUpdate = new Date()
                  element.value = value
                  element.color = 'black'
                  element.notification = {
                    toastId: key.toString().toLowerCase(),
                    position: 'top-right',
                    type: 'info',
                    autoClose: false, // or set time
                    hideProgressBar: false,
                    closeOnClick: true,
                    //  pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    transition: Bounce,
                  }
                  try {
                    // alert(layout.globalJs)
                    eval(layout.globalJs + ';' + element.formula)
                    //   eval(element.formula)
                  } catch (error) {
                    element.value = '---'
                    element.color = 'black'
                    // console.log(element.formula)
                    console.log(error)
                  }
                }
              }
            })
            setState({
              ...state,
              SensorList: sensors.current,
              DashboardStat: 'CONNECTED',
            })
          })
        })
        .catch((e) => {})
    }
  }, [connection])

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      // Decrease the countdown value every second
      setCountdown((prevCountdown) => (prevCountdown - 1 < 0 ? 0 : prevCountdown - 1))
    }, 1000)

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(countdownInterval)
  }, []) // Empty dependency array ensures the effect runs only once

  useEffect(() => {
    axios({
      method: 'get',
      url:
        process.env.REACT_APP_BACKEND_API +
        '/api/sensors/' +
        (props.match?.params?.code ?? 'dream'),
      headers: {...axioDefaultConfig.headers, Authorization: 'Bearer ' + token},
    }).then((response) => {
      if (response.data.status == 'S') {
        setLayout({
          ...layout,
          //subSectorName: response.data.result.subSectors[0].name,
          //subSectorCode: response.data.result.subSectors[0].code,
          //sectorName: response.data.result.name,
          //sectorCode: response.data.result.code,
          //factroyName: response.data.result.subSectors[0].factories[0].name,
          //factroyCode: response.data.result.subSectors[0].factories[0].code,
          //unitName: response.data.result.businessUnitModels[0].name,
          //unitCode: response.data.result.businessUnitModels[0].code,
          Sensor: response.data.result.unitModels[0],
          breadCrumbs: response.data.result.breadCrumbs,
        })
        // setBreadCrum(response)
        RefreshData(response.data.result.unitModels[0])
        setInterval(() => {
          RefreshData(response.data.result.unitModels[0])
        }, 60000)

        setState({
          ...state,
          SensorList: response.data.result.unitModels,
        })
        sensors.current = response.data.result.unitModels
        setTimeout(() => {
          for (let index = 0; index < sensors.current.length; index++) {
            const element = sensors.current[index]
            try {
              eval(layout.globalJs + ';' + element.tempFeild1)
            } catch (error) {
              console.log(error)
            }
          }
          setState({
            ...state,
            SensorList: sensors.current,
            DashboardStat: 'INITIALIZATION',
          })

          setTimeout(() => {
            const newConnection = new HubConnectionBuilder()
              .withUrl(
                process.env.REACT_APP_MIDAS_HUB +
                  '/' +
                  response.data.result.automationChannel +
                  '/Hub'
              )
              .withAutomaticReconnect()
              .build()
            setConnection(newConnection)
          }, 100)
        }, 500)
      }
    })
  }, [])

  function RefreshData(obj) {
    if (obj?.name == undefined) return

    /// Loading

    const sector = ''
    axios({
      ...axioDefaultConfig,
      method: 'get',
      url: process.env.REACT_APP_BACKEND_API + '/api/get-alerts-tag?tag=' + obj.code,
      headers: {...axioDefaultConfig.headers, Authorization: 'Bearer ' + token},
    })
      .then(function (response) {
        setInitialLoading2(false)
        setdata(response?.data?.data)
      })
      .catch(function (error) {})

    var datafield = {}
    datafield['sensorCode'] = obj.code
    datafield['sort'] = 'TimeStamp: 1'
    datafield['find'] = 't_' + obj.object + ' : {$exists:true}, TimeStamp : ' + DeviceType
    datafield['project'] = 'TimeStamp: 1, ' + 't_' + obj.object + ' : 1'
    axios({
      method: 'post',
      url: process.env.REACT_APP_BACKEND_API + '/api/sensor/getall',
      headers: {},
      data: datafield,
    }).then((response) => {
      if (response.data.status == 'S') {
        setChartLoading(false)
        const _labels = []
        const _data = []
        for (let index = 0; index < response.data.result.list.length; index++) {
          const element = response.data.result.list[index]
          _labels.push(element['TimeStamp'])
          _data.push(element['t_' + obj.object])
        }
        const chart = chartReference.current
        chart.data.labels = _labels
        // state.data.datasets[0].label = obj.dispalyName ?? obj.machineNo
        // state.data.datasets[0].data = _data
        setCountdown(60) // Reset the countdown to 5 seconds
        setLive({
          Live: response.data.result.currrent,
          Min: response.data.result.min,
          Max: response.data.result.max,
          Date: response.data.result.date,
          Status: response.data.result.status,
          Note: response.data.result.note,
          Description: response.data.result.description,
          NormalRange: response.data.result.normalRange,
          WarningRange: response.data.result.warningRange,
          CriticalRange: response.data.result.criticalRange,
          DisableRange: response.data.result.disableRange,
        })
        state.data.datasets = [
          {
            label: obj.dispalyName ?? obj.object,
            data: _data,
            lineTension: 0,
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
          },
        ]
        /*  chart.data = {
          _labels,
          datasets: [
            {
              label: obj.dispalyName ?? obj.machineNo,
              data: _data,
              borderColor: 'rgb(53, 162, 235)',
              backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
          ],
        }*/

        chart.data.datasets[0] = state.data.datasets[0]

        chart.update('none')
        setLoading(false)
      }
    })
  }

  function hideAllNotification() {
    toast.dismissAll()
  }

  function hideNotification(notification) {
    toast.dismiss(notification.toastId)
  }

  function getTransactionType(transition) {
    switch (transition.toLowerCase()) {
      case 'flip':
        return Flip
      case 'slide':
        return Slide
      case 'zoom':
        return Zoom

      default:
        return Bounce
    }
  }
  function getType(type) {
    switch (type.toLowerCase()) {
      case 'info':
        return toast.TYPE.INFO
      case 'error':
        return toast.TYPE.ERROR
      case 'success':
        return toast.TYPE.SUCCESS
      case 'warning':
        return toast.TYPE.WARNING
      default:
        return toast.TYPE.INFO
    }
  }

  function showNotification(notification) {
    notification.transition = getTransactionType(notification.transition)
    if (toast.isActive(notification.toastId)) {
      notification.render = notification.message
      notification.type = getType(notification.type)
      toast.update(notification.toastId, notification)
      return
    }

    switch (notification.type) {
      case 'error':
        toast.error(notification.message, notification)
        break
      case 'info':
        toast.info(notification.message, notification)
        break
      case 'warning':
        toast.warn(notification.message, notification)
        break
      case 'success':
        toast.success(notification.message, notification)
        break
      default:
        toast(notification.message)
        break
    }
  }

  function Notification(notification) {
    if (toast.isActive(notification.toastId)) {
      toast.dismiss(notification.toastId)
    }
    switch (notification.Type) {
      case 'error':
        toast.error(notification.Message, {
          toastId: notification?.toastId ?? 'default',
          position: notification?.Position ?? 'top-right',
          autoClose: notification?.Autoclose ?? 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
        break

      case 'info':
        toast.info(notification.Message, {
          position: notification?.Position ?? 'top-right',
          autoClose: notification?.Autoclose ?? 5000,
          toastId: notification?.toastId ?? 'default',
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
        break
      case 'warning':
        toast.warn(notification.Message, {
          position: notification?.Position ?? 'top-right',
          autoClose: notification?.Autoclose ?? 5000,
          toastId: notification.toastId ?? 'default',
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
        break
      case 'success':
        toast.success(notification.Message, {
          position: notification?.Position ?? 'top-right',
          autoClose: notification?.Autoclose ?? 5000,
          toastId: notification?.toastId ?? 'default',
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
        break
      default:
        toast('Wow so easy!')
        break
    }
  }

  function timeonchange(e) {
    setDeviceType(e.target.value)
    setChartLoading(true)
    setTimeout(() => {
      RefreshData(layout.Sensor)
    }, 100)
  }

  var statusColorClass = 'btn btn-sm btn-light-dark'
  if (live.Status == 'NORMAL') {
    statusColorClass = 'btn btn-sm btn-light-success'
  } else if (live.Status == 'WARNING') {
    statusColorClass = 'btn btn-sm btn-light-warning'
  } else if (live.Status == 'CRITICAL') {
    statusColorClass = 'btn btn-sm btn-light-danger'
  }

  return (
    <>
      <PageTitle breadcrumbs={layout.breadCrumbs}>{layout?.Sensor?.name} </PageTitle>
      <div className={`card`}>
        <div className='card-header border-0 '>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Data Visualized (24h)</span>
            <span className='text-muted mt-1 fw-bold fs-7'>
              {layout?.Sensor?.description} |
              <b style={{color: 'darkblue'}}>
                Last Update at {live?.Date} <i class='bi bi-calendar'></i>
              </b>
            </span>
          </h3>
          {countdown <= 10 && (
            <>
              <b style={{color: 'red'}}>Auto refresh in {countdown} seconds</b>
            </>
          )}
          {countdown > 10 && (
            <>
              <p>Auto refresh in {countdown} seconds</p>{' '}
            </>
          )}

          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title=''
          >
            <div className={statusColorClass}>
              <div
                style={{
                  fontFamily: 'Roboto Mono, monospace',
                  zIndex: 10,
                  fontSize: '30px',
                }}
              >
                {live.Live}°C
              </div>
            </div>
          </div>
        </div>

        <div className='card-body py-3'>
          {isChartLoading && <>Loading</>}
          <Line height={100} option={state.option} ref={chartReference} data={state.data} />
        </div>
      </div>

      <br />

      <div className='row'>
        <div
          className={
            'notice d-flex  rounded  border border-dashed p-6 col-lg-3  mb-1  ' +
            (live.Status == 'CRITICAL' ? 'bg-light-danger border-danger' : ' border-dark')
          }
        >
          <KTSVG
            path='icons/duotune/general/gen044.svg'
            className='svg-icon-2tx svg-icon-danger me-4'
          />
          <div className='d-flex flex-stack flex-grow-1'>
            <div className='fw-bold'>
              <h4 className='text-gray-800 fw-bolder'>Critical Level</h4>
              <div className='fs-6 text-gray-600'>{live?.CriticalRange} Range</div>
            </div>
          </div>
        </div>
        <div
          className={
            'notice d-flex  rounded  border border-dashed p-6 col-lg-3  mb-1 ' +
            (live.Status == 'WARNING' ? 'bg-light-warning border-warning' : ' border-dark')
          }
        >
          <KTSVG
            path='icons/duotune/general/gen044.svg'
            className='svg-icon-2tx svg-icon-warning me-4'
          />
          <div className='d-flex flex-stack flex-grow-1'>
            <div className='fw-bold'>
              <h4 className='text-gray-800 fw-bolder'>Warning Level</h4>
              <div className='fs-6 text-gray-600'>{live?.WarningRange} Range</div>
            </div>
          </div>
        </div>
        <div
          className={
            'notice d-flex  rounded  border border-dashed p-6 col-lg-3  mb-1  ' +
            (live.Status == 'NORMAL' ? 'bg-light-success border-success' : ' border-dark')
          }
        >
          <KTSVG
            path='icons/duotune/general/gen044.svg'
            className='svg-icon-2tx svg-icon-success me-4'
          />
          <div className='d-flex flex-stack flex-grow-1'>
            <div className='fw-bold'>
              <h4 className='text-gray-800 fw-bolder'>Normal Level</h4>
              <div className='fs-6 text-gray-600'>{live?.NormalRange} Range</div>
            </div>
          </div>
        </div>

        <div
          className={
            'notice d-flex  rounded border-dark border border-dashed p-6 col-lg-3  mb-1  ' +
            (live.Status == 'DISABLED' ? 'bg-light-dark' : '')
          }
        >
          <KTSVG
            path='icons/duotune/general/gen044.svg'
            className='svg-icon-2tx svg-icon-dark me-4'
          />
          <div className='d-flex flex-stack flex-grow-1'>
            <div className='fw-bold'>
              <h4 className='text-gray-800 fw-bolder'>Disable Level</h4>
              <div className='fs-6 text-gray-600'>{live?.DisableRange} Range</div>
            </div>
          </div>
        </div>
      </div>

      <br />
      <div className='row'>
        <div className='col-sm-6'>
          <div className={`card`}>
            <div className='card-header border-0'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>Minimum Temprature</span>
                <span className='text-muted mt-1 fw-bold fs-7'>Based On Last 24hours</span>
              </h3>
              <div
                className='card-toolbar'
                data-bs-toggle='tooltip'
                data-bs-placement='top'
                data-bs-trigger='hover'
              >
                <div className='btn btn-sm btn-light-warning'>
                  <div
                    style={{
                      fontFamily: 'Roboto Mono, monospace',
                      zIndex: 10,
                      fontSize: '30px',
                    }}
                  >
                    {live.Min}°C
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-sm-6'>
          <div className={`card`}>
            <div className='card-header border-0'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>Maximum Temprature</span>
                <span className='text-muted mt-1 fw-bold fs-7'>Based On Last 24hours</span>
              </h3>
              <div
                className='card-toolbar'
                data-bs-toggle='tooltip'
                data-bs-placement='top'
                data-bs-trigger='hover'
                title='Click to add a user'
              >
                <div className='btn btn-sm btn-light-danger'>
                  <div
                    style={{
                      fontFamily: 'Roboto Mono, monospace',
                      zIndex: 10,
                      fontSize: '30px',
                    }}
                  >
                    {live.Max}°C
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <TablesWidget10 />
    </>
  )

  return (
    <>
      <PageTitle breadcrumbs={breadCrumbs.current}>{layout?.Sensor?.name} </PageTitle>
      <>
        <>
          <div className='row g-5 g-xl-8'>
            <div className='col-xl-12'>
              <br />
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>Data Visualized </span>
                <span className='text-muted mt-1 fw-bold fs-7'></span>
              </h3>
            </div>
          </div>
          <div className='row g-5 g-xl-8'>
            <Line height={100} option={state.option} ref={chartReference} data={state.data} />
          </div>
        </>
      </>
    </>
  )
}

export default SensorView
