import React, {useState, useEffect, useRef} from 'react'
import './../../../_metronic/assets/sass/kntdashbaord.css'
import {HubConnectionBuilder, JsonHubProtocol} from '@microsoft/signalr'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import axios from 'axios'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {MixedWidget20_1} from '../../../_metronic/partials/widgets'
import 'reactjs-popup/dist/index.css'
import {FabricJSCanvas, useFabricJSEditor} from 'fabricjs-react'
import LoadingSentrum from '../helpers/LoadingSentrum'
import {axioDefaultConfig} from '../common/commonResource2'
import {useDataLayerValue} from '../../../DataLayer'

const breadCrumbs = []
function FacilityManager(props) {
  const [{token, user}, dispatch] = useDataLayerValue()
  const hubService = process.env.REACT_APP_BACKEND_API
  const [connection, setConnection] = useState(null)
  const reconnectRef = useRef(0)
  const urlParams = new URLSearchParams(window.location.search)
  const [isloading, setLoading] = useState(true)
  const [item, setItem] = useState('')
  const [SensorList, setsensorList] = useState([])
  const sensors = useRef([])
  const {selectedObjects, setSelectedObject, editor, onReady} = useFabricJSEditor({
    defaultStrokeColor: 'red',
    selection: false,
  })

  const [layout, setLayout] = useState({
    id: 'dashboard',
    layoutName: '',
    imageData: null,
    devices: [],
    isZoom: false,
    globalJs: '',
    designObject: [],
    subSectors: [],
    detail: [],
    name: '',
  })

  const [state, setState] = useState({
    Data: {},
    DashboardStat: 'CONNECTING',
    IsRefesh: false,
    SensorList: [],
  })

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then((result) => {
          setState({
            ...state,
            DashboardStat: 'CONNECTED',
          })

          reconnectRef.current = 0

          connection.onreconnecting((error) => {
            setState({
              ...state,
              DashboardStat: 'RECONNECTING',
            })
            console.log('Connection lost due to error . Reconnecting.')
          })

          // Reconnect if hub disconnects
          connection.onclose(function (e) {
            setState({
              ...state,
              DashboardStat: 'DISCONNECTED',
            })
          })

          connection.on('ReceiveMessage', (message) => {
            if (typeof message === 'string' || message instanceof String) {
              message = JSON.parse(message)
            }
            //console.log(message)
            /* if (
              message.key !== 'SensorData' ||
              message.packetType !== (props.match?.params?.sector ?? 'dream')
            ) {
              return
            }*/

            let dataPacket = JSON.parse(message.data)
            if (dataPacket.Packet != (props.match?.params?.code ?? 'dream')) {
              return
            }

            // if (
            //   dataPacket.TimeStamp === undefined ||
            //   Math.round((new Date() - new Date(dataPacket.TimeStamp)) / 1000) > 60
            // ) {
            //   return
            // }
            console.log(message)
            sensors.current[dataPacket.Packet] = dataPacket

            setState({
              ...state,
              DashboardStat: 'CONNECTED',
            })
          })
        })
        .catch((e) => {
          console.log('Connection failed: ', e)
        })
    }
  }, [connection])

  useEffect(() => {
    console.log(token)
    axios({
      method: 'get',
      url:
        process.env.REACT_APP_BACKEND_API +
        '/api/facility/' +
        (props.match?.params?.code ?? 'dream'),
      headers: {...axioDefaultConfig.headers, Authorization: 'Bearer ' + token},
    })
      .then((response) => {
        if (response.data.status == 'S') {
          setLoading(false)
          setLayout({
            ...layout,
            detail: response.data.result.facilities,
            breadCrumbs: response.data.result.breadCrumbs,
            name: response.data.result.name,
            //   name: response.data.result.name,
          })

          setTimeout(() => {
            const newConnection = new HubConnectionBuilder()
              .withUrl(
                process.env.REACT_APP_MIDAS_HUB +
                  '/' +
                  response.data.result.automationChannel +
                  '/Hub'
              )
              .withAutomaticReconnect()
              .build()
            setConnection(newConnection)
          }, 100)
        }
      })
      .catch(function (error) {
        dispatch({
          type: 'SET_TOKEN',
          token: null,
        })
        return
      })
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={layout.breadCrumbs}>{layout.name} </PageTitle>

      <div>
        <div className='row g-2 g-xl-12'>
          {layout.detail.map((item, key) => (
            <>
              <div key={key} className='col-xl-3'>
                <MixedWidget20_1
                  factoryName={item.name}
                  code={item.code}
                  user={user?.role ?? ''}
                  critical={
                    sensors.current[props.match?.params?.code ?? 'dream']?.[item.code + '_critical']
                  }
                  warning={
                    sensors.current[props.match?.params?.code ?? 'dream']?.[item.code + '_warning']
                  }
                  alert={
                    sensors.current[props.match?.params?.code ?? 'dream']?.[item.code + '_alert']
                  }
                  alldevice={
                    sensors.current[props.match?.params?.code ?? 'dream']?.[item.code + '_devices']
                  }
                  connected={
                    sensors.current[props.match?.params?.code ?? 'dream']?.[
                      item.code + '_connected'
                    ]
                  }
                  link={
                    item.itemCount > 0
                      ? '/facility/' + item.code
                      : window.location.hostname.toLowerCase() === 'portal.smartgas.biz'
                      ? '/facility-detail2/' + item.code
                      : '/facility-detail/' + item.code
                  }
                  className='card-xl-stretch mb-xl-8'
                  color={'dark'}
                  timestamp={sensors.current[props.match?.params?.code ?? 'dream']?.['TimeStamp']}
                />
              </div>
            </>
          ))}
        </div>
      </div>
    </>
  )
}

export default FacilityManager
