import React from "react";
//import ChromeDinoGame from 'react-chrome-dino';

export function Dashboard() {
    return <div></div>
   /* return <div>

        <ChromeDinoGame />
    </div>*/
}


export default Dashboard
