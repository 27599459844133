/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { useLayout } from '../core'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'

var content = 'INDUSRTYX'
if (window.location.hostname.toLowerCase() === 'e2eq.lk') {
  content = 'E2EQ'
} if (window.location.hostname.toLowerCase() === 'portal.smartgas.biz') {
  content = 'SMARTGAS'
}

const Footer: FC = () => {
  const { classes } = useLayout()
  return (
    <div className='footer py-4 d-flex flex-lg-column' id='kt_footer'>
      {/* begin::Container */}
      <div
        className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
      >
        {/* begin::Copyright */}
        <div className='text-dark order-2 order-md-1'>
          <span className='text-muted fw-bold me-2'>{new Date().getFullYear()} &copy;</span>
          <a href='https://industryx.io/home-2' className='text-gray-800 text-hover-primary'>
            <img width={80} src="https://industryx.io/wp-content/uploads/2024/08/logo-2.png" />
          </a>
        </div>

        {/* end::Copyright */}

        {(content === 'E2EQ') &&
          <ul className='menu menu-gray-600 menu-hover-primary fw-bold order-1'>
            <li className='menu-item'>
              <a href='#' className='menu-link pe-0 pe-2'>
                {window.location.hostname.toLowerCase() === 'e2eq.lk' && <>
                  <img src={toAbsoluteUrl('/media/logos/kfe.png')} alt='Footer' width={100} />
                </>}

              </a>
            </li>
          </ul>

        }
        {/* begin::Nav 
        <ul className='menu menu-gray-600 menu-hover-primary fw-bold order-1'>
          <li className='menu-item'>
            <a href='#' className='menu-link ps-0 pe-2'>
              About
            </a>
          </li>
          <li className='menu-item'>
            <a href='#' className='menu-link pe-0 pe-2'>
              Contact
            </a>
          </li>
          <li className='menu-item'>
            <a href='#' className='menu-link pe-0'>
              Purchase
            </a>
          </li>
        </ul>*/}
        {/* end::Nav */}
      </div>
      {/* end::Container */}
    </div>
  )
}

export { Footer }
