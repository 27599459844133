import React, {useState, useEffect, useRef} from 'react'
import './../../../../_metronic/assets/sass/kntdashbaord.css'
import {HubConnectionBuilder, JsonHubProtocol} from '@microsoft/signalr'
import axios from 'axios'
import useWindowDimensions from '../../helpers/WindowDimension'
import Dictionary from '../../helpers/Dictionary'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Dropdown1} from '../../../../_metronic/partials'
import {AsideDefault} from '../../../../_metronic/layout/components/aside/AsideDefault'
import {Footer} from '../../../../_metronic/layout/components/Footer'
import {HeaderWrapper} from '../../../../_metronic/layout/components/header/HeaderWrapper'
import {PageDataProvider} from '../../../../_metronic/layout/core'
import {Content} from '../../../../_metronic/layout/components/Content'
import {
  ChartsWidget1,
  TablesWidget1,
  ListsWidget5,
  TablesWidget5,
} from '../../../../_metronic/partials/widgets'
import {stat} from 'fs'
import Confetti from 'react-confetti'
import ImageMapper from 'react-image-mapper'
import Draggable from 'react-draggable'
import {v4 as uuidv4} from 'uuid'
import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'
import SensorArea from './SensorArea'
import {auto, hide, right} from '@popperjs/core'
import {ToastContainer, toast, Slide, Zoom, Flip, Bounce} from 'react-toastify'

import {FabricJSCanvas, useFabricJSEditor} from 'fabricjs-react'
import {fabric} from 'fabric'

import 'react-toastify/dist/ReactToastify.css'
var randomColor = require('randomcolor')

function DisplayGroup(props) {
  const [connection, setConnection] = useState(null)
  const reconnectRef = useRef(0)
  const urlParams = new URLSearchParams(window.location.search)
  const {selectedObjects, setSelectedObject, editor, onReady} = useFabricJSEditor({
    defaultStrokeColor: 'red',
  })
  const [isloading, setLoading] = useState(true)
  const [item, setItem] = useState('')
  const [SensorList, setsensorList] = useState([])
  const sensors = useRef([])
  const [text, setText] = useState('')
  const [strokeColor, setStrokeColor] = useState('')
  const [fillColor, setFillColor] = useState('')
  const [isload, setisload] = useState(true)

  const [layout, setLayout] = useState({
    id: props.match?.params?.id ?? props.layout, //urlParams.get('id')??props.layout,
    layoutName: '',
    imageData: null,
    devices: [],
    isZoom: false,
    globalJs: '',
    hub: '',
    designObject: [],
  })
  const [state, setState] = useState({
    Data: {},
    DashboardStat: 'CONNECTING',
    SensorList: [],
  })

  const hubService = process.env.REACT_APP_MIDAS_HUB // "https://signalr.midassit.com";

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then((result) => {
          setState({
            ...state,
            DashboardStat: 'CONNECTED',
          })
          setLoading(false)
          reconnectRef.current = 0
          //// console.log('Connected!');
          connection.onreconnecting((error) => {
            setState({
              ...state,
              DashboardStat: 'RECONNECTING',
            })
            // // console.log('Connection lost due to error . Reconnecting.')
          })

          // Reconnect if hub disconnects
          connection.onclose(function (e) {
            setState({
              ...state,
              DashboardStat: 'DISCONNECTED',
            })
            //// console.log('Disconnected');
          })

          connection.on('ReceiveMessage', (message) => {
            if (typeof message === 'string' || message instanceof String) {
              message = JSON.parse(message)
            }

            // if (message.key !== "SensorData")
            // return;

            let dataPacket = JSON.parse(message.data)

            if (
              dataPacket.TimeStamp === undefined ||
              Math.round((new Date() - new Date(dataPacket.TimeStamp)) / 1000) > 10
            ) {
              return
            } else {
              if (dataPacket?.Notifications !== undefined && dataPacket?.Notifications.length > 0) {
                toast.dismiss()
                for (let index = 0; index < dataPacket?.Notifications.length; index++) {
                  const notification = dataPacket?.Notifications[index]
                  switch (notification.Type) {
                    case 'error':
                      toast.error(notification.Message, {
                        position: notification?.Position ?? 'top-right',
                        autoClose: notification?.Autoclose ?? 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      })
                      break

                    case 'info':
                      toast.info(notification.Message, {
                        position: notification?.Position ?? 'top-right',
                        autoClose: notification?.Autoclose ?? 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      })
                      break
                    case 'warning':
                      toast.warn(notification.Message, {
                        position: notification?.Position ?? 'top-right',
                        autoClose: notification?.Autoclose ?? 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      })
                      break
                    case 'success':
                      toast.success(notification.Message, {
                        position: notification?.Position ?? 'top-right',
                        autoClose: notification?.Autoclose ?? 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      })
                      break
                    default:
                      toast('Wow so easy!')
                      break
                  }
                }
              }

              let newElements = {}
              Object.entries(dataPacket).map(([key, value]) => {
                newElements[key] = value
              })

              Object.entries(dataPacket).map(([key, value]) => {
                for (let index = 0; index < sensors.current.length; index++) {
                  const element = sensors.current[index]
                  if (element.machineNo === '') continue

                  if (element.icon === undefined) element.icon = 'dot'

                  if (
                    element.isNotify === undefined ||
                    element.isNotify === '' ||
                    element.isNotify === null
                  )
                    element.isNotify = false

                  if (!element.lastUpdate) {
                    element.lastUpdate = new Date()
                  } else if (Math.round((new Date() - new Date(element.lastUpdate)) / 1000) > 60) {
                    //element.value = "---";
                    //element.color = 'black';
                    if (
                      element.type == 14 ||
                      element.type == 13 ||
                      element.type == 17 ||
                      element.type == 15
                    ) {
                      //// console.log("[chart data cleared "+element.machineNo.toString().toLowerCase()+"]");
                      element.value = []
                    } else {
                      element.value = '---'
                      element.color = 'black'
                    }
                  }

                  let object = element.object.toString().toLowerCase()
                  if (props?.object !== undefined && props?.object !== '') {
                    object = props?.object.toString().toLowerCase()
                  }

                  //console.log(""+message.packetType.toString().toLowerCase()+" === "+object)

                  if (
                    element.machineNo.toString().toLowerCase() === key.toString().toLowerCase() &&
                    message.packetType.toString().toLowerCase() === object
                  ) {
                    console.log(message)
                    // console.log("[DB PACKET "+element.machineNo.toString().toLowerCase()+"]"+JSON.stringify(value));
                    element.lastUpdate = new Date()
                    element.value = value
                    element.color = 'black'
                    element.notification = {
                      toastId: key.toString().toLowerCase(),
                      position: 'top-right',
                      type: 'info',
                      autoClose: false, // or set time
                      hideProgressBar: false,
                      closeOnClick: true,
                      //  pauseOnHover: true,
                      draggable: false,
                      progress: undefined,
                      transition: Bounce,
                    }
                    try {
                      // alert(layout.globalJs)
                      eval(layout.globalJs + ';' + element.formula)
                      //   eval(element.formula)
                    } catch (error) {
                      element.value = '---'
                      element.color = 'black'
                      // // console.log(element.formula)
                      // console.log(error);
                    }
                  }
                }
              })
              setState({
                ...state,
                SensorList: sensors.current,
                DashboardStat: 'CONNECTED',
              })
            }
          })
        })
        .catch((e) => {
          // console.log('Connection failed: ', e);
        })
    }
  }, [connection])

  useEffect(() => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_BACKEND_API + '/api/sensor-monitor/layout?layout=' + layout.id,
      headers: {},
    }).then((response) => {
      setLayout({
        ...layout,
        layoutName: response.data.result.layoutName,
        imageData: 'data:image/png;base64,' + response.data.result.imageData,
        isZoom: response.data.result.isZoom,
        globalJs: response.data.result.globalJs,
        hub: response.data.result.hub,
        designObject: response.data.result.designObject,
      })

      /*  try {
           //// console.log(response.data.result.globalJs)
           eval(response.data.result.globalJs)
         } catch (error) {
           // console.log(error);
         }*/

      setState({
        ...state,
        SensorList: response.data.result.devices,
      })

      sensors.current = response.data.result.devices

      setTimeout(() => {
        for (let index = 0; index < sensors.current.length; index++) {
          const element = sensors.current[index]
          try {
            eval(layout.globalJs + ';' + element.tempFeild1)
          } catch (error) {
            // console.log(error);
          }
        }
        setState({
          ...state,
          SensorList: sensors.current,
          DashboardStat: 'INITIALIZATION',
        })
        setTimeout(() => {
          const newConnection = new HubConnectionBuilder()
            .withUrl(hubService + '/' + response.data.result.hub + '/Hub') //
            .withAutomaticReconnect()
            .build()
          setConnection(newConnection)
        }, 100)
      }, 500)
    })
  }, [])

  if (editor != undefined && isload && layout.designObject.length > 0) {
    var desginObject = {
      version: '4.3.1',
      objects: [],
    }
    for (let index = 0; index < layout.designObject.length; index++) {
      const element = layout.designObject[index]
      var ds = JSON.parse(element.tempFeild1)
      //Movement:
      ds.lockMovementX = true
      ds.lockMovementY = true

      //Scaling:
      ds.lockScalingX = true
      ds.lockScalingY = true

      // Rotation:
      ds.lockRotation = true

      // Object's frame/bounidngBox:
      ds.hasControls = false
      ds.hasBorders = false
      desginObject.objects.push(ds)
    }
    editor?.canvas.loadFromJSON(desginObject)
    setisload(false)
  }

  let bodyCss = {
    overflowX: 'hidden',
  }

  function hideAllNotification() {
    toast.dismissAll()
  }

  function hideNotification(notification) {
    toast.dismiss(notification.toastId)
  }

  function getTransactionType(transition) {
    switch (transition.toLowerCase()) {
      case 'flip':
        return Flip
      case 'slide':
        return Slide
      case 'zoom':
        return Zoom

      default:
        return Bounce
    }
  }
  function getType(type) {
    switch (type.toLowerCase()) {
      case 'info':
        return toast.TYPE.INFO
      case 'error':
        return toast.TYPE.ERROR
      case 'success':
        return toast.TYPE.SUCCESS
      case 'warning':
        return toast.TYPE.WARNING
      default:
        return toast.TYPE.INFO
    }
  }

  function showNotification(notification) {
    notification.transition = getTransactionType(notification.transition)
    if (toast.isActive(notification.toastId)) {
      notification.render = notification.message
      notification.type = getType(notification.type)
      toast.update(notification.toastId, notification)
      return
    }

    switch (notification.type) {
      case 'error':
        toast.error(notification.message, notification)
        break
      case 'info':
        toast.info(notification.message, notification)
        break
      case 'warning':
        toast.warn(notification.message, notification)
        break
      case 'success':
        toast.success(notification.message, notification)
        break
      default:
        toast(notification.message)
        break
    }
  }

  function Notification(notification) {
    if (toast.isActive(notification.toastId)) {
      toast.dismiss(notification.toastId)
    }
    switch (notification.Type) {
      case 'error':
        toast.error(notification.Message, {
          toastId: notification?.toastId ?? 'default',
          position: notification?.Position ?? 'top-right',
          autoClose: notification?.Autoclose ?? 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
        break

      case 'info':
        toast.info(notification.Message, {
          position: notification?.Position ?? 'top-right',
          autoClose: notification?.Autoclose ?? 5000,
          toastId: notification?.toastId ?? 'default',
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
        break
      case 'warning':
        toast.warn(notification.Message, {
          position: notification?.Position ?? 'top-right',
          autoClose: notification?.Autoclose ?? 5000,
          toastId: notification.toastId ?? 'default',
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
        break
      case 'success':
        toast.success(notification.Message, {
          position: notification?.Position ?? 'top-right',
          autoClose: notification?.Autoclose ?? 5000,
          toastId: notification?.toastId ?? 'default',
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
        break
      default:
        toast('Wow so easy!')
        break
    }
  }

  let backcss = {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 'auto',
    width: '1200px',
  }

  let sensorcss = {
    position: 'absolute',
    width: '1200px',
  }

  if (!layout.isZoom) {
    backcss = {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      margin: 'auto',
    }

    sensorcss = {
      position: 'absolute',
    }

    bodyCss = {}
  }
  if (isloading) {
    return (
      <>
        <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'>
          {/* begin::Content */}
          <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
            <div className='w-lg-500px '>
              <div>
                <span
                  className='indicator-progress'
                  style={{display: 'block', textAlign: 'center'}}
                >
                  <>
                    {' '}
                    {state.DashboardStat}{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </>
                  <br />
                </span>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
  return (
    <PageDataProvider>
      {/*    <body style={bodyCss}>      </body>  */}
      <div
        className='grid'
        style={{
          height: '100%',
          margin: 'auto',
          width: '100%',
          padding: '10px',
          overflow: 'hide',
        }}
      >
        <div style={backcss}>
          <img style={sensorcss} src={layout.imageData}></img>
          <FabricJSCanvas className='sample-canvas' onReady={onReady} />
          <SensorArea state={state} layout={layout} sensorcss={sensorcss} />
        </div>
      </div>
    </PageDataProvider>
  )
}

export default DisplayGroup
//https://codesandbox.io/s/k2ds4?file=/src/App.js:128-4341
