import React, {useState, useEffect, useRef} from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {useTable, useFilters, useGlobalFilter, usePagination, useAsyncDebounce} from 'react-table'
import axios from 'axios'
import ErrorMessage from '../helpers/message'
import Loading from '../helpers/loading'
import {axioDefaultConfig} from '../helpers/commonResource'
import {useDataLayerValue} from '../../../DataLayer'

const breadCrumb = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

let data = []
function GlobalFilter({preGlobalFilteredRows, globalFilter, setGlobalFilter}) {
  //const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const [{token, user}, dispatch] = useDataLayerValue()

  const onChange = (value) => {
    setGlobalFilter(value)
  }

  return (
    <div className='w-100 position-relative' autoComplete='off'>
      <KTSVG
        path='/media/icons/duotune/general/gen021.svg'
        className='svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 ms-5 translate-middle-y'
      />

      <input
        type='text'
        className='form-control form-control-solid px-15'
        name='search'
        placeholder='Search'
        value={value || ''}
        onChange={(e) => {
          setValue(e.target.value)
          onChange(e.target.value)
        }}
      />
    </div>
  )
}

function TablesWidget10(props) {
  console.log(JSON.stringify(props))
  const columns = React.useMemo(
    () => [
      {
        Header: ' ',
        columns: [
          {
            accessor: 'code',
          },
          {
            accessor: 'formatCode',
          },
          {
            accessor: 'description',
          },
          {
            accessor: 'createdOn',
          },
          {
            accessor: 'status',
          },
          {
            accessor: 'priority',
          },
          {
            accessor: 'fK_SectorCode',
          },
          {
            accessor: 'facilityName',
          },
          {
            accessor: 'remain',
          },
        ],
      },
    ],
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: {pageIndex, pageSize, globalFilter},
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {pageIndex: 0, pageSize: 20, globalFilter: ''},
    },
    useGlobalFilter,
    usePagination
  )
  const [error, setError] = useState({status: 'E', text: ''})
  const [InitialLoading, setInitialLoading] = useState(true)
  const [{user, token}, dispatch] = useDataLayerValue()
  function handleErros(error) {
    if (error?.response?.status === 401) {
      dispatch({
        type: 'SET_TOKEN',
        token: null,
      })
      return
    }

    if (error?.response?.data?.status === 'E') {
      setError(error?.response?.data)
      return
    }
    const message = Object.entries(error?.response?.data?.errors)
      .map(([key, value]) => value)
      .join(',')
    setError({status: 'E', text: message})
  }

  /// Loading
  useEffect(() => {
    const sector = props.props.match?.params?.sector ?? ''
    axios({
      ...axioDefaultConfig,
      method: 'get',
      url:
        process.env.REACT_APP_BACKEND_API +
        '/api/get-alerts?facility=' +
        sector +
        '&code=' +
        user.facility,
      headers: {...axioDefaultConfig.headers, Authorization: 'Bearer ' + token},
    })
      .then(function (response) {
        setInitialLoading(false)
        data = response?.data?.data
        setGlobalFilter('')
      })
      .catch(function (error) {
        handleErros(error)
      })
  }, [])

  if (InitialLoading) return <Loading />

  return (
    <div className={`card`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>All Alerts</span>
          <span className='text-muted mt-1 fw-bold fs-7'> Alert Management</span>
        </h3>
        <ErrorMessage error={error} />
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        >
          <div className='me-4'>
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={''}
              setGlobalFilter={setGlobalFilter}
            />
          </div>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table
            className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'
            {...getTableProps()}
          >
            <thead>
              <td className='text-gray-600 text-gray-800 fw-bolder'>Ticket</td>
              <td className='text-gray-600 text-gray-800 fw-bolder'>Description</td>
              <td className='text-gray-600 text-gray-800 fw-bolder'></td>
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.length === 0 && (
                <tr>
                  <td colSpan={7}>
                    <b>No Data Found!</b>
                  </td>
                </tr>
              )}
              {page.map((row, i) => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps()}>
                    {/* {row.cells.map((cell) => {
                      return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    })}*/}
                    <td style={{width: '120px'}}>
                      {<a className='btn btn-sm  fw-bolder  fs-9 py-1'>{row.values.formatCode}</a>}
                    </td>
                    <td>
                      <b>{row.values.description}</b>{' '}
                      <span
                        style={{fontSize: '10px'}}
                        className='btn btn-sm btn-light-info fw-bolder  fs-9 py-1'
                      >
                        {row.values.priority}
                      </span>
                      {
                        <a
                          style={{fontSize: '10px'}}
                          className='btn btn-sm btn-light-info fw-bolder  ms-2 fs-9 py-1'
                        >
                          {row.values.facilityName}
                        </a>
                      }
                      <br />
                      <span style={{fontSize: '10px'}}> {row.values.createdOn}</span>
                      {row.values.status == 'Opened' && (
                        <a
                          style={{fontSize: '10px'}}
                          className='btn btn-sm btn-light-danger fw-bolder ms-2 fs-9 py-1 px-3'
                        >
                          {' '}
                          OPENED ({row.values.remain})
                        </a>
                      )}
                      {row.values.status == 'Resolved' && (
                        <a
                          style={{fontSize: '10px'}}
                          className='btn btn-sm btn-light-warning fw-bolder ms-2 fs-9 py-1 px-3'
                        >
                          {' '}
                          RESOLVED
                        </a>
                      )}
                      {row.values.status == 'Closed' && (
                        <a
                          style={{fontSize: '10px'}}
                          className='btn btn-sm btn-light-gray fw-bolder ms-2 fs-9 py-1 px-3'
                        >
                          {' '}
                          CLOSED
                        </a>
                      )}
                    </td>

                    <td>
                      <div className='d-flex justify-content-end flex-shrink-0'>
                        <>
                          <a
                            href={'/alert/view/' + row.values.fK_SectorCode + '/' + row.values.code}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          >
                            <i class='bi bi-eye'></i>
                          </a>
                        </>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
          {/* 
      Pagination can be built however you'd like. 
      This is just a very basic UI implementation:
    */}
          <ul className='pagination'>
            <li className='page-item' onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              <a className='page-link'>First</a>
            </li>
            <li className='page-item' onClick={() => previousPage()} disabled={!canPreviousPage}>
              <a className='page-link'>{'<'}</a>
            </li>
            <li className='page-item' onClick={() => nextPage()} disabled={!canNextPage}>
              <a className='page-link'>{'>'}</a>
            </li>
            <li
              className='page-item'
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              <a className='page-link'>Last</a>
            </li>
            <li>
              <a className='page-link'>
                Page{' '}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>{' '}
              </a>
            </li>
            <li>
              <a className='page-link'>
                <input
                  className='page-link'
                  type='number'
                  defaultValue={pageIndex + 1}
                  onChange={(e) => {
                    const page = e.target.value ? Number(e.target.value) - 1 : 0
                    gotoPage(page)
                  }}
                  style={{width: '100px', height: '20px'}}
                />
              </a>
            </li>{' '}
            <select
              className='page-link'
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value))
              }}
              style={{width: '120px', height: '38px'}}
            >
              {[5, 10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </ul>
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

function AllAlerts(props) {
  return (
    <>
      <PageTitle breadcrumbs={breadCrumb}>All Alerts(s)</PageTitle>
      <div className='row gy-5 g-xl-12'>
        <TablesWidget10 props={props} />
      </div>
    </>
  )
}

export default AllAlerts
