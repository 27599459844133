/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { getCSSVariableValue } from '../../../assets/ts/_utils'
import { link } from 'fs'
import { config } from 'process'

type Props = {
  className: string
  chartColor: string
  chartHeight: string
  name: string
  min: string
  max: string
  val: number
  Link: string
  extra: string,
  type: string,

}

const routeChange = (link: any) => {
  window.location.href = link;
  // alert('asa' + link)
}

const MixedWidget10: React.FC<Props> = ({ className, chartColor, chartHeight, name, min, max, val, Link, extra, type }) => {

  const chartRef = useRef<HTMLDivElement | null>(null)
  let obj = JSON.parse(extra)

  var color = ''
  if (type === "OFFLINE") {
    color = 'dark'
    return (
      <div className={`card ${className}`} onClick={(e) => routeChange(Link)} style={{ cursor: 'pointer' }}>
        {/* begin::Body */}
        <div className='card-body d-flex flex-column p-0'>
          {/* begin::Stats */}
          <div className={`flex-grow-1 card-p pb-0 bg-${color}`}>
            <div className='d-flex flex-stack flex-wrap'>
              <div className='me-2'>
                <a href='#' className='text-live text-hover-primary fw-bolder fs-3'>
                  {obj?.Description}
                </a>
                <div style={{ color: 'white' }} className='fs-7 '>{obj?.Name}</div>
                <div className='fs-7 '>{obj?.Note}</div>
              </div>
              <div className={`fw-bolder fs-3 text-${chartColor}`}> ---</div>
            </div>
          </div>
          {/* end::Stats */}


        </div>
        {/* end::Body */}
      </div>
    )
  }



  return (
    <div className={`card ${className}`} onClick={(e) => routeChange(Link)} style={{ cursor: 'pointer' }}>
      {/* begin::Body */}
      <div className='card-body d-flex flex-column p-0'>
        {/* begin::Stats */}
        <div className={`flex-grow-1 card-p pb-0 bg-${color}`}>
          <div className='d-flex flex-stack flex-wrap'>
            <div className='me-2'>
              <a href='#' className='text-dark text-hover-primary fw-bolder fs-3'>
                {obj?.Description}
              </a>
              <div className='text-muted fs-7 fw-bold'>{obj?.Name}</div>
              <div className='fs-7 '>{obj?.Note}</div>
            </div>
            <div className={`fw-bolder fs-3 text-${chartColor}`}>{val}°C</div>
            <div className='ms-1' style={{ position: 'absolute', textAlign: 'right', right: '20px', top: '15px' }}>
              <>
                <span style={{ fontSize: '13px', marginTop: "-7px", position: "absolute" }} className={`dot bg-info`}></span>
                <span style={{ fontSize: '13px', marginTop: '-20px', marginLeft: '-12px' }} className={`pulse-ring bg-info`}> </span>
              </>
            </div>
          </div>
        </div>
        {/* end::Stats */}


      </div>
      {/* end::Body */}
    </div>
  )
}


export { MixedWidget10 }
