/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Dropdown1 } from '../../content/dropdown/Dropdown1'
import { KTSVG } from '../../../helpers'



type Props = {
  className: string
  color: string
  factoryName: string
  critical: string
  warning: string
  connected: number
  alldevice: number
  alert: string
  link: string,
  key: string,
  timestamp: string
  code: string,
  user: any
}


const routeChange = (link: any) => {
  window.location.href = link;
}

const MixedWidget20_1: React.FC<Props> = ({ className, color, factoryName, critical, warning, connected, alert, alldevice, link, key, timestamp, code, user }) => {
  let val = '';
  color = 'success'

  if (alldevice === 0 || alldevice == undefined) {
    color = 'dark'
    val = '0%'
  } else {
    val = Math.round((connected / alldevice) * 100) + "%";
  }

  if (parseFloat(critical ?? 0) > 0) {
    color = 'danger'
  }
  if (connected === 0) {
    color = 'dark'
  }

  var normal = (connected ?? 0) - parseInt(critical ?? 0) - parseInt(warning ?? 0);
  var disable = (alldevice ?? 0) - (connected ?? 0);
  return (
    <div className={`card ${className}`} style={{ cursor: 'pointer' }}>
      {/* begin::Body */}
      <div className='card-body p-0'>
        {/* begin::Header */}
        <div className={`px-9 pt-7 card-rounded h-275px w-100 bg-${color}`} onClick={(e) => routeChange(link)} >
          {/* begin::Heading */}
          <div className='d-flex flex-stack'>
            <h3 className='m-0 text-white fw-bolder fs-3'>{factoryName}</h3>
            <div className='ms-1'>

              {!(val == '0%' || alldevice == undefined) && (<>
                <span style={{ fontSize: '13px', marginTop: "-7px", position: "absolute" }} className={`dot bg-info`}></span>
                <span style={{ fontSize: '13px', marginTop: '-20px', marginLeft: '-12px' }} className={`pulse-ring bg-info`}> </span>
              </>)}

              {/*
              <button
                type='button'
                className={`btn btn-sm btn-icon btn-color-white btn-active-white btn-active-color-${color} border-0 me-n3`}
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='top-end'
              >
                <KTSVG path='/media/icons/duotune/general/gen001.svg' className='svg-icon-2' />
              </button>
              <Dropdown1 /> begin::Menu */}
              {/* end::Menu */}
            </div>
          </div>
          {/* end::Heading */}
          {/* begin::Balance */}
          <div className='d-flex text-center flex-column text-white pt-8'>
            <span className='fw-bold fs-7'>Critical </span>
            <span className='fw-bolder fs-5x pt-1 '>
              {critical}
            </span>
          </div>
          {/* end::Balance */}
        </div>
        {/* end::Header */}
        {/* begin::Items */}
        <div
          className='shadow-xs card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1 bg-white'
          style={{ marginTop: '-100px' }}
        >

          {/* begin::Item */}
          <div className='d-flex align-items-center mb-6'>
            <div className='d-flex align-items-center flex-wrap w-100'>
              {/* begin::Title */}
              <div className='mb-1 pe-3 flex-grow-1'>
                <a href='#' className='fs-5 text-gray-800 text-hover-primary fw-bolder'>
                  Warnings
                </a>
                <div className='text-gray-400 fw-bold fs-7'>
                  Normal {normal}
                </div>
              </div>
              {/* end::Title */}
              {/* begin::Label */}
              <div className='d-flex align-items-center'>
                <div className='fw-bolder fs-5 text-gray-800 pe-1'>{warning}</div>
                <KTSVG
                  path='/media/icons/duotune/general/gen024.svg'
                  className='svg-icon-5 svg-icon-danger ms-1'
                />
              </div>
              {/* end::Label */}
            </div>
            {/* end::Description */}
          </div>
          {/* end::Item */}
          {/* begin::Item */}
          <div className='d-flex align-items-center mb-6'>

            <div className='d-flex align-items-center flex-wrap w-100'>
              {/* begin::Title */}
              <div className='mb-1 pe-3 flex-grow-1'>
                <a href='#' className='fs-5 text-gray-800 text-hover-primary fw-bolder'>
                  Connections
                </a>
                <div className='text-gray-400 fw-bold fs-7'>
                  Disable {disable}
                </div>
              </div>
              {/* end::Title */}
              {/* begin::Label */}
              <div className='d-flex align-items-center'>
                <div className='fw-bolder fs-5 text-gray-800 pe-1'>{val}</div>
                <KTSVG
                  path='/media/icons/duotune/arrows/arr066.svg'
                  className='svg-icon-5 svg-icon-success ms-1'
                />
              </div>
              {/* end::Label */}
            </div>
            {/* end::Description */}
          </div>
          <div className='d-flex align-items-center mb-6'>
            <div className='d-flex align-items-center flex-wrap w-100'>
              {/* begin::Title  */}
              <div className='mb-1 pe-3 flex-grow-1'>
                <a href='#' className='fs-5 text-gray-800 text-hover-primary fw-bolder'>
                  All Tags
                </a>
              </div>
              {/* end::Title */}
              {/* begin::Label */}
              <div className='d-flex align-items-center'>
                <div className='fw-bolder fs-5 text-gray-800 pe-1'>{alldevice}</div>
                <KTSVG
                  path='/media/icons/duotune/arrows/arr066.svg'
                  className='svg-icon-5 svg-icon-success ms-1'
                />
              </div>
              {/* end::Label */}
            </div>
            {/* end::Description */}
          </div>
          {/* end::Item */}
          {/* begin::Item */}
          <div className='d-flex align-items-center'>

            <div className='d-flex align-items-center flex-wrap w-100'>
              {/* begin::Title */}
              <div className='mb-1 pe-3 flex-grow-1'>
                <a href='#' className='fs-5 text-gray-800 text-hover-primary fw-bolder'>
                  <a href={'/alerts/open/' + code}>Alerts</a>
                </a>
                <div className='text-gray-400 fw-bold fs-7'>Past 24 hours</div>
              </div>
              {/* end::Title */}
              {/* begin::Label */}
              <div className='d-flex align-items-center'>
                <div className='fw-bolder fs-5 text-gray-800 pe-1'>
                  <a href={'/alerts/open/' + code}>{alert}</a>

                </div>
                <KTSVG
                  path='/media/icons/duotune/arrows/arr089.svg'
                  className='svg-icon-5 svg-icon-danger ms-1'
                />
              </div>
              {/* end::Label */}
            </div>
            {/* end::Description */}
          </div>
          {/* begin::Title */}
          <div style={{ fontSize: '10px', textAlign: 'center' }} >
            <div className=''>{timestamp}</div> {(user == 'Administrator' || user == 'Content_Admin') && <a target='_blank' href={'https://elysium-backend.overleap.lk/apps/facility/all?id=' + code}> edit facility info</a>}
          </div>
          {/* end::Description */}

          {/* end::Item */}
        </div>
        {/* end::Items */}

      </div>

      {/* end::Body */}
    </div>
  )
}


export { MixedWidget20_1 }
