function Dictionary(param) {

	var items = param;

	//  add 
	this.set = function (key, value) {
		items[key] = value;
	};

	//  delete 
	this.delete = function (key) {
		if (this.has(key)) {
			delete items[key];
			return true;
		}
		return false;
	};

	//  determine if there is a key value 
	this.has = function (key) {
		return key in items;
	};

	//  get through some key value
	this.get = function (key) {
		return this.has(key) ? items[key] : undefined;
	};

	this.getIndex = function (index) {
		return  items[index] ;
	};

	//  clear 
	this.clear = function () {
		items = {};
	};

	//  the length of the 
	this.size = function () {
		return Object.keys(items).length;
	};

	//  key 
	this.keys = function () {
		return Object.keys(items);
	};

	//  value 
	this.values = function () {
		return Object.values(items);
	};

	//  the dictionary 
	this.getItems = function () {
		return items;
	}
}

export default Dictionary;